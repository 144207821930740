package ph.umi.online.di

import org.koin.dsl.module
import ph.umi.online.order.OrderViewModel
import ph.umi.online.otp.OtpViewModel
import ph.umi.online.questionary.QuestionaryViewModel

val provideviewModelModule = module {
    single {
        QuestionaryViewModel(get())
    }

    single { OrderViewModel(get()) }

    single { OtpViewModel(get()) }
}