package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import ph.umi.online.questionary.models.QuestionaryEvent

@Serializable
data class DocTypeRequestBody(
    @SerialName("document_type")
    val docType: String
)
