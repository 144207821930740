package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class IncomeRequestBody(
    @SerialName("occupation_type")
    val occupation: String,

    @SerialName("industry_type")
    val industry: String?,

    @SerialName("company_name")
    val company: String?,

    @SerialName("position")
    val position: String?,

    @SerialName("income_monthly")
    val income: Int?,
)
