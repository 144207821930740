package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AddressRequestBody(
    @SerialName("country")
    val country: String,

    @SerialName("address_type")
    val addressType: String,

    @SerialName("unit")
    val unit: String,

    @SerialName("city")
    val city: String,

    @SerialName("province")
    val province: String,

    @SerialName("zip_code")
    val zip: Int,

    @SerialName("street")
    val street: String,

    @SerialName("barangay")
    val barangay: String
)
