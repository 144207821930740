package ph.umi.online.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Divider
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.focusTarget
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.theme.UmiColors
import ph.umi.online.theme.UmiTheme
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_description_text_color
import ph.umi.online.theme.umi_divider_color
import ph.umi.online.theme.umi_primary_text_color

@Composable
fun UmiElevatedButton(
    label: String,
    onClick: () -> Unit,
    enabled: Boolean = true
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        HorizontalDivider(
            modifier = Modifier.fillMaxWidth(),
            color = umi_divider_color,
            thickness = 1.dp
        )

        Box(modifier = Modifier
            .padding(top = 16.dp)
            .focusTarget()
        ) {
            ElevatedButton(
                enabled = enabled,
                modifier = Modifier.padding(horizontal = 4.dp, vertical = 4.dp).widthIn(min = 400.dp),
                colors = ButtonDefaults.textButtonColors(
                    containerColor = umi_background_blue,
                    contentColor = umi_description_text_color,
                    disabledContainerColor = NeutralsGrey80,
                    disabledContentColor = Color.White
                ),
                onClick = {
                    onClick()
                },
                content = {
                    Spacer(Modifier.size(ButtonDefaults.IconSpacing))
                    Text(label)
                }
            )
        }
    }
}