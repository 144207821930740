package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class IndustriesResponse(
    @SerialName("results")
    val response: List<PersonalExtraInfo>
)
