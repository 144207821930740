package ph.umi.online.views

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.DropdownListWithRecommendation
import ph.umi.online.ui.DropdownListWithSearch
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.document
import umib2bonline.composeapp.generated.resources.document_header
import umib2bonline.composeapp.generated.resources.ic_cyclone
import umib2bonline.composeapp.generated.resources.next_btn_text
import umib2bonline.composeapp.generated.resources.select_type_of_document
import umib2bonline.composeapp.generated.resources.warning_icon

@Composable
internal fun SelectDocTypeView(
    state: QuestionaryState,
    eventHandler: (QuestionaryEvent) -> Unit
) {
    val items = state.documentTypes.collectAsState()
    val isChoosen = state.docType.collectAsState()
//    val defaultItemIndex = remember { mutableStateOf(state.defaultDocumentIndex.value) }
    val loading = state.isLoading.collectAsState()



    Scaffold(
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.document_header),
                progress = 20,
                onBackClick = {
                    eventHandler.invoke(QuestionaryEvent.onBackClicked)
                }
            )
        },
        bottomBar = {
            UmiElevatedButton(
                label = stringResource(Res.string.next_btn_text),
                enabled = !isChoosen.value.isNullOrBlank(),
                onClick = {
                    eventHandler.invoke(QuestionaryEvent.saveDocType(state.docType.value))
            })
        }
    ) { paddingValues ->
        Column(
            modifier = Modifier
                .padding(paddingValues)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            val backgroundList =
                listOf(Color(0xFFFCCA5D), Color(0xFFFCAC86))

            ElevatedCard(
                elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                colors = CardDefaults.cardColors(containerColor = Color.White),
                modifier = Modifier.fillMaxWidth()
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(
                            brush = Brush.horizontalGradient(
                                colors = backgroundList
                            )
                        )
                        .height(IntrinsicSize.Min)
                        .padding(horizontal = 10.dp, vertical = 16.dp)
                ) {
                    Column(
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.Start,
                        modifier = Modifier.padding(end = 10.dp).wrapContentSize()
                    ) {
                        Column {
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.warning_icon),
                                    contentDescription = null,
                                    tint = MaterialTheme.colorScheme.onPrimary,
                                    modifier = Modifier.padding(
                                        horizontal = 5.dp,
                                        vertical = 4.dp
                                    )
                                )
                            }
                        }
                    }

                    VerticalDivider(
                        modifier = Modifier
                            .width(1.dp)
                            .fillMaxHeight(),
                        color = Color.White
                    )

                    Column(
                        modifier = Modifier.padding(start = 16.dp).wrapContentSize(),
                        verticalArrangement = Arrangement.Center
                    ) {
                        Text(
                            text = "You must bring your SELECTED ID to the store for final steps!",
                            style = TextStyle(
                                fontSize = 14.sp,
                                lineHeight = 20.sp,
                                fontWeight = FontWeight(400),
                                color = Color.White,
                                textAlign = TextAlign.Start,
                                letterSpacing = 0.25.sp,
                            )
                        )
                    }
                }
            }

            Text(
                text = stringResource(Res.string.select_type_of_document),
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = NeutralsGrey50,
                    textAlign = TextAlign.Center,
                    letterSpacing = 0.5.sp,
                ),
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 40.dp, start = 16.dp, end = 16.dp)
            )

            DropdownListWithRecommendation(
                modifier = Modifier
                    .padding(top = 32.dp, start = 16.dp, end = 16.dp, bottom = 16.dp),
                items = items.value,
                defaultItemIndex = null,
                defaultItem = if (!state.docType.value.isNullOrBlank()) {
                    items.value.first { it.codename == state.docType.value }.name
                } else null,
                errorLabel = "",
                chooseCategory = { doc ->
                    //                    state.defaultDocumentIndex.value = items.value.first { it.name == doc }.listPosition
                    state.docType.value = items.value.first { it.name == doc }.codename
                },
                label = stringResource(Res.string.document)
            )
        }
    }
}