@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package umib2bonline.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val back_icon: DrawableResource by 
      lazy { init_back_icon() }

  public val congrats: DrawableResource by 
      lazy { init_congrats() }

  public val ic_close: DrawableResource by 
      lazy { init_ic_close() }

  public val ic_copy: DrawableResource by 
      lazy { init_ic_copy() }

  public val ic_cyclone: DrawableResource by 
      lazy { init_ic_cyclone() }

  public val ic_dark_mode: DrawableResource by 
      lazy { init_ic_dark_mode() }

  public val ic_incorrect: DrawableResource by 
      lazy { init_ic_incorrect() }

  public val ic_light_mode: DrawableResource by 
      lazy { init_ic_light_mode() }

  public val ic_map: DrawableResource by 
      lazy { init_ic_map() }

  public val ic_order_num: DrawableResource by 
      lazy { init_ic_order_num() }

  public val ic_posimg: DrawableResource by 
      lazy { init_ic_posimg() }

  public val ic_rotate_right: DrawableResource by 
      lazy { init_ic_rotate_right() }

  public val ic_store: DrawableResource by 
      lazy { init_ic_store() }

  public val subdirectory_arrow_right_icon: DrawableResource by 
      lazy { init_subdirectory_arrow_right_icon() }

  public val warning_icon: DrawableResource by 
      lazy { init_warning_icon() }

  public val waving_icon: DrawableResource by 
      lazy { init_waving_icon() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("back_icon", CommonMainDrawable0.back_icon)
  map.put("congrats", CommonMainDrawable0.congrats)
  map.put("ic_close", CommonMainDrawable0.ic_close)
  map.put("ic_copy", CommonMainDrawable0.ic_copy)
  map.put("ic_cyclone", CommonMainDrawable0.ic_cyclone)
  map.put("ic_dark_mode", CommonMainDrawable0.ic_dark_mode)
  map.put("ic_incorrect", CommonMainDrawable0.ic_incorrect)
  map.put("ic_light_mode", CommonMainDrawable0.ic_light_mode)
  map.put("ic_map", CommonMainDrawable0.ic_map)
  map.put("ic_order_num", CommonMainDrawable0.ic_order_num)
  map.put("ic_posimg", CommonMainDrawable0.ic_posimg)
  map.put("ic_rotate_right", CommonMainDrawable0.ic_rotate_right)
  map.put("ic_store", CommonMainDrawable0.ic_store)
  map.put("subdirectory_arrow_right_icon", CommonMainDrawable0.subdirectory_arrow_right_icon)
  map.put("warning_icon", CommonMainDrawable0.warning_icon)
  map.put("waving_icon", CommonMainDrawable0.waving_icon)
}

internal val Res.drawable.back_icon: DrawableResource
  get() = CommonMainDrawable0.back_icon

private fun init_back_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:back_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/back_icon.xml", -1, -1),
    )
)

internal val Res.drawable.congrats: DrawableResource
  get() = CommonMainDrawable0.congrats

private fun init_congrats(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:congrats",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/congrats.xml", -1, -1),
    )
)

internal val Res.drawable.ic_close: DrawableResource
  get() = CommonMainDrawable0.ic_close

private fun init_ic_close(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_close.xml", -1, -1),
    )
)

internal val Res.drawable.ic_copy: DrawableResource
  get() = CommonMainDrawable0.ic_copy

private fun init_ic_copy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_copy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_copy.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cyclone: DrawableResource
  get() = CommonMainDrawable0.ic_cyclone

private fun init_ic_cyclone(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cyclone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_cyclone.xml", -1, -1),
    )
)

internal val Res.drawable.ic_dark_mode: DrawableResource
  get() = CommonMainDrawable0.ic_dark_mode

private fun init_ic_dark_mode(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_dark_mode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_dark_mode.xml", -1, -1),
    )
)

internal val Res.drawable.ic_incorrect: DrawableResource
  get() = CommonMainDrawable0.ic_incorrect

private fun init_ic_incorrect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_incorrect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_incorrect.xml", -1, -1),
    )
)

internal val Res.drawable.ic_light_mode: DrawableResource
  get() = CommonMainDrawable0.ic_light_mode

private fun init_ic_light_mode(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_light_mode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_light_mode.xml", -1, -1),
    )
)

internal val Res.drawable.ic_map: DrawableResource
  get() = CommonMainDrawable0.ic_map

private fun init_ic_map(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_map.xml", -1, -1),
    )
)

internal val Res.drawable.ic_order_num: DrawableResource
  get() = CommonMainDrawable0.ic_order_num

private fun init_ic_order_num(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_order_num",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_order_num.xml", -1, -1),
    )
)

internal val Res.drawable.ic_posimg: DrawableResource
  get() = CommonMainDrawable0.ic_posimg

private fun init_ic_posimg(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_posimg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_posimg.xml", -1, -1),
    )
)

internal val Res.drawable.ic_rotate_right: DrawableResource
  get() = CommonMainDrawable0.ic_rotate_right

private fun init_ic_rotate_right(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_rotate_right",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_rotate_right.xml", -1, -1),
    )
)

internal val Res.drawable.ic_store: DrawableResource
  get() = CommonMainDrawable0.ic_store

private fun init_ic_store(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_store",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/ic_store.xml", -1, -1),
    )
)

internal val Res.drawable.subdirectory_arrow_right_icon: DrawableResource
  get() = CommonMainDrawable0.subdirectory_arrow_right_icon

private fun init_subdirectory_arrow_right_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:subdirectory_arrow_right_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/subdirectory_arrow_right_icon.xml", -1, -1),
    )
)

internal val Res.drawable.warning_icon: DrawableResource
  get() = CommonMainDrawable0.warning_icon

private fun init_warning_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:warning_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/warning_icon.xml", -1, -1),
    )
)

internal val Res.drawable.waving_icon: DrawableResource
  get() = CommonMainDrawable0.waving_icon

private fun init_waving_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:waving_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/drawable/waving_icon.xml", -1, -1),
    )
)
