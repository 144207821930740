package ph.umi.online.finish

import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarDuration
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import kotlinx.coroutines.launch
import org.koin.compose.getKoin
import ph.umi.online.finish.models.FinishActions
import ph.umi.online.order.OrderViewModel
import ph.umi.online.order.models.OrdersAction
import ph.umi.online.ui.CustomErrSnackBar
import ph.umi.online.views.FinishView

external fun copyToClipboard(text: String)

internal expect fun openMapsUrl(url: String?)


@Composable
fun FinishScreen(
    viewModel: OrderViewModel = getKoin().get()
) {
    val state by viewModel.viewStates().collectAsState()
    val action by viewModel.viewActions().collectAsState(null)

    val snackbarHostState = remember { SnackbarHostState() }
    val coroutineScope = rememberCoroutineScope()


    Scaffold(
        snackbarHost = @Composable {
            CustomErrSnackBar(snackbarHostState)
        },
    ) {
        FinishView(state) { finishEvents ->
            viewModel.obtainEvent(finishEvents)
        }
    }


    when (action) {
        OrdersAction.GetOrders -> {}
        OrdersAction.ShowErrorMsg -> {
            state.isError = mutableStateOf(true)
        }
        OrdersAction.goNextPage -> {}
        null -> {}
        OrdersAction.go404Page -> {}
        OrdersAction.goRestart -> {}
        is OrdersAction.CopyAddress -> {
            try {
                copyToClipboard((action as OrdersAction.CopyAddress).dat)
            } catch (_: Exception) {
                coroutineScope.launch {
                    snackbarHostState.showSnackbar("Error copy address", duration = SnackbarDuration.Short)
                }
            } finally {
                coroutineScope.launch {
                    snackbarHostState.showSnackbar("Address copied to clipboard", duration = SnackbarDuration.Short)
                }
            }
            viewModel.clearActions()
        }
        is OrdersAction.ShowGmaps -> {
            openMapsUrl("https://www.google.com/maps?q=".plus((action as OrdersAction.ShowGmaps).long).plus(",").plus(
                (action as OrdersAction.ShowGmaps).lat))
            viewModel.clearActions()
        }

        OrdersAction.goFinishPage -> {}
    }
}