package ph.umi.online.views

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.gestures.scrollable
import androidx.compose.foundation.gestures.snapping.SnapPosition
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.GenericShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.order.data.AdditionalProduct
import ph.umi.online.order.data.DomainGoodsCategory
import ph.umi.online.order.data.GoodsItem
import ph.umi.online.order.data.InsurancePrograms
import ph.umi.online.order.data.OrderCart
import ph.umi.online.order.data.OrderCartItem
import ph.umi.online.order.data.OrderClient
import ph.umi.online.order.data.OrderInfo
import ph.umi.online.order.models.OrdersEvent
import ph.umi.online.order.models.OrdersState
import ph.umi.online.otp.models.OtpEvent
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_background_gray
import ph.umi.online.theme.umi_divider_color
import ph.umi.online.theme.umi_header_color
import ph.umi.online.theme.umi_primary_text_color
import ph.umi.online.ui.CustomLinearProgressIndicator
import ph.umi.online.ui.EmptyCard
import ph.umi.online.ui.NeutralsGrey62
import ph.umi.online.ui.OrderCardInfo
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import ph.umi.online.utils.withThousands
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.back_icon
import umib2bonline.composeapp.generated.resources.confirm_btn_text
import umib2bonline.composeapp.generated.resources.ic_cyclone
import umib2bonline.composeapp.generated.resources.next_btn_text
import umib2bonline.composeapp.generated.resources.order_description_msg
import umib2bonline.composeapp.generated.resources.order_header
import umib2bonline.composeapp.generated.resources.personal_address_title
import umib2bonline.composeapp.generated.resources.price_msg
import umib2bonline.composeapp.generated.resources.select_type_of_document
import umib2bonline.composeapp.generated.resources.subdirectory_arrow_right_icon
import umib2bonline.composeapp.generated.resources.waving_icon
import kotlin.math.ceil
import kotlin.math.roundToInt


//TODO move to Theme
val NeutralsGrey67 = Color(0xFFABABAB)
val NeutralsGrey50 = Color(0xFF808080)

@OptIn(ExperimentalFoundationApi::class)
@Composable
internal fun OrdersView(
    state: OrdersState,
    paddingValues: PaddingValues,
    eventHandler: (OrdersEvent) -> Unit
) {
    val scrollstate = rememberScrollState()

    val goodsCategories: List<DomainGoodsCategory> = listOf(
        DomainGoodsCategory(
            id = 1,
            name = "MOBILES",
            codename = "MOBILES",
            rank = 1,
            isSerialNumberNeeded = false
        ),
        DomainGoodsCategory(
            id = 2,
            name = "ELECTRONICS_ACCESSORIES",
            codename = "ELECTRONICS_ACCESSORIES",
            rank = 2,
            isSerialNumberNeeded = false
        )
    )
    val ordersList = state.ordersList.collectAsState()
    val additionalProductsList = state.additionalProduct.collectAsState()

    Column(modifier = Modifier
        .wrapContentHeight()
        .padding(paddingValues)
        .fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Row(horizontalArrangement = Arrangement.SpaceEvenly, verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(top = 32.dp, start = 16.dp, end = 16.dp, bottom = 8.dp)) {
            Icon(
                contentDescription = "hello",
                painter = painterResource(Res.drawable.waving_icon),
                modifier = Modifier.size(24.dp),
                tint = Color.Unspecified
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = "Hello, ${state.clientName.value}",
                style = TextStyle(
                    fontSize = 18.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = umi_header_color,
                    textAlign = TextAlign.Center,
                    letterSpacing = 0.5.sp,
                ),
                modifier = Modifier.wrapContentWidth()
            )
        }


        Text(
            text = stringResource(Res.string.order_description_msg),
            style = TextStyle(
                fontSize = 16.sp,
                lineHeight = 24.sp,
                fontWeight = FontWeight(400),
                color = umi_primary_text_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.5.sp,
            ),
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        )

        println("set lazy column")

        LazyColumn(
            modifier = Modifier
                .scrollable(state = scrollstate, orientation = Orientation.Vertical),
            verticalArrangement = Arrangement.spacedBy(16.dp),
            contentPadding = PaddingValues(8.dp)
        ) {
            items(
                items = ordersList.value,
                itemContent = { item ->
                    OrderDetailsItem(
                        item = item,
                        goodsCategories = goodsCategories,
                        additionalProducts = additionalProductsList.value, //TODO check for { it.cart_item_id ==  item.id}
                        isShowDiscount = false
                    )

                }
            )
        }

        additionalProductsList.value.filter { it.cart_item_id == null }.forEach { additionalProduct ->
            Card(
                modifier = Modifier.wrapContentSize().padding(start = 16.dp, end = 16.dp),
                shape = RoundedCornerShape(16.dp),
                colors = CardDefaults.cardColors(
                    containerColor = umi_background_gray
                )
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 16.dp, bottom = 16.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = additionalProduct.name,
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(400),
                            color = umi_header_color,
                            letterSpacing = 0.5.sp,
                        ),
                    )


                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        if (true) {
                            Text(
                                text = ceil(additionalProduct.saleAmount).roundToInt().toString().withThousands(' '),
                                style = TextStyle(
                                    fontSize = 18.sp,
                                    lineHeight = 24.sp,
                                    fontWeight = FontWeight(500),
                                    color = umi_header_color,
                                    textAlign = TextAlign.Right,
                                    letterSpacing = 0.5.sp,
                                )
                            )
                        }

                        Text(
                            text = "₱",
                            style = TextStyle(
                                fontSize = 14.sp,
                                lineHeight = 20.sp,
                                fontWeight = FontWeight(400),
                                color = umi_header_color,
                                textAlign = TextAlign.Right,
                                letterSpacing = 0.25.sp,
                            ),
                            modifier = Modifier.padding(2.dp)
                        )
                    }
                }
            }
        }

        HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(top = 16.dp, bottom = 16.dp), color = umi_divider_color, thickness = 1.dp)

        Column(
            modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(start = 16.dp, end = 16.dp),
            horizontalAlignment = Alignment.End,
            verticalArrangement = Arrangement.Center
        ) {

            Text(
                text = stringResource(Res.string.price_msg),
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = umi_header_color,
                    textAlign = TextAlign.Center,
                    letterSpacing = 0.5.sp,
                ),
                modifier = Modifier.padding(end = 16.dp, start = 16.dp)

            )

            Row(
                modifier = Modifier.padding(top = 4.dp, end = 16.dp, bottom = 24.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = state.priceText.value.toInt().toString().withThousands(' '),
                    style = TextStyle(
                        fontSize = 20.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(500),
                        color = umi_header_color,
                        textAlign = TextAlign.Right,
                        letterSpacing = 0.15.sp,
                    )
                )

                Text(
                    text = state.currency,
                    style = TextStyle(
                        fontSize = 16.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(400),
                        color = umi_header_color,
                        letterSpacing = 0.5.sp,
                    ),
                    modifier = Modifier.padding(start = 4.dp)
                )
            }
        }
    }
}

@Composable
private fun OrderDetailsItem(
    currency: String = "₱",
    item: OrderCartItem,
    additionalProducts: List<AdditionalProduct>,
    goodsCategories: List<DomainGoodsCategory>,
    isShowDiscount: Boolean
) {

    val indexOfCategory =
        goodsCategories.map { it.codename }.indexOf(item.goodsType)

    val goodsCategoryName =
        if (indexOfCategory >= 0) goodsCategories[indexOfCategory].name else ""


    Card(
        modifier = Modifier.wrapContentSize(),
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = umi_background_gray
        )
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 8.dp, bottom = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.wrapContentHeight()
            ) {
                if (goodsCategoryName.isNotEmpty()) {
                    Text(
                        text = goodsCategoryName,
                        style = TextStyle(
                            fontSize = 12.sp,
                            lineHeight = 16.sp,
                            fontWeight = FontWeight(500),
                            color = umi_primary_text_color,
                            letterSpacing = 0.5.sp,
                        )
                    )
                }

                Text(
                    text = item.name,
                    style = TextStyle(
                        fontSize = 16.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(400),
                        color = umi_header_color,
                        letterSpacing = 0.5.sp,
                    )
                )
            }

            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(bottom = 8.dp)) {
                Text(
                    text = item.price.toInt().toString().withThousands(' '),
                    style = TextStyle(
                        fontSize = 18.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(500),
                        color = umi_header_color,
                        textAlign = TextAlign.Right,
                        letterSpacing = 0.5.sp,
                    )
                )

                Text(
                    text = currency,
                    style = TextStyle(
                        fontSize = 14.sp,
                        lineHeight = 20.sp,
                        fontWeight = FontWeight(400),
                        color = umi_header_color,
                        textAlign = TextAlign.Right,
                        letterSpacing = 0.25.sp,
                    ),
                    modifier = Modifier.padding(2.dp)
                )
            }
        }

        additionalProducts.filter { it.cart_item_id == item.id }.forEach { additionalProduct ->
            SalePreview(
                categoryName = additionalProduct.name,
                price = additionalProduct.saleAmount,
                isShowDiscount = isShowDiscount
            )
        }
    }

}

@Composable
private fun SalePreview(
    categoryName: String,
    price: Double?,
    priceWithSale: Double? = null,
    currency: String = "₱",
    isShowDiscount: Boolean
) {
    HorizontalDivider(
        modifier = Modifier.fillMaxWidth(),
        color = MaterialTheme.colorScheme.onPrimary,
        thickness = 4.dp
    )

    Row(
        modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, bottom = 8.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {


        Row {
            Icon(
                painter = painterResource(Res.drawable.subdirectory_arrow_right_icon),
                contentDescription = null,
                tint = Color.Unspecified,
                modifier = Modifier.padding(top = 6.dp)
            )

            Text(
                text = categoryName,
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = umi_header_color,
                    letterSpacing = 0.5.sp,
                ),
                modifier = Modifier.padding(start = 16.dp, top = 8.dp)
            )
        }


        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(top = 8.dp)
        ) {

            val showSale = (priceWithSale != null) && isShowDiscount

            if (showSale) {
                Box(
                    modifier = Modifier.padding(end = 8.dp)
                ) {
                    Text(
                        text = "${ceil(price?: 0.0).roundToInt().toString().withThousands(' ')}$currency",
                        style = TextStyle(
                            fontSize = 14.sp,
                            lineHeight = 20.sp,
                            fontWeight = FontWeight.W400,
                            color = umi_header_color,
                            textAlign = TextAlign.Right,
                            letterSpacing = 0.25.sp,
                        )
                    )
//                    Canvas(
//                        modifier = Modifier.matchParentSize()
//                    ) {
//                        drawLine(
//                            color = SaleGreen,
//                            start = Offset(0f, size.height),
//                            end = Offset(size.width, 0f),
//                            strokeWidth = 2.dp.toPx(),
//                            cap = StrokeCap.Round,
//                            pathEffect = null
//                        )
//                    }
                }
            }

            val priceText = if (showSale) priceWithSale else price

            if (priceText != null) {
                Text(
                    text = ceil(priceText).roundToInt().toString().withThousands(' '),
                    style = TextStyle(
                        fontSize = 18.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(500),
                        color = if (!showSale) umi_header_color else Color.Green,
                        textAlign = TextAlign.Right,
                        letterSpacing = 0.5.sp,
                    )
                )
            }

            Text(
                text = currency,
                style = TextStyle(
                    fontSize = 14.sp,
                    lineHeight = 20.sp,
                    fontWeight = FontWeight(400),
                    color = if (!showSale) umi_header_color else Color.Green,
                    textAlign = TextAlign.Right,
                    letterSpacing = 0.25.sp,
                ),
                modifier = Modifier.padding(2.dp)
            )
        }
    }
}



fun cutDateForSort(date: String): String {
    return date.substring(0, 10)
}