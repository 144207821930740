package ph.umi.online.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ph.umi.online.order.data.OrderInfo
import ph.umi.online.utils.*

//TODO move to Theme
val NeutralsGrey37 = Color(0xFF5E5E5E)
val NeutralsGrey50 = Color(0xFF808080)
val NeutralsGrey80 = Color(0xFFCCCCCC)


@Composable
fun OrderCardInfo(
    modifier: Modifier = Modifier,
    order: OrderInfo,
    phoneCode: String,
    onCardClick: (OrderInfo) -> Unit = {},
) {
    Card(
        modifier = modifier
            .fillMaxWidth()
            .padding(start = 12.dp, end = 12.dp, bottom = 12.dp)
            .clickable { onCardClick(order) },
        shape = RoundedCornerShape(8.dp),
        elevation = CardDefaults.cardElevation(defaultElevation = 5.dp)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(color = MaterialTheme.colorScheme.background)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.Bottom
            ) {
                Text(
                    text = "#${order.orderNumber}",
                    modifier = Modifier.padding(start = 16.dp, top = 16.dp, bottom = 6.dp),
                    style = TextStyle(
                        fontSize = 14.sp,
                        lineHeight = 20.sp,
                        fontWeight = FontWeight(400),
                        color = Color.Black,
                        letterSpacing = 0.25.sp,
                    )
                )
                Text(
                    text = getClientName(
                        firstName = order.client?.firstName,
                        lastName = order.client?.lastName,
                        middleName = order.client?.middleName
                    ),
                    modifier = Modifier.padding(end = 16.dp, top = 14.dp, bottom = 6.dp),
                    style = TextStyle(
                        fontSize = 18.sp,
                        lineHeight = 22.sp,
                        fontWeight = FontWeight(400),
                        color = NeutralsGrey37,
                        letterSpacing = 0.15.sp,
                    )
                )
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.Top
            ) {
                Box(modifier = Modifier.padding(start = 16.dp, top = 6.dp, bottom = 12.dp)) {
                    Text(
                        text = order.statusDisplayedName,
                        style = TextStyle(
                            fontSize = 12.sp,
                            lineHeight = 16.sp,
                            fontWeight = FontWeight(500),
                            color = Color.White,
                            letterSpacing = 0.4.sp,
                        ),
                        modifier = Modifier
                            .clip(RoundedCornerShape(5.dp))
                            .background(NeutralsGrey80)
//                            .background(color = getCardOrderColor(order.status))
                            .padding(horizontal = 8.dp, vertical = 4.dp)
                    )
                }
                Text(
                    text = getClientPhone(
                        phoneNumber = order.client?.phoneNumber,
                        phoneCode = phoneCode
                    ),
                    modifier = Modifier.padding(end = 16.dp, top = 6.dp, bottom = 16.dp),
                    color = Color.Gray,
                    style = TextStyle(
                        fontSize = 14.sp,
                        lineHeight = 20.sp,
                        fontWeight = FontWeight(400),
                        color = NeutralsGrey50,
                        letterSpacing = 0.25.sp,
                    )
                )
            }
        }
    }
}