package ph.umi.online.utils

import androidx.compose.ui.text.AnnotatedString
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimePeriod
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.daysUntil
import kotlinx.datetime.minus
import kotlinx.datetime.periodUntil
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.yearsUntil
import org.jetbrains.skia.Pattern
import ph.umi.online.questionary.models.DocTypesPatterns
import ph.umi.online.ui.MaskedTransformation

private const val TextMaxLength = 32
private const val ContactMaxLength = 255

//private val dateFormat = DateTimeFormatter.ofPattern("MM/dd/yyyy")
//private val dateFormatTo = DateTimeFormatter.ofPattern("yyyy-MM-dd")

fun validateAge(date: String): Boolean {
    return if (date.isEmpty()) {
        false
    } else {
        try {
            val start = LocalDate.parse(date)
            val current = Clock.System.now().toLocalDateTime(TimeZone.UTC).date.minus(18, DateTimeUnit.YEAR)
            start.yearsUntil(current) < 0 && start > current
        } catch (ex: Exception) {
            println("validateAge failed")
            false
        }
    }
}

fun validateDocIssue(date: String): Boolean {
    return if (date.isEmpty()) {
        false
    } else {
        try {
            val start = LocalDate.parse(date)
            val current = Clock.System.now().toLocalDateTime(TimeZone.UTC).date.minus(1, DateTimeUnit.DAY)
            start.daysUntil(current) < 0 && start > current
        } catch (ex: Exception) {
            false
        }
    }
}

fun validateDocExpired(date: String): Boolean {
    return if (date.isEmpty()) {
        false
    } else {
        try {
            val start = LocalDate.parse(date)
            val current = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
            start < current
        } catch (ex: Exception) {
            false
        }
    }
}

fun validateNames(input: String): Boolean {
    val regex = "^(?!.*(--|- |-'|-\\.| -|  | '| \\.|'-|' |''|'\\.|\\.-|\\.'|\\.\\.|  |''|  -|- |' |-\\.|\\.-|\\.- |\\.  \\.|'  '|  '|''\\.| \$|-\$|'\$)|[-' \\.]\$)[A-Z][A-Za-z.' -]*[A-Za-z]*[.]?\$"
    return if (input.isEmpty()) {
        false
    } else input.matches(regex.toRegex()) && input.length <= TextMaxLength
}

fun validateContact(input: String): Boolean {
    val regex = "^(?!.*(--|- |-'|-\\.| -|  | '| \\.|'-|' |''|'\\.|\\.-|\\.'|\\.\\.|  |''|  -|- |' |-\\.|\\.-|\\.- |\\.  \\.|'  '|  '|''\\.| \$|-\$|'\$)|[-' \\.]\$)[A-Z][A-Za-z.' -]*[A-Za-z]*[.]?\$"
    return if (input.isEmpty()) {
        false
    } else input.matches(regex.toRegex()) && input.length <= ContactMaxLength
}

fun validateEmail(mail: String): Boolean {
    val regex = Regex("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")

    return if (mail.isEmpty()) {
        false
    } else mail.matches(regex)
}

fun validateNumbers(input: String, maxLength: Int): Boolean {
    return if (input.isEmpty()) {
        false
    } else ((input.count { char -> char.isDigit() } == input.length)
            && (input.length <= maxLength))
}

fun validateMaxLength(input: String, maxLength: Int): Boolean {
    return input.length <= maxLength
}

fun validateTemporaryIncome(input: String?): Boolean {
    return if (input == null || input.isNotEmpty()) {
        true
    } else if (input.isEmpty()) {
        false
    } else false
}

fun validateDocument(input: String, docType: String): Boolean {
    val pattern = DocTypesPatterns.valueOf(docType).pattern
    val mask = DocTypesPatterns.valueOf(docType).mask

    val regex = Regex(pattern, options = setOf(RegexOption.IGNORE_CASE))
    return if (input.isEmpty()) {
        false
    } else regex.matches(MaskedTransformation(mask, '0').filter(AnnotatedString(input)).text.text)
}

fun validatePhoneNumber(phone: String, phoneCode: String): Boolean {
    val phoneNumber = "+$phoneCode$phone"
    val phoneNumberPattern = "^\\+${phoneCode}9\\d{9}$"
    val pattern = Regex(phoneNumberPattern)
    println(phoneNumber.matches(pattern))
    return phoneNumber.matches(pattern)
}

