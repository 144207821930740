package ph.umi.online.views

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Divider
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource
import ph.umi.online.finish.models.FinishEvents
import ph.umi.online.finish.models.FinishState
import ph.umi.online.order.models.OrdersEvent
import ph.umi.online.order.models.OrdersState
import ph.umi.online.theme.md_theme_light_background
import ph.umi.online.theme.umi_background_gray
import ph.umi.online.theme.umi_header_color
import ph.umi.online.theme.umi_primary_text_color
import ph.umi.online.ui.CustomLinearProgressIndicator
import ph.umi.online.ui.EmptyCard
import ph.umi.online.ui.NeutralsGrey62
import umib2bonline.composeapp.generated.resources.IndieFlower_Regular
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.back_icon
import umib2bonline.composeapp.generated.resources.congrats
import umib2bonline.composeapp.generated.resources.ic_copy
import umib2bonline.composeapp.generated.resources.ic_cyclone
import umib2bonline.composeapp.generated.resources.ic_map
import umib2bonline.composeapp.generated.resources.ic_order_num
import umib2bonline.composeapp.generated.resources.ic_posimg
import umib2bonline.composeapp.generated.resources.personal_address_title
import umib2bonline.composeapp.generated.resources.pre_approve_msg
import umib2bonline.composeapp.generated.resources.subdirectory_arrow_right_icon
import umib2bonline.composeapp.generated.resources.warning_icon

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun FinishView(
    state: OrdersState,
    eventHandler: (OrdersEvent) -> Unit
) {
    val backgroundList =
        listOf(Color(0xFFFCCA5D), Color(0xFFFCAC86))

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(md_theme_light_background),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(40.dp))

        Image(
            modifier = Modifier.size(80.dp),
            imageVector = vectorResource(Res.drawable.congrats),
            contentDescription = null
        )

        Spacer(modifier = Modifier.height(32.dp))

        Text(
            text = stringResource(Res.string.congrats),
            style = TextStyle(
                fontSize = 20.sp,
                lineHeight = 24.sp,
                fontWeight = FontWeight(500),
                color = umi_header_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.15.sp
            )
        )

        Spacer(modifier = Modifier.height(24.dp))


        ElevatedCard(
            elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
            colors = CardDefaults.cardColors(containerColor = Color.White),
            modifier = Modifier.fillMaxWidth()
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .background(
                        brush = Brush.horizontalGradient(
                            colors = backgroundList
                        )
                    )
                    .height(IntrinsicSize.Min)
                    .padding(horizontal = 10.dp, vertical = 16.dp)
            ) {
                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start,
                    modifier = Modifier.padding(end = 10.dp).wrapContentSize()
                ) {
                    Column {
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(
                                painter = painterResource(Res.drawable.warning_icon),
                                contentDescription = "Congrats",
                                tint = MaterialTheme.colorScheme.onPrimary,
                                modifier = Modifier.padding(horizontal = 5.dp, vertical = 4.dp)
                            )
                        }
                    }
                }

                VerticalDivider(
                    modifier = Modifier
                        .width(1.dp)
                        .fillMaxHeight(),
                    color = Color.White
                )

                Column(modifier = Modifier.padding(start = 16.dp).wrapContentSize(), verticalArrangement = Arrangement.Center) {
                    Text(
                        text = "Bring your SELECTED ID to the store for final steps",
                        style = TextStyle(
                            fontSize = 14.sp,
                            lineHeight = 20.sp,
                            fontWeight = FontWeight(400),
                            color = Color.White,
                            textAlign = TextAlign.Start,
                            letterSpacing = 0.25.sp,
                        )
                    )
                }
            }
        }

        Spacer(modifier = Modifier.height(24.dp))

        Text(
            text = stringResource(Res.string.pre_approve_msg),
            textAlign = TextAlign.Center,
            style = TextStyle(
                fontSize = 16.sp,
                lineHeight = 24.sp,
                fontWeight = FontWeight(400),
                color = umi_primary_text_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.5.sp,
            )
        )

        Spacer(modifier = Modifier.height(24.dp))


        Card(
            modifier = Modifier.wrapContentSize(),
            shape = RoundedCornerShape(8.dp),
            colors = CardDefaults.cardColors(
                containerColor = umi_background_gray
            )
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(all = 16.dp),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.Top
            ) {
                Icon(
                    painter = painterResource(Res.drawable.ic_order_num),
                    contentDescription = null,
                    tint = umi_primary_text_color
                )

                Spacer(modifier = Modifier.width(16.dp))

                Column(
                    verticalArrangement = Arrangement.Top
                ) {
                    Text(
                        "Order number",
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(500),
                            color = Color(0xFF1D1B20),
                            letterSpacing = 0.5.sp,
                        ))
                    Spacer(modifier = Modifier.height(8.dp))
                    Text(
                        state.orderId.value,
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(500),
                            color = umi_primary_text_color,
                            letterSpacing = 0.5.sp
                        ))
                }
            }
        }

        Spacer(modifier = Modifier.height(8.dp))

        Card(
            modifier = Modifier.wrapContentSize(),
            shape = RoundedCornerShape(8.dp),
            colors = CardDefaults.cardColors(
                containerColor = umi_background_gray
            )
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(all = 16.dp),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.Top
            ) {
                Icon(
                    painter = painterResource(Res.drawable.ic_posimg),
                    contentDescription = null,
                    tint = umi_primary_text_color
                )

                Spacer(modifier = Modifier.width(16.dp))


                Column(
                    verticalArrangement = Arrangement.Top
                ) {
                    Text(
                        state.posName.value,
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(500),
                            color = Color(0xFF1D1B20),
                            letterSpacing = 0.5.sp,
                        ))
                    Spacer(modifier = Modifier.height(8.dp))
                    Text(
                        state.posAddr.value,
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(500),
                            color = umi_primary_text_color,
                            letterSpacing = 0.5.sp
                        )
                    )
                }
            }

            Row(
                modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, bottom = 8.dp),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                TextButton(
                    modifier = Modifier.wrapContentSize(),
                    onClick = {
                        eventHandler.invoke(OrdersEvent.onCopyBtnClicked)
                    }
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_copy),
                        contentDescription = null,
                        tint = umi_primary_text_color
                    )
                    Text("Copy", color = umi_primary_text_color)
                }

                if (state.lat.value != null || state.long.value != null) {
                    TextButton(
                        modifier = Modifier.wrapContentSize(),
                        onClick = {
                            eventHandler.invoke(OrdersEvent.onMapBtnClicked)
                        }
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.ic_map),
                            contentDescription = null
                        )
                        Text("Map")
                    }
                }
            }
        }
    }
}