package ph.umi.online.ui

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_labels_color

val Error = Color(0xFFFF0000)
val NeutralsGrey62 = Color(0xFF9E9E9E)


@Composable
fun TextFieldWithDescription(
    modifier: Modifier = Modifier,
    text: String = "",
    label: String? = null,
    placeholder: String? = null,
    onValueChange: (String) -> Unit,
    keyboardType: KeyboardType = KeyboardType.Text,
    keyboardActions: KeyboardActions = KeyboardActions.Default,
    imeAction: ImeAction = ImeAction.Next,
    capitalization: KeyboardCapitalization = KeyboardCapitalization.None,
    maxLength: Int = Int.MAX_VALUE,
    trailingIcon: @Composable (() -> Unit)? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
    visualTransformation: VisualTransformation = VisualTransformation.None,
    isError: Boolean = false,
    supportingText: String? = null,
    isShowSupportText: Boolean = false,
    readOnly: Boolean = false,
    enabled: Boolean = true
) {
    var isFocused by remember { mutableStateOf(false) }
    val processedText = remember(text) {
        mutableStateOf(text.replace("\\s+".toRegex(), " "))
    }

    Column(modifier = modifier.fillMaxWidth()) {
        OutlinedTextField(
            modifier = Modifier
                .onFocusChanged { isFocused = it.isFocused }
                .fillMaxWidth(),
            value = processedText.value,
            onValueChange = {
                val newValue = it.take(maxLength) // Limit text length
                if (newValue.length <= maxLength) {
                    if (processedText.value != newValue) {
                        processedText.value = newValue.replace("\\s+".toRegex(), " ") // Remove double spaces
                        onValueChange(newValue)
                    }
                }
            },
            singleLine = true,
            readOnly = readOnly,
            enabled = enabled,
            label = {
                Text(
                    text = label ?: "",
                    style = TextStyle(
                        fontSize = 14.sp,
                        lineHeight = 20.sp,
                        fontWeight = FontWeight(400)
                    ),
                    maxLines = 1
                )
            },
            trailingIcon = trailingIcon,
            leadingIcon = leadingIcon,
            colors = defaultTextFieldColors(),
            textStyle = defaultTextStyle(),
            keyboardOptions = KeyboardOptions(
                keyboardType = keyboardType,
                imeAction = imeAction,
                capitalization = capitalization
            ),
            keyboardActions = keyboardActions,
            visualTransformation = visualTransformation,
            isError = isError,
            placeholder = {
                Text(
                    text = placeholder ?: "",
                    style = TextStyle(
                        fontSize = 14.sp,
                        lineHeight = 20.sp,
                        fontWeight = FontWeight(400),
                        color = NeutralsGrey62,
                        letterSpacing = 0.25.sp,
                    )
                )
            }
        )
        supportingText?.let {
            val isShowHelperText = remember { mutableStateOf(false) }
            var colorHelperText = NeutralsGrey62
            when {
                isError -> {
                    colorHelperText = Error
                    isShowHelperText.value = true
                }

                isShowSupportText -> {
                    colorHelperText = NeutralsGrey62
                    isShowHelperText.value = true
                }
                else -> isShowHelperText.value = false
            }

            if (isShowHelperText.value)
                Text(
                    text = supportingText,
                    style = TextStyle(
                        fontSize = 12.sp,
                        lineHeight = 16.sp,
                        fontWeight = FontWeight(400),
                        color = colorHelperText
                    ),
                    modifier = Modifier
                        .padding(start = 16.dp, end = 8.dp, top = 4.dp)
                )
        }
    }
}

@Composable
fun defaultTextStyle() = TextStyle.Default.copy(
    color = Color.Black,
    fontSize = 16.sp,
    lineHeight = 24.sp,
    fontWeight = FontWeight(400),
    letterSpacing = 0.5.sp
)

@Composable
fun defaultTextFieldColors() = OutlinedTextFieldDefaults.colors(
    cursorColor = MaterialTheme.colorScheme.primary,
    focusedBorderColor = umi_background_blue,
    unfocusedBorderColor = umi_labels_color,
    focusedTextColor = MaterialTheme.colorScheme.primary,
    focusedLabelColor = umi_background_blue,
    unfocusedLabelColor = umi_labels_color,
    focusedTrailingIconColor = MaterialTheme.colorScheme.onSurfaceVariant,
    unfocusedTrailingIconColor = MaterialTheme.colorScheme.onSurfaceVariant,
    errorBorderColor = Error,
    errorLabelColor = Error,
    errorCursorColor = Error,
    errorTrailingIconColor = Error,
    errorSupportingTextColor = Error
)