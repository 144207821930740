package ph.umi.online.otp.models

import ph.umi.online.finish.models.FinishActions

sealed class OtpAction {
    data class SetJwtToken(var token: String) : OtpAction()
    data class RunPixelScript(var umidLong: String) : OtpAction()
    data object SendOtp : OtpAction()
    data object goNextPage : OtpAction()
    data object IncorrectOtpSent: OtpAction()
    data object MaxTryCount: OtpAction()
    data object ShowErrorMsg : OtpAction()
    data class ShowSendAgainErrorMsg(var data: String) : OtpAction()
    data object NotExistingOrder : OtpAction()
}