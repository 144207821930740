package ph.umi.online.order.models

sealed class OrdersAction {
    data object GetOrders : OrdersAction()
    data object goNextPage : OrdersAction()
    data object goRestart : OrdersAction()
    data object go404Page : OrdersAction()
    data object goFinishPage : OrdersAction()
    data object ShowErrorMsg : OrdersAction()
    data class ShowGmaps(val long: String, val lat: String) : OrdersAction()
    data class CopyAddress(val dat: String) : OrdersAction()


}