package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import ph.umi.online.questionary.data.DomainDocumentType

@Serializable
data class DocTypesResponse(
    @SerialName("results")
    val results: List<DomainDocumentType>
)
