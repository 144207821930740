package ph.umi.online.otp.models

import kotlinx.coroutines.flow.MutableStateFlow

private const val TimerInitialValue = 60

data class OtpState(
    var umid : String = "",
    var token : String = "",
    var dataPrivacyLink : MutableStateFlow<String> = MutableStateFlow(""),
    var agreementLink : MutableStateFlow<String> = MutableStateFlow(""),
    var otpValue : MutableStateFlow<String> = MutableStateFlow(""),
    val otpCount: Int = 4,

    var attemptsOtpLeft: MutableStateFlow<Int> = MutableStateFlow(3),
    var otpSendCount: MutableStateFlow<Int> = MutableStateFlow(0),

    val otpCheckCountMax: Int = 3,
    var isOtpError: Boolean = false,

    var agreed: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var success: MutableStateFlow<Boolean> = MutableStateFlow(true),
    val countdown: MutableStateFlow<Int> = MutableStateFlow(TimerInitialValue),
    val isSendAgainActive: MutableStateFlow<Boolean> = MutableStateFlow(true),

    var phoneNumber: String = "",
    var phoneCode: String = "+63",
    var isSendButtonEnabled: Boolean = true,
    var isOtpLoading: MutableStateFlow<Boolean> = MutableStateFlow(false),
    var isError: MutableStateFlow<Boolean> = MutableStateFlow(false),
    var errorMessage : MutableStateFlow<String> = MutableStateFlow(""),
    var isLoading: MutableStateFlow<Boolean> = MutableStateFlow(true),
) {
    private val TimerMillis = 1000L

    //todo to utils
    fun getFormattedPhoneNumber() : String {
        return buildString {
            for (i in phoneNumber.indices) {
                when(i) {
                    2 -> append('-')
                }
                append(phoneNumber[i])
            }
        }
    }
}