package ph.umi.online.ui

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DatePicker
import androidx.compose.material3.DatePickerColors
import androidx.compose.material3.DatePickerDefaults
import androidx.compose.material3.DatePickerDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import io.ktor.http.parsing.ParseException
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeFormat
import kotlinx.datetime.toLocalDateTime
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_background_gray

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DatePickerField(
    modifier: Modifier = Modifier,
    onDateSelected: (date: String) -> Unit,
    isShowError: Boolean = false,
    errorText: String = "",
    initialDate: String = "",
    label: String,
    onDone: () -> Unit
) {
    val openDialog = remember { mutableStateOf(false) }
    var dateResult by remember { mutableStateOf("") }

    LaunchedEffect(key1 = initialDate) {
        dateResult = initialDate
    }

    val focusRequester = remember { FocusRequester() }
    val focusManager = LocalFocusManager.current
    var isFocused by remember { mutableStateOf(false) }

    Box(modifier = modifier) {
        TextFieldWithDescription(modifier = Modifier
            .focusRequester(focusRequester)
            .onFocusChanged {
                isFocused = it.isFocused
                if (isFocused) {
                    openDialog.value = true
                }
            }
            .fillMaxWidth(),
            label = label,
            text = dateResult,
            supportingText = errorText,
            onValueChange = onDateSelected,
            keyboardType = KeyboardType.Number,
            imeAction = ImeAction.Done,
            readOnly = true,
            keyboardActions = KeyboardActions(onDone = { onDone() }),
            isError = !isShowError,
            isShowSupportText = !isShowError)
    }

    if (openDialog.value) {
        val datePickerState = rememberDatePickerState(
            initialSelectedDateMillis = convertDateToLong(initialDate)
        )

        DatePickerDialog(
            onDismissRequest = {
                openDialog.value = false
            },
            colors = DatePickerDefaults.colors(
                containerColor = Color.White
            ),
            confirmButton = {
                Button(
                    onClick = {
                        openDialog.value = false
                        dateResult = datePickerState.selectedDateMillis?.let { convertLongToTime(it) }.orEmpty()
                        onDateSelected(dateResult)
                        onDone()
                    },
                    modifier = Modifier
                        .padding(end = 16.dp)
                        .offset(y = (-16).dp),
                    colors = ButtonDefaults.textButtonColors(
                        containerColor = umi_background_blue,
                        disabledContainerColor = umi_background_gray
                    ),
                    border = BorderStroke(4.dp, umi_background_blue)
                ) {
                    Text(
                        text = "OK",
                        color = MaterialTheme.colorScheme.onPrimary,
                        style = TextStyle(
                            fontSize = 14.sp,
                            lineHeight = 16.sp,
                            fontWeight = FontWeight(500),
                            textAlign = TextAlign.Center,
                            letterSpacing = 0.1.sp,
                        )
                    )
                }
            },
            dismissButton = {
                Button(
                    onClick = {
                        openDialog.value = false
                        onDone()
                    },
                    modifier = Modifier
                        .padding(end = 8.dp)
                        .offset(y = (-16).dp),
                    border = BorderStroke(1.dp, umi_background_blue),
                    colors = ButtonDefaults.textButtonColors(
                        containerColor = Color.White,
                        disabledContainerColor = umi_background_gray
                    )
                ) {
                    Text(
                        text = "Cancel",
                        style = TextStyle(
                            fontSize = 14.sp,
                            lineHeight = 16.sp,
                            fontWeight = FontWeight(500),
                            textAlign = TextAlign.Center,
                            letterSpacing = 0.1.sp,
                        )
                    )
                }
            },
        ) {
            DatePicker(state = datePickerState, colors = DatePickerDefaults.colors(containerColor = Color.White))
        }
    }
}
fun convertLongToTime(time: Long): String {
//    return "mm/dd/yyyy"
    val currentMoment = Instant.fromEpochMilliseconds(time)
//    val date = LocalDateTime(time)
//    val format = SimpleDateFormat("MM/dd/yyyy", Locale.US)
    return try {
        currentMoment.toLocalDateTime(TimeZone.UTC).date.format(LocalDate.Formats.ISO)
//        format.format(date)
    } catch (e: Exception) {
        ""
    }
}

fun convertDateToLong(date: String): Long {
    return Clock.System.now().toEpochMilliseconds()
//    if (date.isEmpty())
//        return Clock.System.now().toEpochMilliseconds()

//    val df = SimpleDateFormat("MM/dd/yyyy", Locale.US)
//    df.timeZone = TimeZone.getTimeZone("UTC")
//    return try {
//        df.parse(date)?.time ?: throw IllegalArgumentException("Invalid date format")
//    } catch (e: ParseException) {
//        throw IllegalArgumentException("Invalid date format. Please use MM/dd/yyyy.")
//    }
}