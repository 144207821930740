package ph.umi.online.questionary

import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.viewModelScope
import io.ktor.client.call.NoTransformationFoundException
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import ph.umi.online.core.BaseViewModel
import ph.umi.online.data.ApiStatus
import ph.umi.online.data.NetworkRepository
import ph.umi.online.data.NetworkResult
import ph.umi.online.data.requests.AddressRequestBody
import ph.umi.online.data.requests.ContactsReuestBody
import ph.umi.online.data.requests.DocTypeRequestBody
import ph.umi.online.data.requests.IncomeRequestBody
import ph.umi.online.data.requests.PersonalDataRequestBody
import ph.umi.online.data.responses.GeoSuggestionsResponse
import ph.umi.online.navigation.ScreensList
import ph.umi.online.questionary.data.GeoSuggestion
import ph.umi.online.questionary.models.QuestionaryAction
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.utils.validateAge
import ph.umi.online.utils.validateDocExpired
import ph.umi.online.utils.validateDocIssue
import ph.umi.online.utils.validateDocument
import ph.umi.online.utils.validateEmail
import ph.umi.online.utils.validateNames
import ph.umi.online.utils.validatePhoneNumber

external fun savePageToSessionStorage(page: String)

class QuestionaryViewModel(private val networkRepository: NetworkRepository): BaseViewModel<QuestionaryState, QuestionaryAction, QuestionaryEvent>(
    initialState = QuestionaryState()
) {
    var isContentLoading: MutableStateFlow<Boolean> = MutableStateFlow(false)

    init {
        clearActions()
        println("QuestionaryViewModel Initialized")

        if (baseToken.isNotEmpty()) {
            println("set token from QuestionaryViewModel")
            networkRepository.setToken(baseToken)
        }
        savePageToSessionStorage(ScreensList.Questionary.title)

        viewModelScope.launch {
            getDocumentTypes()
            getProvinces()
        }
    }

    override fun obtainEvent(viewEvent: QuestionaryEvent) {
        when (viewEvent) {
            is QuestionaryEvent.savePersonalData -> {
                isContentLoading.value = true

                viewModelScope.launch {
                    setPersonalData(viewEvent.data)

                    getProvinces()
                }
            }
            is QuestionaryEvent.saveAddressData -> {
                isContentLoading.value = true

                viewModelScope.launch {
                    getIndustries()
                    getOccupations()

                    setAddressData(viewEvent.data)
                }
            }
            is QuestionaryEvent.saveContactsData -> {
                isContentLoading.value = true

                viewModelScope.launch {
                    setContactsData(viewEvent.data)
                }
            }
            is QuestionaryEvent.saveDocType -> {
                isContentLoading.value = true
                viewModelScope.launch {
                    setDocType(DocTypeRequestBody(viewState.docType.value))
                    println("setDocType ${viewState.docType.value}")
                }
            }
            QuestionaryEvent.onBackClicked -> {
                clearActions()
                isContentLoading.value = false

                viewAction = QuestionaryAction.openPreviousPage
            }
            is QuestionaryEvent.saveJobData -> {
                viewModelScope.launch {
                    setIncomeData(viewEvent.data)

                    getRelations()
                }
            }
            is QuestionaryEvent.updateEmailName -> {
                viewState.email.value = viewEvent.data
                setEmailValid(validateEmail(viewEvent.data))
            }
            is QuestionaryEvent.updateFirstName -> {
                updateFirstName(viewEvent.data)
                setFirstValid(validateNames(viewEvent.data))
            }
            is QuestionaryEvent.updateLastName -> {
                updateLastName(viewEvent.data)
                setLastValue(validateNames(viewEvent.data))
            }
            is QuestionaryEvent.updateMiddleName -> {
                updateMiddleName(viewEvent.data)
                setMiddleValue(validateNames(viewEvent.data))
            }
            is QuestionaryEvent.updateSerialNumber -> {
                updateSerialNumber(viewEvent.data)
            }
            is QuestionaryEvent.updateIndustry -> {
                viewState.company.value = viewEvent.data
            }
            is QuestionaryEvent.updateOccupation -> {
                viewState.position.value = viewEvent.data
            }
            is QuestionaryEvent.updateComtactPhone -> {
                println("QuestionaryEvent.updateComtactPhone" + viewState.contactPhone.value + "event" + viewEvent.data)
                viewState.contactPhone.value = viewEvent.data
            }
            is QuestionaryEvent.updateContactName -> {
                viewState.contactName.value = viewEvent.data
                viewState.contactValid.value = validateNames(viewEvent.data)
            }

            is QuestionaryEvent.provinceSelected -> {
                viewModelScope.launch {
                    getCities(viewEvent.data)
                    println("QuestionaryEvent.provinceSelected")
                }

                cleanAfterProvince()
            }
            is QuestionaryEvent.citySelected -> {
                cleanAfterCity()
            }

            is QuestionaryEvent.validateComtactPhone -> {
                println("QuestionaryEvent.validateComtactPhone" + viewState.contactPhone.value)

                viewState.phoneValid.value = validatePhoneNumber(viewEvent.data, viewState.phoneCode)
            }

            is QuestionaryEvent.updateDateOfBirth -> {
                setDateOfBirth(viewEvent.data)
            }
            is QuestionaryEvent.updateDateOfExpired -> {
                setDateOfExpired(viewEvent.data)
            }
            is QuestionaryEvent.updateDateOfIssue -> {
                setDateOfIssue(viewEvent.data)
            }

            is QuestionaryEvent.getBarangaySuggestions -> {
                viewModelScope.launch {
                    getSuggestions(viewEvent.data)
                }
            }

            is QuestionaryEvent.getZipSuggestions -> {
                viewModelScope.launch {
                    getZipSuggestions(viewEvent.data)
                }
            }

            is QuestionaryEvent.clearBarangaySuggestions -> {
                viewState.barangay.value = ""
            }
            is QuestionaryEvent.clearZipSuggestions -> {
                viewState.zip.value = ""
            }
        }
    }

    private suspend fun getSuggestions(suggestion: String) {
        var result: List<GeoSuggestion> = listOf()

        coroutineScope {
            try {
                val suggestions = ("${viewState.selectedProvince.value} ${viewState.selectedCity.value} $suggestion")

                networkRepository.getBarangays(suggestions.toString()).collect { resp ->
                    when (resp.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.barangays.value = resp?.data?.suggestions!!
                        }
                        ApiStatus.LOADING -> {}
                        else -> {
                            println("Suggestions Err")
                        }
                    }
                }
            } catch (ex: NoTransformationFoundException) {
                println("Suggestions Exception" + ex.message.toString())
            }
        }
    }

    private suspend fun getZipSuggestions(suggestion: String) {

        coroutineScope {
            try {
                val suggestions = if (suggestion.length > 3) "$suggestion ${viewState.barangay.value}" else suggestion
                networkRepository.getZips(suggestions.toString()).collect { resp ->
                    when (resp.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.zips.value = resp?.data?.suggestions!!
                        }
                        ApiStatus.LOADING -> {}
                        else -> {
                            println("Suggestions Err")
                        }
                    }
                }
            } catch (ex: NoTransformationFoundException) {
                println("Suggestions Exception" + ex.message.toString())
            }
        }
    }


    private suspend fun getDocumentTypes() {
        coroutineScope {
            try {
                networkRepository.getDocumentTypes().collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.documentTypes.value = response.data?.results!!.toMutableList()
                            println(response.data?.results.orEmpty())
                            println("response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println("getDocumentTypes response ERROR")
                            viewAction = QuestionaryAction.ShowErrorMsg

                        }
                        ApiStatus.LOADING -> {
                            println("response LOADING")
                        }

                        ApiStatus.NOT_FOUND -> TODO()
                    }
                }
            } catch (err: Exception) {
                println("getDocTypes Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun getProvinces() {
        coroutineScope {
            try {
                networkRepository.getProvinces().collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.provinces.value = response.data.orEmpty()
                            println("response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println(" getProvinces response ERROR")
                        }
                        ApiStatus.LOADING -> {
                            println("getProvinces response LOADING")
                        }
                        ApiStatus.NOT_FOUND -> {
                            println("getProvincesresponse NOT FOUND")
                        }
                    }
                }
            } catch (err: Exception) {
                println("getProvince Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun getCities(province: String) {
        coroutineScope {
            try {
                networkRepository.getCities(province).collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.cities.value = response.data.orEmpty()
                            println("getCities response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println("getCities response ERROR")
                        }
                        ApiStatus.LOADING -> {
                            println("getCities response LOADING")
                        }

                        ApiStatus.NOT_FOUND -> {
                            println("getCities response NOT FOUND")
                        }
                    }
                }
            } catch (err: Exception) {
                println("getProvince Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun getIndustries() {
        coroutineScope {
            try {
                networkRepository.getIndustries().collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.industries.value = response.data?.response!!
                            println("response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println("response ERROR")

                        }
                        ApiStatus.LOADING -> {
                            println("response LOADING")
                        }

                        ApiStatus.NOT_FOUND -> {}
                    }
                }
            } catch (err: Exception) {
                println("getIndustries Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun getRelations() {
        coroutineScope {
            try {
                networkRepository.getRelations().collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.relationships.value = response.data?.response!!
                            println("response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println("response ERROR")

                        }
                        ApiStatus.LOADING -> {
                            println("response LOADING")
                        }

                        ApiStatus.NOT_FOUND -> TODO()
                    }
                }
            } catch (err: Exception) {
                println("getRelations Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun getOccupations() {
        coroutineScope {
            try {
                networkRepository.getOccupations().collect{ response ->
                    when (response.status) {
                        ApiStatus.SUCCESS -> {
                            viewState.occupations.value = response.data?.response!!
                            println("response SUCCESS")
                        }
                        ApiStatus.ERROR -> {
                            println("response ERROR")

                        }
                        ApiStatus.LOADING -> {
                            println("response LOADING")
                        }

                        ApiStatus.NOT_FOUND -> {

                        }
                    }
                }
            } catch (err: Exception) {
                println("getOccupations Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
            }
        }
    }

    suspend fun setDocType(docTypeRequestBody: DocTypeRequestBody) {
        coroutineScope {
            try {
                networkRepository.setDocumentType(docTypeRequestBody).collect {
                    when (it.status) {
                        ApiStatus.SUCCESS -> {
                            println(it.data.toString())
                            println("setDocType response SUCCESS")

                            viewAction = QuestionaryAction.openNextPage
                        }
                        ApiStatus.LOADING -> {
                        }
                        else -> {
                            println(it.data.toString())
                            println("setDocType response NOT SUCCESS")

                            viewAction = QuestionaryAction.ShowErrorMsg
                        }
                    }
                }
            } catch (ex: Exception) {
                println("setDocType Exeption -" + ex.message.orEmpty() + ex.cause?.message.orEmpty())

                viewAction = QuestionaryAction.ShowErrorMsg
            }
        }
    }

    suspend fun setPersonalData(dataRequestBody: PersonalDataRequestBody) {
        coroutineScope {
            try {
                networkRepository.setPersonalData(dataRequestBody).collect {
                    when (it.status) {
                        ApiStatus.SUCCESS -> {
                            viewAction = QuestionaryAction.openNextPage
                        }
                        ApiStatus.LOADING -> {
                        }
                        else -> {
                            println(it.data.toString())
                            println("setPersonalData response NOT SUCCESS")

                            viewAction = QuestionaryAction.ShowErrorMsg
                        }
                    }
                }
            } catch (ex: Exception) {
                println("setPersonalData Exeption -" + ex.message.orEmpty() + ex.cause?.message.orEmpty())

                viewAction = QuestionaryAction.ShowErrorMsg
            }
        }
    }

    suspend fun setAddressData(addressRequestBody: AddressRequestBody) {
        try {
            networkRepository.setAddressData(addressRequestBody).collect {
                when (it.status) {
                    ApiStatus.SUCCESS -> {
                        println("setAddressData SUCCESS")

                        viewAction = QuestionaryAction.openNextPage
                    }
                    ApiStatus.ERROR -> {

                        println("setAddressData response NOT SUCCESS")

                        viewAction = QuestionaryAction.ShowErrorMsg
                    }
                    ApiStatus.NOT_FOUND -> {
                        println("setAddressData response NOT_FOUND")


//                            viewAction = QuestionaryAction.ShowErrorMsg //temporary while request broken
                    }
                    else -> {
                        println("setAddressData LOADING")

                    }
                }
            }
        } catch (ex: Exception) {

            println("setAddressData response Exeption + ${ex.message.toString()}")

            viewAction = QuestionaryAction.ShowErrorMsg
        }
    }

    suspend fun setIncomeData(incomeRequestBody: IncomeRequestBody) {
        coroutineScope {
            try {
                networkRepository.setIncomeData(incomeRequestBody).collect {
                    when (it.status) {
                        ApiStatus.SUCCESS -> {
                            viewAction = QuestionaryAction.openNextPage

                            viewState.industrySelected.value = ""
                            viewState.occupationSelected.value = ""
                        }
                        ApiStatus.ERROR -> {

                            println("setIncomeData response NOT SUCCESS")

                            viewAction = QuestionaryAction.ShowErrorMsg
                        }
                        ApiStatus.NOT_FOUND -> {

//                            viewAction = QuestionaryAction.ShowErrorMsg //temporary while request broken
                        }
                        else -> {
                        }
                    }
                }
            } catch (ex: Exception) {

                println("setIncomeData response Exeption + ${ex.message.toString()}")

                viewAction = QuestionaryAction.ShowErrorMsg
            }
        }
    }

    suspend fun setContactsData(contactsReuestBody: ContactsReuestBody) {
        coroutineScope {
            try {
                networkRepository.setContactsData(contactsReuestBody).collect {
                    when (it.status) {
                        ApiStatus.SUCCESS -> {
                            viewAction = QuestionaryAction.openFinishScreen
                        }
                        ApiStatus.ERROR -> {
                            viewState.isLoading.value = false

                            viewAction = QuestionaryAction.openFinishScreen //temporary while request broken

//                            viewAction = QuestionaryAction.ShowErrorMsg
                        }
                        ApiStatus.NOT_FOUND -> {
                            viewAction = QuestionaryAction.openFinishScreen //temporary while request broken
                        }
                        else -> {
                            viewState.isLoading.value = true
                        }
                    }
                }
            } catch (ex: Exception) {
                viewState.isLoading.value = false

                println("setContactsData response Exeption + ${ex.message.toString()}")

                viewAction = QuestionaryAction.ShowErrorMsg
            }
        }
    }

    suspend fun getCityByProvince(code: String) {
        getCities(code)
    }

    //VALIDATIONS
    fun updateFirstName(name: String) {
        viewState.firstName.value = name
    }

    fun updateMiddleName(name: String) {
        viewState.middleName.value = name
    }

    fun updateLastName(name: String) {
        viewState.lastName.value = name
    }

    fun updateDateOfBirth(date: String) {
        viewState.dateOfBirth.value = date
    }

    fun updateDateOfIssue(date: String) {
        viewState.dateOfIssue.value = date
    }

    fun updateDateOfExpired(date: String) {
        viewState.dateOfExpired.value = date
    }

    fun updateGender(value: String) {
        viewState.gender.value = value
    }

    fun updateSerialNumber(value: String) {
        viewState.serialNumber.value = value
        updateSerialNumValid(validateDocument(value, viewState.docType.value))
    }

    fun updateSerialNumValid(value: Boolean) {
        viewState.isSerialNumValid.value = value
    }

    fun setFirstValid(value: Boolean) {
        viewState.isFirstValid.value = value
    }

    fun setMiddleValue(value: Boolean) {
        viewState.isMiddleValid.value = value
    }

    fun setLastValue(value: Boolean) {
        viewState.isLastValid.value = value
    }

    fun setDateOfBirth(date: String) {
        updateDateOfBirth(date)
        setDateOfBirthValid(validateAge(date))
    }

    private fun setDateOfBirthValid(value: Boolean) {
        viewState.isDateOfBirthValid.value = value
    }

    fun setDateOfIssue(date: String) {
        updateDateOfIssue(date)
        setDateOfIssueValid(validateDocIssue(date))
    }

    fun setDateOfIssueValid(value: Boolean) {
        viewState.isIssueDateValid.value = value
    }

    fun setDateOfExpired(date: String) {
        updateDateOfExpired(date)
        setDateOfExpiredValid(validateDocExpired(date))
    }

    fun setDateOfExpiredValid(value: Boolean) {
        viewState.isExpiredDateValid.value = value
    }

    fun setEmailValid(value: Boolean) {
        viewState.isEmailValid.value = value
    }

    fun cleanAfterProvince() {
        viewState.selectedCity.value = ""
        viewState.barangay.value = ""
        viewState.house.value = ""
        viewState.zip.value = ""
        viewState.street.value = ""
    }

    fun cleanAfterCity() {
        viewState.barangay.value = ""
        viewState.house.value = ""
        viewState.zip.value = ""
        viewState.street.value = ""
    }

    protected fun cleanBarangayAndZip() {
        viewState.barangay.value = ""
        viewState.zip.value = ""
    }

    protected fun cleanBarangay() {
        viewState.barangay.value = ""
    }

    protected fun cleanZipy() {
        viewState.zip.value = ""
    }

    protected fun cleanAfterZip() {
        viewState.house.value = ""
        viewState.zip.value = ""
        viewState.street.value = ""
    }

    fun checkDatesValid() {
        if (viewState.dateOfBirth.value.isNotEmpty())
            viewState.isDateOfBirthValid.value = validateAge(viewState.dateOfBirth.value)

        if (viewState.dateOfExpired.value.isNotEmpty())
            viewState.isExpiredDateValid.value = validateAge(viewState.dateOfExpired.value)

        if (viewState.dateOfIssue.value.isNotEmpty())
            viewState.isIssueDateValid.value = validateAge(viewState.dateOfIssue.value)
    }
}

