package ph.umi.online.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.relocation.BringIntoViewRequester
import androidx.compose.foundation.relocation.bringIntoViewRequester
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusProperties
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.focus.onFocusEvent
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import ph.umi.online.data.requests.AddressRequestBody
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.DropdownGeoSuggestionNew
import ph.umi.online.ui.DropdownListWithSearch
import ph.umi.online.ui.TextFieldWithDescription
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.input_4_numbers
import umib2bonline.composeapp.generated.resources.next_btn_text
import umib2bonline.composeapp.generated.resources.personal_address_barangay
import umib2bonline.composeapp.generated.resources.personal_address_city
import umib2bonline.composeapp.generated.resources.personal_address_house
import umib2bonline.composeapp.generated.resources.personal_address_province
import umib2bonline.composeapp.generated.resources.personal_address_street
import umib2bonline.composeapp.generated.resources.personal_address_title
import umib2bonline.composeapp.generated.resources.personal_address_zipcode
import umib2bonline.composeapp.generated.resources.personal_data_text_error_msg
import umib2bonline.composeapp.generated.resources.select_barangay
import umib2bonline.composeapp.generated.resources.select_industry_from_list
import umib2bonline.composeapp.generated.resources.select_zip

private const val ZipMaxLength = 4
private const val StreetMaxLength = 255
private const val BarangayMaxLength = 128
private const val HouseMaxLength = 255

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun AddressView(
    state: QuestionaryState,
    eventHandler: (QuestionaryEvent) -> Unit
) {
    val scrollState = rememberScrollState()
    val focusManager = LocalFocusManager.current
    val (provinceFocus, cityFocus, streetFocus, barangayFocus,zipFocus,houseFocus) = FocusRequester.createRefs()
    val bringIntoBarangay = remember { BringIntoViewRequester() }
    val bringIntoHouse = remember { BringIntoViewRequester() }
    var hasExecutedGetCityList by remember { mutableStateOf(false) }
    val isNextBtnEnabled = state.isNextFromAddressBtnEnabled.collectAsState(false)
    val loading = state.isLoading.collectAsState()

    val defaultProvince = remember { mutableStateOf("") }

    val provinceList = state.provinces.collectAsState()
    val cityList = state.cities.collectAsState()
    val barList = state.barangays.collectAsState()
    val currentBar = state.barangay.collectAsState()

    val zipList = state.zips.collectAsState()
    val currentZip = state.zip.collectAsState()
    val barangayValid = state.barangayValid.collectAsState()
    val zipValid = state.zipValid.collectAsState()
    val streetValid = state.streetValid.collectAsState()



    LaunchedEffect(state.selectedCity.value, provinceList.value.isNotEmpty()) {
        if (state.selectedProvince.value.isNotEmpty()) {
            val type = provinceList.value.find { it.name == state.selectedProvince.value }
            if (type != null && cityList.value.isEmpty()) {
                eventHandler.invoke(QuestionaryEvent.provinceSelected(type.codename))
            }
        }
    }


    Scaffold(
        modifier = Modifier
            .fillMaxSize()
            .windowInsetsPadding(WindowInsets.safeDrawing),
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.personal_address_title),
                progress = 60,
                onBackClick = {
                    eventHandler.invoke(QuestionaryEvent.onBackClicked)
                }
            )
        },
        bottomBar = {
            UmiElevatedButton(enabled = isNextBtnEnabled.value, label = stringResource(Res.string.next_btn_text), onClick = {
                state.isLoading.value = true

                eventHandler.invoke(QuestionaryEvent.saveAddressData(
                    AddressRequestBody(
                        country = "PH",
                        addressType = "CLIENT_ADDRESS",
                        unit = state.house.value.toString(),
                        city = state.selectedCity.value.toString(),
                        province = state.selectedProvince.value.toString(),
                        street = state.street.value.toString(),
                        zip = state.zip.value.toInt(),
                        barangay = state.barangay.value.toString()
                )))
            })
        }
    ) { padding ->
        Column(
            modifier = Modifier
                .padding(padding)
                .fillMaxWidth()
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            DropdownListWithSearch(
                modifier = Modifier
                    .padding(start = 16.dp, end = 16.dp, bottom = 0.dp)
                    .focusRequester(provinceFocus)
                    .focusProperties { down = cityFocus },
                label = stringResource(Res.string.personal_address_province),
                errorLabel = stringResource(Res.string.select_industry_from_list),
                items = provinceList.value.map { it.name },
                defaultItemIndex = null,
                defaultItem = if (!state.selectedProvince.value.isNullOrBlank()) {
                    provinceList.value.first { it.name == state.selectedProvince.value }.name
                } else null,
                isSearchable = true,
                capitalization = KeyboardCapitalization.Sentences,
                chooseCategory = { newValue ->
                    state.selectedProvince.value = newValue
                    eventHandler.invoke(QuestionaryEvent.provinceSelected(provinceList.value.find { it.name == newValue }?.codename.orEmpty()))
                    focusManager.moveFocus(FocusDirection.Next)
                }
            )

            DropdownListWithSearch(
                modifier = Modifier
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp, bottom = 0.dp)
                    .focusRequester(cityFocus)
                    .focusProperties { down = barangayFocus },
                label = stringResource(Res.string.personal_address_city),
                errorLabel = stringResource(Res.string.select_industry_from_list),
                items = cityList.value.map { it.name },
                defaultItemIndex = null,
                defaultItem = if (!state.selectedCity.value.isNullOrBlank()) {
                    cityList.value.first { it.name == state.selectedCity.value }.name
                } else null,
                isSearchable = true,
                capitalization = KeyboardCapitalization.Sentences,
                chooseCategory = { newValue ->
                    state.selectedCity.value = newValue
                    val type = cityList.value.find { it.name == newValue }
                    if (type != null) {
                        eventHandler.invoke(QuestionaryEvent.citySelected(type.codename))

                        focusManager.moveFocus(FocusDirection.Next)
                    }
                }
            )

            DropdownGeoSuggestionNew(
                modifier = Modifier
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp)
                    .focusRequester(barangayFocus)
                    .focusProperties { next = zipFocus },
                isLoading = state.isLoadingDictionary,
                items = barList.value,
                value = currentBar.value,
                label = stringResource(Res.string.personal_address_barangay),
                errorLabel = stringResource(Res.string.personal_data_text_error_msg),
                chooseCategory = { suggestion ->
                    val parts = suggestion.text.split(",")
                    val newBarangay = parts[0]

                    if (newBarangay.isNotEmpty()) {
                        if (newBarangay.length <= BarangayMaxLength) {
                            state.barangay.value = newBarangay
                            state.zip.value = ""
                            state.street.value = ""
                        }
                    }
                    focusManager.moveFocus(FocusDirection.Next)
                },
                onValueChange = {
                    if (it.isEmpty()) {
                        //                        viewModel.cleanBarangayAndSuggestion()
                    }
                    if (it.isNotEmpty()) {
                        println(barList.value.toString())
                        state.barangay.value = it

                        eventHandler.invoke(QuestionaryEvent.getBarangaySuggestions(it))
                    }
                },
                onClear = {
                    eventHandler.invoke(QuestionaryEvent.clearBarangaySuggestions)
                    focusManager.clearFocus()
                },

                isError = !barangayValid.value,
                supportingText = stringResource(Res.string.select_barangay),
                maxLength = state.BarangayMaxLength,
                capitalization = KeyboardCapitalization.Sentences,
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                })
            )

            var isCheckZip by rememberSaveable { mutableStateOf(false) }

            if (isCheckZip) {
                //                viewModel.setZipValid(if (state.zip.isEmpty()) true else state.zip.length == 4)
                isCheckZip = false
            }

            DropdownGeoSuggestionNew(
                modifier = Modifier
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp)
                    .focusRequester(zipFocus)
                    .focusProperties { next = streetFocus },
                isLoading = state.isLoadingDictionary,
                items = zipList.value,
                value = currentZip.value,
                label = stringResource(Res.string.personal_address_zipcode),
                errorLabel = stringResource(Res.string.input_4_numbers),
                chooseCategory = { suggestion ->
                    //                    viewModel.getExtraAddressInfo(suggestion.magicKey, false)
                    val parts = suggestion.text.split(",")
                    val newZip = parts[0]
                    //
                    if (newZip.isNotEmpty()) {
                        state.zip.value = newZip

                        isCheckZip = true
                    }

                    focusManager.moveFocus(FocusDirection.Next)
                },
                onValueChange = {
                    if (it.isEmpty()) {
                        //                        viewModel.cleanZipCode()
                    }
                    if (it.isNotEmpty()) {
                        state.zip.value = it
                        eventHandler.invoke(QuestionaryEvent.getZipSuggestions(it))
                    }

                    //                    viewModel.setZipValid(true)
                },
                onClear = {
                    eventHandler.invoke(QuestionaryEvent.clearZipSuggestions)
                    focusManager.clearFocus()
                },
                keyboardType = KeyboardType.NumberPassword,
                isError = !zipValid.value,
                supportingText = stringResource(Res.string.select_zip),
                maxLength = state.ZipMaxLength,
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                })
            )


            TextFieldWithDescription(
                modifier = Modifier
                    .padding(start = 16.dp, end = 16.dp, top = 16.dp)
                    .bringIntoViewRequester(bringIntoBarangay)
                    .onFocusEvent {
                        //                        if (it.isFocused || it.hasFocus) {
                        //                            coroutineScope.launch {
                        //                                delay(250)
                        //                                bringIntoBarangay.bringIntoView()
                        //                            }
                        //                        }
                        //
                        //                        if (!it.isFocused && street.isNotEmpty()) {
                        //                            viewModel.setStreetValid(street.isNotEmpty())
                        //                        }
                    }
                    .focusRequester(streetFocus)
                    .focusProperties { down = houseFocus },
                label = stringResource(Res.string.personal_address_street),
                text = state.street.value,
                onValueChange = {
                    if (it.length <= StreetMaxLength) {
                        state.street.value = it
                        //                        viewModel.updateStreet(it)
                        //                        viewModel.setStreetValid(true)
                    }
                },
                keyboardActions = KeyboardActions (onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                }),
                //                keyboardActions = KeyboardActions(onNext = {
                //                    if (street.isNotEmpty())
                //                        viewModel.setStreetValid(street.isNotEmpty())
                //                    focusManager.moveFocus(FocusDirection.Down)
                //                }),
                keyboardType = KeyboardType.Text,
                maxLength = StreetMaxLength,
                isError = !streetValid.value,
                capitalization = KeyboardCapitalization.Sentences
            )

            TextFieldWithDescription(
                modifier = Modifier
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp)
                    .bringIntoViewRequester(bringIntoHouse)
                    //                    .onFocusEvent {
                    //                        if (it.isFocused || it.hasFocus) {
                    //                            coroutineScope.launch {
                    //                                delay(250)
                    //                                bringIntoHouse.bringIntoView()
                    //                            }
                    //                        }
                    //                        if (!it.isFocused && house.isNotEmpty()) {
                    //                            viewModel.setHouseValid(house.isNotEmpty())
                    //                        }
                    //                    }
                    .focusRequester(houseFocus),
                label = stringResource(Res.string.personal_address_house),
                text = state.house.value,
                onValueChange = {
                    if (it.length <= HouseMaxLength) {
                        state.house.value = it

                        //                        viewModel.updateHouse(it)
                        //                        viewModel.setHouseValid(true)
                    }
                },
                //                keyboardActions = KeyboardActions(onNext = {
                //                    focusManager.moveFocus(FocusDirection.Down)
                //                    if (house.isNotEmpty()) {
                //                        viewModel.setHouseValid(house.isNotEmpty())
                //                    }
                //                }),
                keyboardType = KeyboardType.Text,
                keyboardActions = KeyboardActions (onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                }),
                maxLength = HouseMaxLength,
                isError = !state.houseValid,
                capitalization = KeyboardCapitalization.Sentences
            )
        }
    }
}

@Preview
@Composable
fun AddressView_Preview() {

}