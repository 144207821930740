package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import ph.umi.online.order.data.AdditionalProduct
import ph.umi.online.order.data.OrderCart

@Serializable
data class OrderResponse(
    @SerialName("created")
    val created: String,

    @SerialName("order_number")
    val order_number: String,

    @SerialName("status")
    val status: String, //TODO need to check for skip Questionnaire pages

    @SerialName("selected_down_payment")
    val selected_down_payment: Double,

    @SerialName("selected_loan_term")
    val selected_loan_term: Int,

    @SerialName("selected_loan_amount")
    val selected_loan_amount: Double,

    @SerialName("cart")
    val cart: OrderCart,

    @SerialName("additional_products")
    val additional_products: List<AdditionalProduct>,

    @SerialName("client_questionnaire")
    val client: ClientInfo,

    @SerialName("pos")
    val pos: PosInfo

)

@Serializable
data class ClientInfo(
    @SerialName("first_name")
    val first_name: String,

    @SerialName("middle_name")
    val middle_name: String?,

    @SerialName("last_name")
    val last_name: String,
)

@Serializable
data class PosInfo(
    @SerialName("name")
    val name: String,

    @SerialName("latitude")
    val latitude: Double?,

    @SerialName("longitude")
    val longitude: Double?,

    @SerialName("address")
    val address: String
)

@Serializable
data class Address(
    @SerialName("unit")
    val unit: Int,

    @SerialName("street")
    val street: String,

    @SerialName("barangay")
    val barangay: String,

    @SerialName("city")
    val city: String,

    @SerialName("province")
    val province: String,

    @SerialName("zip_code")
    val zip_code: Int,
)
