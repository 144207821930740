package ph.umi.online.questionary.models

import ph.umi.online.data.requests.AddressRequestBody
import ph.umi.online.data.requests.ContactsReuestBody
import ph.umi.online.data.requests.IncomeRequestBody
import ph.umi.online.data.requests.PersonalDataRequestBody

sealed class QuestionaryEvent {
    data class saveContactsData(val data: ContactsReuestBody): QuestionaryEvent()
    data class saveDocType(val data: String): QuestionaryEvent()
    data class saveAddressData(val data: AddressRequestBody): QuestionaryEvent()
    data class saveJobData(val data: IncomeRequestBody): QuestionaryEvent()
    data class savePersonalData(val data: PersonalDataRequestBody): QuestionaryEvent()
    data object onBackClicked: QuestionaryEvent()
    data class updateFirstName(val data: String): QuestionaryEvent()
    data class updateMiddleName(val data: String): QuestionaryEvent()
    data class updateLastName(val data: String): QuestionaryEvent()
    data class updateDateOfBirth(val data: String): QuestionaryEvent()
    data class updateDateOfIssue(val data: String): QuestionaryEvent()
    data class updateDateOfExpired(val data: String): QuestionaryEvent()
    data class updateEmailName(val data: String): QuestionaryEvent()
    data class updateSerialNumber(val data: String): QuestionaryEvent()
    data class updateIndustry(val data: String): QuestionaryEvent()
    data class updateOccupation(val data: String): QuestionaryEvent()
    data class updateContactName(val data: String): QuestionaryEvent()
    data class updateComtactPhone(val data: String): QuestionaryEvent()
    data class validateComtactPhone(val data: String): QuestionaryEvent()
    data class provinceSelected(val data: String): QuestionaryEvent()
    data class citySelected(val data: String): QuestionaryEvent()
    data class getBarangaySuggestions(val data: String): QuestionaryEvent()
    data object clearBarangaySuggestions: QuestionaryEvent()
    data class getZipSuggestions(val data: String): QuestionaryEvent()
    data object clearZipSuggestions: QuestionaryEvent()
}