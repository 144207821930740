package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ContactsReuestBody(
    @SerialName("contact_relationship_type")
    val relation: String,

    @SerialName("contact_full_name")
    val fullName: String?,

    @SerialName("contact_phone_number")
    val phone: String
)
