package ph.umi.online.utils

fun getClientName(firstName: String?, lastName: String?, middleName: String?) : String {
    if (lastName == null) return ""

    val myFirstName = if (firstName != null) "$firstName" else ""
    val myMiddleName = if (middleName != null) "$middleName" else ""

    return "$lastName $myMiddleName$myFirstName"
}

fun getClientPhone(phoneNumber: String?, phoneCode: String): String {
    if (phoneNumber == null) return ""
    if (phoneNumber.isEmpty()) return ""
    val phone = StringBuilder(phoneNumber)
    phone.insert(2, '-')
    return "+$phoneCode (***) *** $phone"
}

//fun showTotalPrice(price: Double?): String {
//    if (price == null || price == 0.0) return "0"
//    val nf: NumberFormat = NumberFormat.getNumberInstance(Locale.US)
//    val df = nf as DecimalFormat
//    df.applyPattern("#,###,###.00")
//
//    var newPrice = df.format(price)
//
//    val indexOfDecimal: Int = newPrice.indexOf(".")
//    val decimalPart = newPrice.substring(indexOfDecimal + 1)
//
//    newPrice = if (decimalPart == "00") newPrice.replace(".00", "") else newPrice
//
//    return newPrice.replace(",", " ")
//}

//Convert Double to String with space after every third character
fun formatDoubleToStringWithSpace(value: Int?): String {
    if (value == null) return "0"

    val numberString = value.toString()

    val reversedString = numberString.reversed()
    val formattedReversedString = reversedString.chunked(3).joinToString(" ")
    return formattedReversedString.reversed()
}

//fun formatDoubleToStringWithSpace(value: Double): String {
//    return String.format("%,.0f", value).replace(",", " ")
//}

fun String.withThousands(separator: Char = ','): String {
    val original = this
    return buildString {
        original.indices.forEach { position ->
            val realPosition = original.lastIndex - position
            val character = original[realPosition]
            insert(0, character)
            if (position != 0 && realPosition != 0 && position % 3 == 2) {
                insert(0, separator)
            }
        }
    }
}