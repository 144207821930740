package ph.umi.online.ui

import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.input.OffsetMapping
import androidx.compose.ui.text.input.TransformedText
import androidx.compose.ui.text.input.VisualTransformation
import kotlin.math.max
import kotlin.math.min

class ThousandSeparatorVisualTransformation(
    private var maxFractionDigits: Int = 2,
    private var minFractionDigits: Int = 0,
) : VisualTransformation {

    private val spaceReplacementPattern = Regex("\\B(?=(?:\\d{3})+(?!\\d))")

    override fun filter(text: AnnotatedString): TransformedText {
        if (text.isEmpty()) return TransformedText(text, OffsetMapping.Identity)

        val space = ' '
        val dot = '.'
        val comma = ','

        // Replace commas with dot
        val newText = text.text.replace(comma, dot)

        // Divide the integer and fractional parts
        var (intPart, fracPart) = newText.split(dot).let { Pair(it[0], it.getOrNull(1)) }

        // Format the integer part with spaces for thousandths
        val normalizedIntPart = intPart.replace(spaceReplacementPattern, space.toString())

        // Limit the number of characters after the dot (fractional part)
        val minFractionDigits = min(this.maxFractionDigits, this.minFractionDigits)
        fracPart = fracPart?.take(maxFractionDigits)?.padEnd(minFractionDigits, '0')

        // We collect the final text with formatting
        val formattedText = normalizedIntPart + if (fracPart != null) ".$fracPart" else ""

        // Create a new AnnotatedString for display
        val resultText = AnnotatedString(formattedText, text.spanStyles, text.paragraphStyles)

        // Correct OffsetMapping for working with cursor
        val offsetMapping = ThousandSeparatorOffsetMapping(
            originalText = newText,
            formattedText = formattedText,
            spaceCount = normalizedIntPart.count { it == space }
        )

        return TransformedText(resultText, offsetMapping)
    }

    private inner class ThousandSeparatorOffsetMapping(
        val originalText: String,
        val formattedText: String,
        val spaceCount: Int
    ) : OffsetMapping {

        // Converting the original index to a transformed one
        override fun originalToTransformed(offset: Int): Int {
            return if (offset > originalText.length) {
                formattedText.length
            } else {
                val spaceAdded = (offset / 3)
                min(offset + spaceAdded, formattedText.length)
            }
        }

        // Converting the transformed index to the original
        override fun transformedToOriginal(offset: Int): Int {
            if (offset <= 0) {
                return 0 // Processing the beginning of the text to avoid negative indices
            }

            return if (offset > formattedText.length) {
                originalText.length // Processing the end of the text to avoid going beyond the bounds
            } else {
                // We take into account the number of added spaces
                val spaceRemoved = offset - spaceCount
                max(min(spaceRemoved, originalText.length), 0) // Ensure that the result does not exceed [0, originalText.length]
            }
        }
    }
}