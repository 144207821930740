package ph.umi.online.data

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.cookies.cookies
import io.ktor.client.request.HttpSendPipeline
import io.ktor.client.request.accept
import io.ktor.client.request.cookie
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.prepareGet
import io.ktor.client.request.put
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.HttpResponsePipeline
import io.ktor.client.statement.bodyAsChannel
import io.ktor.http.ContentType
import io.ktor.http.Cookie
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.cookies
import io.ktor.http.headers
import io.ktor.http.setCookie
import io.ktor.util.date.GMTDate
import io.ktor.util.date.Month
import io.ktor.util.pipeline.intercept
import io.ktor.utils.io.copyAndClose
import kotlinx.coroutines.flow.Flow
import ph.umi.online.data.requests.AddressRequestBody
import ph.umi.online.data.requests.ContactsReuestBody
import ph.umi.online.data.requests.DocTypeRequestBody
import ph.umi.online.data.requests.IncomeRequestBody
import ph.umi.online.data.requests.MetadataRequest
import ph.umi.online.data.requests.PersonalDataRequestBody
import ph.umi.online.data.requests.VerificationCheckBody
import ph.umi.online.data.responses.CitiesResponse
import ph.umi.online.data.responses.ContinueResponse
import ph.umi.online.data.responses.DocTypesResponse
import ph.umi.online.data.responses.GeoSuggestionsResponse
import ph.umi.online.data.responses.IndustriesResponse
import ph.umi.online.data.responses.OccupationsResponse
import ph.umi.online.data.responses.OrderResponse
import ph.umi.online.data.responses.OtpProperties
import ph.umi.online.data.responses.ProvinceResponse
import ph.umi.online.data.responses.RelationsResponse
import ph.umi.online.data.responses.SendAgainResponse

class NetworkRepository(private val httpClient: HttpClient) {

    //AUTHORIZATION, CONTINUE
    private var token: String = "" //may be empty when process start's
    private lateinit var guidShort: String


    fun setToken(jwt: String) {
        this.token = jwt
    }

    fun getToken(): String {
        return this.token
    }

    fun setGuid(huid: String) {
        this.guidShort = huid
    }

    fun authorize(application: String, otp: String, agreement: Boolean?) : Flow<NetworkResult<OtpProperties>> {
        return toResultFlow {
            val response = httpClient
                .post("api/v1/remote/verification/check") {
                    contentType(ContentType.Application.Json)
                    setBody(VerificationCheckBody(application, otp, agreement))
                }

            if (response.status.value in 200..299) NetworkResult.Success(response.body<OtpProperties>()) else if (response.status.value == 404) NetworkResult.NotFound("") else NetworkResult.Error(null, "")
        }
    }

    fun updateMetadaata(umid: String, body: MetadataRequest) : Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/device-metadata/$umid/update") {
                    contentType(ContentType.Application.Json)
                    setBody(body)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<OrderResponse>()) else NetworkResult.Error(null, "")
        }
    }

    fun sendAgain() : Flow<NetworkResult<SendAgainResponse>> {
        return toResultFlow {
            val response = httpClient
                .post("api/v1/remote/orders/$guidShort/send-again") {
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<SendAgainResponse>()) else if (response.status.value == 404) NetworkResult.NotFound("") else if (response.status.value == 403) NetworkResult.Error(null, "403") else NetworkResult.Error(null, "")
        }
    }

    fun continueAction() : Flow<NetworkResult<ContinueResponse>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/orders/$guidShort/actions/continue") {
                    contentType(ContentType.Application.Json)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<ContinueResponse>()) else if (response.status.value == 404) NetworkResult.NotFound("") else NetworkResult.Error(response.body<ContinueResponse>(), "")
        }
    }


    fun getOrderDetails(): Flow<NetworkResult<OrderResponse>> {
        return toResultFlow {
            val response = httpClient.get("api/v1/remote/orders/$guidShort"){
                headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
            }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<OrderResponse>()) else if (response.status.value == 404) NetworkResult.NotFound("") else NetworkResult.Error(null, "")
        }
    }

    //DOCUMENT

    fun getDocumentTypes(): Flow<NetworkResult<DocTypesResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/document-types?channel=REMOTE") {
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<DocTypesResponse>()) else NetworkResult.Error(null, "")
        }
    }

    fun setDocumentType(docTypeRequestBody: DocTypeRequestBody): Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/orders/$guidShort/document-type") {
                    contentType(ContentType.Application.Json)
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                    setBody(docTypeRequestBody)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<Any>()) else NetworkResult.Error(null, "")
        }
    }

    //QUESTIONNAIRE

    fun setPersonalData(dataRequestBody: PersonalDataRequestBody): Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/orders/$guidShort/documents") {
                    contentType(ContentType.Application.Json)
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                    setBody(dataRequestBody)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<Any>()) else NetworkResult.Error(null, "")
        }
    }

    fun setAddressData(addressRequestBody: AddressRequestBody): Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .post("api/v1/remote/orders/$guidShort/addresses") {
                    contentType(ContentType.Application.Json)
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                    setBody(addressRequestBody)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<Any>()) else NetworkResult.Error(null, "")
        }
    }

    fun setIncomeData(incomeRequestBody: IncomeRequestBody): Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/orders/$guidShort/income-data") {
                    contentType(ContentType.Application.Json)
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                    setBody(incomeRequestBody)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<Any>()) else NetworkResult.Error(null, "")
        }
    }

    fun setContactsData(contactsReuestBody: ContactsReuestBody): Flow<NetworkResult<Any>> {
        return toResultFlow {
            val response = httpClient
                .put("api/v1/remote/orders/$guidShort/contact-person") {
                    contentType(ContentType.Application.Json)
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                    setBody(contactsReuestBody)
                }
            if (response.status.value in 200..299) NetworkResult.Success(response.body<Any>()) else NetworkResult.Error(null, "")
        }
    }

    //DICTIONARIES

    fun getProvinces(): Flow<NetworkResult<List<ProvinceResponse>>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/provinces?channel=REMOTE") {
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
                .body<List<ProvinceResponse>>()
            NetworkResult.Success(response)
        }
    }

    fun getCities(province: String): Flow<NetworkResult<List<CitiesResponse>>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/cities?channel=REMOTE&province_code=$province") {
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
                .body<List<CitiesResponse>>()
            NetworkResult.Success(response)
        }
    }

    fun getBarangays(suggestion: String): Flow<NetworkResult<GeoSuggestionsResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=pjson&countryCode=ph&category=Neighborhood&maxSuggestions=5&text=${suggestion}") {
//                    headers.append(HttpHeaders.ContentType, "text/plain; charset=UTF-8")
//                    contentType(ContentType.Text.Plain)
//                    accept(ContentType.Text.Plain)
//                    headers.append(HttpHeaders.Accept, "text/plain; charset=UTF-8")
                }
                .body<GeoSuggestionsResponse>()
            NetworkResult.Success(response)
        }
    }

    fun getZips(suggestion: String): Flow<NetworkResult<GeoSuggestionsResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=pjson&countryCode=ph&category=Postal&maxSuggestions=5&text=${suggestion}") {
//                    headers.append(HttpHeaders.ContentType, "text/plain; charset=UTF-8")
//                    contentType(ContentType.Text.Plain)
//                    accept(ContentType.Text.Plain)
//                    headers.append(HttpHeaders.Accept, "text/plain; charset=UTF-8")
                }
                .body<GeoSuggestionsResponse>()
            NetworkResult.Success(response)
        }
    }

    fun getOccupations(): Flow<NetworkResult<OccupationsResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/occupation-types?channel=REMOTE"){
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
                .body<OccupationsResponse>()
            NetworkResult.Success(response)
        }
    }

    fun getRelations(): Flow<NetworkResult<RelationsResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/relationship-types?channel=REMOTE"){
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
                .body<RelationsResponse>()
            NetworkResult.Success(response)
        }
    }

    fun getIndustries(): Flow<NetworkResult<IndustriesResponse>> {
        return toResultFlow {
            val response = httpClient
                .get("api/v1/industry-types?channel=REMOTE") {
                    headers.append(HttpHeaders.Authorization, "JWT ".plus(token))
                }
                .body<IndustriesResponse>()
            NetworkResult.Success(response)
        }
    }
}