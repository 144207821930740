package ph.umi.online.questionary.models

import ph.umi.online.otp.models.OtpAction

sealed class QuestionaryAction {
    data object sendPersonData : QuestionaryAction()
    data object sendContactsData : QuestionaryAction()
    data object sendJobData : QuestionaryAction()
    data object sendAddressData : QuestionaryAction()
    data object openFinishScreen: QuestionaryAction()
    data object openNextPage: QuestionaryAction()
    data object openPreviousPage: QuestionaryAction()
    data object ShowErrorMsg : QuestionaryAction()


}