package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import ph.umi.online.questionary.data.GeoSuggestion

@Serializable
data class GeoSuggestionsResponse(
    @SerialName("suggestions")
    val suggestions: List<GeoSuggestion>
)
