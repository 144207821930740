package ph.umi.online.order

import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ph.umi.online.core.BaseViewModel
import ph.umi.online.data.ApiStatus
import ph.umi.online.data.NetworkRepository
import ph.umi.online.navigation.ScreensList
import ph.umi.online.order.data.GoodsItem
import ph.umi.online.order.models.OrdersAction
import ph.umi.online.order.models.OrdersEvent
import ph.umi.online.order.models.OrdersState
import ph.umi.online.otp.guidShort
import kotlin.math.ceil


class OrderViewModel(private val networkRepository: NetworkRepository): BaseViewModel<OrdersState, OrdersAction, OrdersEvent>(
    initialState = OrdersState()
) {
    val statusList: List<String> = listOf(
        "LINK_TO_REMOTE_ORDER_GENERATED",
        "DOC_TYPE_SELECTED",
        "SHORT_APP_DATA_FILLED",
        "SHORT_APP_DATA_FILLED",
        "WORK_DATA_FILLED" ,
        "CONTACT_PERSON_DATA_FILLED"
    )
    init {
        networkRepository.setGuid(guidShort) //omg!!!

        if (baseToken.isNotEmpty()) {
            println("set token from OrderViewModel")
            networkRepository.setToken(baseToken)

            getOrderDetails()
        }
    }

    private fun calculateTotalPrice(orderItems: List<GoodsItem>): Double {
        val totalPrice = orderItems.sumOf { item ->
            val itemTotal = (item.price ?: 0.0) * (item.quantity ?: 0)
            val insuranceTotal = item.insurancePrograms.sumOf { it.insurancePrice ?: 0.0 }
            itemTotal + insuranceTotal
        }
        return ceil(totalPrice)
    }

    fun getOrderDetails() {
        viewModelScope.launch {
            coroutineScope {
                try {
                    networkRepository.getOrderDetails().collect{ response ->
                        when (response.status) {
                            ApiStatus.SUCCESS -> {
                                viewState.orderId.value = response.data?.order_number.toString()
                                viewState.clientName.value = response.data?.client?.last_name.orEmpty().plus(" ")
                                    .plus(response.data?.client?.first_name.orEmpty().plus(" ")
                                        .plus(response.data?.client?.middle_name.orEmpty()).plus("!")
                                )
                                val sum = response.data?.additional_products?.sumOf { it.saleAmount ?: 0.0 }
                                viewState.priceText.value = (response.data?.cart?.totalAmount?.plus(
                                    sum!!
                                )).toString()
                                viewState.ordersList.value = response.data?.cart?.items.orEmpty()
                                viewState.additionalProduct.value = response.data?.additional_products.orEmpty()

                                //for finish screen
                                viewState.posName.value = response.data?.pos?.name.orEmpty()
                                viewState.posAddr.value = response.data?.pos?.address.orEmpty()
                                viewState.lat.value = response.data?.pos?.latitude
                                viewState.long.value = response.data?.pos?.longitude


                                println("getOrderDetails response SUCCESS")
                                launch {
                                    delay(1500)
                                    viewState.isLoading.value = false
                                }

                                if (!response.data?.status?.isEmpty()!!) {
                                    if (statusList.contains(response.data.status)) {
                                        println("OrdersAction.GetOrders")

                                        viewAction = OrdersAction.GetOrders
                                    }
                                    if (response.data.status == "PRE_APPROVAL_RECEIVED") {
                                        viewAction = OrdersAction.goFinishPage
                                    }
                                }


                            }
                            ApiStatus.ERROR -> {
                                println("rgetOrderDetails esponse ERROR")
                                viewAction = OrdersAction.goRestart
                            }
                            ApiStatus.LOADING -> {
                                viewState.isLoading.value = true
                                println("getOrderDetails response LOADING")
                            }

                            ApiStatus.NOT_FOUND -> {
                                viewAction = OrdersAction.go404Page
                            }
                        }
                    }
                } catch (err: Exception) {
                    println("getOrderDetails Exeption -" + err.message.orEmpty() + err.cause?.message.orEmpty())
                    viewState.isError.value = true
                    launch {
                        delay(1500)
                        viewAction = OrdersAction.goRestart

                    }

                }
            }
        }
    }


    override fun obtainEvent(viewEvent: OrdersEvent) {
        when(viewEvent) {
            OrdersEvent.onError -> {}
            OrdersEvent.onNextBtnClicked -> {
                viewAction = OrdersAction.goNextPage
            }

            OrdersEvent.onCopyBtnClicked -> {
                viewAction = OrdersAction.CopyAddress(viewState.posAddr.value)
            }
            OrdersEvent.onMapBtnClicked -> {
                if (viewState.lat.value != null && viewState.long.value != null) {
                    viewAction = OrdersAction.ShowGmaps(viewState.lat.value.toString(), viewState.long.value.toString())
                } else {

                }
            }
        }
    }
}