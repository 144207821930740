package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ContinueResponse(
    @SerialName("umid_long")
    val umid: String,

    @SerialName("phone_code")
    val phoneCode: String,

    @SerialName("phone_number")
    val phoneNumber: String,

    @SerialName("agreements")
    val agreements: List<Agreement>,
)

@Serializable
data class Agreement(
    @SerialName("agreement_type")
    val type: String,

    @SerialName("link")
    val link: String,
)

