package ph.umi.online.views

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.otp.models.OtpEvent
import ph.umi.online.otp.models.OtpState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_background_gray
import ph.umi.online.theme.umi_error_color
import ph.umi.online.theme.umi_header_color
import ph.umi.online.theme.umi_link_text_color
import ph.umi.online.theme.umi_primary_text_color
import ph.umi.online.ui.NeutralsGrey80
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.IndieFlower_Regular
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.data_privacy_header
import umib2bonline.composeapp.generated.resources.data_privacy_msg
import umib2bonline.composeapp.generated.resources.otp_description_msg
import umib2bonline.composeapp.generated.resources.otp_header
import umib2bonline.composeapp.generated.resources.send_again
import umib2bonline.composeapp.generated.resources.terms_msg

internal expect fun openUrl(url: String?)

@OptIn(ExperimentalMaterial3Api::class, ExperimentalComposeUiApi::class)
@Composable
internal fun OtpView(
    state: OtpState,
    eventHandler: (OtpEvent) -> Unit
) {
    val coroutineScope = rememberCoroutineScope()
    val bottomSheetState = rememberModalBottomSheetState()
    var openBottomSheet by rememberSaveable { mutableStateOf(false) }
//    val focusRequester = remember { FocusRequester() }
//    val focusManager = LocalFocusManager.current

    val scrollState = rememberScrollState()
    val uriHandler = LocalUriHandler.current

    val countdownValue by state.countdown.collectAsState()
    val isSendAgainActive by state.isSendAgainActive.collectAsState()
    var otpLoading = state.isOtpLoading.collectAsState()
    val isSuccess = state.success.collectAsState()
    val otpLocal = state.otpValue.collectAsState()
    var error by remember { mutableStateOf("") }
    val errorMessage by state.errorMessage.collectAsState()
    val isError = state.isError.collectAsState()
    val checked = state.agreed.collectAsState()

//    LaunchedEffect(Unit) {
//        eventHandler.invoke(OtpEvent.onUmidReceived(""))
//    }


    Scaffold(
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.otp_header),
                isBackBtnEnabled = false,
                isDividerEnabled = true
            )
        },
        bottomBar = {}
    ) { paddingValues ->
        Column(modifier = Modifier
            .fillMaxWidth()
            .padding(paddingValues)
            .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Text(
                modifier = Modifier.padding(top = 16.dp, bottom = 8.dp),
                text = stringResource(Res.string.otp_description_msg),
                textAlign = TextAlign.Center,
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = umi_primary_text_color,
                    textAlign = TextAlign.Center,
                    letterSpacing = 0.5.sp,
                )
            )

            Text(
                text = state.phoneCode + " (***) *** " + state.getFormattedPhoneNumber(),
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 22.sp,
                    fontWeight = FontWeight(800),
                    color = umi_primary_text_color,
                    textAlign = TextAlign.Center,
                ),
                modifier = Modifier
                    .fillMaxWidth()
            )

            Text(
                modifier = Modifier.padding(top = 24.dp),
                text = stringResource(Res.string.data_privacy_msg),
                textAlign = TextAlign.Center,
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = umi_primary_text_color,
                    textAlign = TextAlign.Center,
                    letterSpacing = 0.5.sp,
                )
            )

            Box(
                modifier = Modifier
                    .clip(CircleShape)
                    .clickable {
                        openUrl(state.dataPrivacyLink.value.toString())
                    }
            ) {
                Text(
                    text = stringResource(Res.string.otp_header),
                    style = MaterialTheme.typography.titleMedium
                        .copy(
                            color = umi_link_text_color,
                            textDecoration = TextDecoration.Underline,
                        ),
                    modifier = Modifier.padding(vertical = 8.dp, horizontal = 8.dp)
                )
            }

            if (otpLoading.value) {
                Box {
                    CircularProgressIndicator(
                        Modifier.align(Alignment.Center).padding(24.dp),
                        color = umi_background_blue
                    )
                }
            } else {
                OtpTextField(
                    modifier = Modifier
                        .padding(top = 16.dp)
                        .height(88.dp)
                        .align(Alignment.CenterHorizontally),
                    enabled = true,
                    otpValue = otpLocal.value,
                    otpCount = state.otpCount,
                    onValueChange = {
                        if (it.length <= state.otpCount) {
                            state.otpValue.value = it
                        }
                        if (it.length == state.otpCount) {
                            eventHandler.invoke(OtpEvent.OtpChanged(it))
                        }
                    },
                    isError = isError.value,
                    errorMessage = if (isError.value) errorMessage else null,
                )

                Box(
                    modifier = Modifier
                        .wrapContentSize().clip(CircleShape)
                        .modifyIf(countdownValue == 0 && isSendAgainActive) {
                            clickable {
                                eventHandler.invoke(OtpEvent.RequestOtpAgain)
                            }
                    }
                ) {
                    Text(
                        text =
                        if (countdownValue > 0)
                            "SEND AGAIN AFTER  $countdownValue S."
                        else
                            stringResource(Res.string.send_again).uppercase(),
                        style = TextStyle(
                            fontSize = 14.sp,
                            lineHeight = 20.sp,
                            fontWeight = FontWeight(500),
                            color = if (countdownValue > 0) NeutralsGrey80 else if (!isSendAgainActive) NeutralsGrey80 else umi_primary_text_color,
                            textDecoration = if (countdownValue > 0) TextDecoration.None else if (!isSendAgainActive) TextDecoration.None else TextDecoration.Underline,
                            textAlign = TextAlign.Center,
                            letterSpacing = 0.1.sp,
                        ),
                        modifier = Modifier
                            .padding(vertical = 8.dp, horizontal = 8.dp)
                    )
                }
            }

            Spacer(modifier = Modifier.height(24.dp))

            if (state.agreementLink.value.isNotEmpty()) {
                Box(modifier = Modifier.wrapContentHeight()) {
                    Checkbox(
                        modifier = Modifier.wrapContentSize().align(Alignment.TopStart),
                        colors = CheckboxDefaults.colors(
                            checkedColor = umi_background_blue
                        ),
                        checked = checked.value,
                        onCheckedChange = {
                            eventHandler.invoke(OtpEvent.ProtectionChecked(it))
                        }
                    )

                    Column(
                        modifier = Modifier.fillMaxWidth().align(Alignment.TopEnd).padding(start = 32.dp, top = 16.dp),
                        horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.SpaceAround
                    ) {
                        Text(
                            text = stringResource(Res.string.terms_msg),
                            textAlign = TextAlign.Center,
                            style = TextStyle(
                                fontSize = 16.sp,
                                lineHeight = 24.sp,
                                fontWeight = FontWeight(400),
                                color = umi_primary_text_color,
                                textAlign = TextAlign.Center,
                                letterSpacing = 0.5.sp,
                            )
                        )

                        Box(
                            modifier = Modifier
                                .clip(CircleShape)
                                .clickable {
                                    openUrl(state.agreementLink.value)
                                }
                        ) {
                            Text(
                                text = stringResource(Res.string.data_privacy_header),
                                style = MaterialTheme.typography.titleMedium
                                    .copy(
                                        color = umi_link_text_color,
                                        textDecoration = TextDecoration.Underline,
                                        textAlign = TextAlign.Center
                                    ),
                                modifier = Modifier.wrapContentHeight().padding(horizontal = 8.dp, vertical = 8.dp)
                            )
                        }
                    }
                }
            }
        }

        if (openBottomSheet) {
            ModalBottomSheet(
                modifier = Modifier.wrapContentHeight(),
                sheetState = bottomSheetState,
                shape = RoundedCornerShape(20.dp, 20.dp, 0.dp, 0.dp),
                onDismissRequest = {
                    openBottomSheet = false
                },
                content = {
                    Text(
                        text = "Data Privacy Policy",
                        fontFamily = FontFamily(Font(Res.font.IndieFlower_Regular)),
                        style = MaterialTheme.typography.displayLarge
                    )

                    uriHandler.openUri("https://kotlinlang.org/docs/multiplatform.html")
                }
            )
        }
    }
}

@Composable
fun OtpTextField(
    modifier: Modifier = Modifier,
    otpValue: String,
    onValueChange: (String) -> Unit,
    otpCount: Int = 4,
    errorMessage: String? = null,
    isError: Boolean = false,
    onClear: () -> Unit = {},
    enabled: Boolean = true
) {
    val keyboardController = LocalSoftwareKeyboardController.current
    val focusRequester = remember { FocusRequester() }

//    LaunchedEffect(Unit) {
//        delay(1000)
//        focusRequester.requestFocus()
//        keyboardController?.show()
//    }

    Column(modifier = modifier, horizontalAlignment = Alignment.CenterHorizontally) {
        Row(horizontalArrangement = Arrangement.Center) {
            BasicTextField(
                modifier = Modifier.focusRequester(focusRequester).onFocusChanged { if (it.isFocused) keyboardController?.show() },
                value = otpValue,
                onValueChange = {
                    if (it.length <= otpCount) {
                        onValueChange(it)
                    }
                },
                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.NumberPassword),
                decorationBox = {
                    Row(horizontalArrangement = Arrangement.Center) {
                        repeat(otpCount) { index ->
                            val isFocused = otpValue.length == index
                            val char = when {
                                index >= otpValue.length -> ""
                                else -> otpValue[index].toString()
                            }
                            Text(
                                text = char,
                                style = TextStyle(
                                    fontSize = 36.sp,
                                    lineHeight = 28.sp,
                                    fontWeight = FontWeight(400),
                                    color = if (isError) Color.Red else umi_header_color,
                                    textAlign = TextAlign.Center,
                                ),
                                modifier = Modifier
                                    .width(40.dp)
                                    .drawBehind {
                                        val strokeWidth = when {
                                            isFocused -> 4.dp.toPx()
                                            isError && index == otpCount - 1 -> 4.dp.toPx()
                                            else -> 2.dp.toPx()
                                        }
                                        val y = size.height - strokeWidth / 2

                                        val lineColor = when {
                                            isError && index == otpCount - 1 -> umi_background_blue
                                            isFocused -> umi_background_blue
                                            else -> NeutralsGrey67
                                        }

                                        drawRoundRect(
                                            color = lineColor,
                                            topLeft = Offset(0f, y),
                                            size = size.copy(height = strokeWidth),
                                            cornerRadius = CornerRadius(25.dp.toPx(), 25.dp.toPx())
                                        )
                                    }
                            )

                            if (index != otpCount - 1) {
                                Spacer(modifier = Modifier.width(16.dp))
                            }
                        }
                    }
                })

//            if (otpValue.length == otpCount) {
//                IconButton(
//                    onClick = {
//                        onClear()
//                    }
//                ) {
//                    Icon(
//                        painter = painterResource(Res.drawable.ic_rotate_right),
//                        contentDescription = "Clear"
//                    )
//                }
//            }
        }

        if (isError && errorMessage != null) {
            Text(
                text = errorMessage,
                style = TextStyle(
                    fontSize = 12.sp,
                    lineHeight = 20.sp,
                    fontWeight = FontWeight(400),
                    color = Color.Red,
                    textAlign = TextAlign.Center,
                ),
                modifier = Modifier.padding(top = 8.dp)
            )
        }
    }
}

@Composable
fun CharView(
    index: Int,
    text: String,
    isSuccess: Boolean,
    enabled: Boolean
) {
    val isFocused = text.length == index && enabled

    val char = when {
        index == text.length -> ""
        index > text.length -> ""
        else -> text[index].toString()
    }

    val borderColor = when {
        isFocused -> umi_background_blue
        else -> umi_background_gray
    }

    val borderWidth = when {
        isFocused -> 8.0F
        else -> 4.0F
    }

    Text(
        modifier = Modifier
            .width(48.dp)
            .height(48.dp)
            .drawBehind {
                drawLine(
                    color = borderColor,
                    start = Offset.Zero.copy(y = size.height),
                    end = Offset(size.width, size.height),
                    cap = StrokeCap.Round,
                    strokeWidth = borderWidth
                )
            }
            .padding(start = 8.dp, top = 6.dp, end = 8.dp, bottom = 6.dp),
        text = char,
        style = TextStyle(
            fontSize = 36.sp,
            lineHeight = 28.sp,
            fontWeight = FontWeight(400),
            color = umi_header_color,
            textAlign = TextAlign.Right,
            letterSpacing = 0.25.sp,
        ),
        color = if (isFocused) {
            umi_background_blue
        } else if (!isSuccess && text.isNotEmpty()) {
            umi_error_color
        } else {
            umi_header_color
        },
        textAlign = TextAlign.Center
    )
}

fun Modifier.modifyIf(condition: Boolean, modify: Modifier.() -> Modifier) =
    if (condition) modify() else this

