package ph.umi.online.order.models

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import kotlinx.coroutines.flow.MutableStateFlow
import ph.umi.online.order.data.AdditionalProduct
import ph.umi.online.order.data.OrderCartItem

data class OrdersState (
    var orderId : MutableState<String> = mutableStateOf(""),
    var orderGuid : MutableState<String> = mutableStateOf(""),

    var isOrdersNotFound: Boolean = false,
    var isLoading: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var isRefreshing: Boolean = false,
    var priceText: MutableState<String> = mutableStateOf("8200"),
    var currency: String = "₱",
    var isError: MutableState<Boolean> = mutableStateOf(false),

    val clientName: MutableState<String> = mutableStateOf(""),
    val ordersList: MutableStateFlow<List<OrderCartItem>>  = MutableStateFlow(mutableListOf()),
    val additionalProduct: MutableStateFlow<List<AdditionalProduct>>  = MutableStateFlow(mutableListOf()),

    var posName: MutableState<String> = mutableStateOf(""),
    var posAddr: MutableState<String> = mutableStateOf(""),
    var lat: MutableState<Double?> = mutableStateOf(0.0),
    var long: MutableState<Double?> = mutableStateOf(0.0),
    var orderNum: MutableState<String> = mutableStateOf("")
)