package ph.umi.online.questionary.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GeoSuggestion(
    @SerialName("text")
    val text: String,

    @SerialName("magicKey")
    val magicKey: String,

    @SerialName("isCollection")
    val isCollection: Boolean
)
