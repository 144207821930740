package ph.umi.online.di

import UmiB2BOnline.composeApp.BuildConfig
import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.DefaultRequest
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.cookies.CookiesStorage
import io.ktor.client.plugins.cookies.HttpCookies
import io.ktor.client.plugins.logging.DEFAULT
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.accept
import io.ktor.http.ContentType
import io.ktor.http.Cookie
import io.ktor.http.CookieEncoding
import io.ktor.http.HttpHeaders
import io.ktor.http.Url
import io.ktor.serialization.kotlinx.KotlinxSerializationConverter
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import org.koin.dsl.module

data class Cookie(val token: String)

class MyCookiesStorage(private val cookiesStorage: CookiesStorage) : CookiesStorage by cookiesStorage {
    override suspend fun get(requestUrl: Url): List<Cookie> =
        cookiesStorage.get(requestUrl).map { it.copy(encoding = CookieEncoding.RAW) }
}


fun cookieEncoded(encodedValue: String): String  {
    if (encodedValue.trimStart().startsWith("\"") && encodedValue.trimEnd().endsWith("\"") ) {
        return encodedValue.trim().removeSurrounding("\"")
    }
    else  return encodedValue
}

val providehttpClientModule = module {
    single {
        HttpClient(Js) {

            install(DefaultRequest) {
                url(BuildConfig.BASE_BACKEND_URL)
            }

            install(Logging) {
                logger = Logger.DEFAULT
                level = LogLevel.ALL
            }

            install(HttpCookies)
            install(ContentNegotiation) {
                json(json = Json {
                    ignoreUnknownKeys = true
                    explicitNulls = false
                    prettyPrint = true
                    isLenient = true
                } )
                register(ContentType.Text.Plain, KotlinxSerializationConverter(
                    Json {
                        prettyPrint = true
                        isLenient = true
                        ignoreUnknownKeys = true
                    }
                ) )
            }
        }
    }
}