package ph.umi.online.order.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AdditionalProduct(
    @SerialName("cart_item_id")
    val cart_item_id: Int?,

    @SerialName("name")
    val name: String,

    @SerialName("sale_amount")
    val saleAmount: Double
)
