package ph.umi.online.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.relocation.BringIntoViewRequester
import androidx.compose.foundation.relocation.bringIntoViewRequester
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.onFocusEvent
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.data.requests.IncomeRequestBody
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.DropdownListWithSearch
import ph.umi.online.ui.TextFieldWithDescription
import ph.umi.online.ui.ThousandSeparatorVisualTransformation
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.next_btn_text
import umib2bonline.composeapp.generated.resources.personal_data_text_error_msg
import umib2bonline.composeapp.generated.resources.personal_income_company
import umib2bonline.composeapp.generated.resources.personal_income_industry
import umib2bonline.composeapp.generated.resources.personal_income_monthly
import umib2bonline.composeapp.generated.resources.personal_income_position
import umib2bonline.composeapp.generated.resources.personal_income_source
import umib2bonline.composeapp.generated.resources.personal_income_title
import umib2bonline.composeapp.generated.resources.select_income_from_list
import umib2bonline.composeapp.generated.resources.select_industry_from_list

private const val TextFieldsMaxLength = 128
private const val IncomeMaxLength = 8

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun JobView(
    state: QuestionaryState,
    eventHandler: (QuestionaryEvent) -> Unit
) {
    val scrollState = rememberScrollState()
    val focusManager = LocalFocusManager.current
    val isNextBtnEnabled = state.isNextFromIncomeBtnEnabled.collectAsState(false)
    val loading = state.isLoading.collectAsState()


    var visibility by remember {
        mutableStateOf(true)
    }
    var occupationId by remember {
        mutableStateOf(0)
    }
    val coroutineScope = rememberCoroutineScope()
    val bringIntoPosition = remember { BringIntoViewRequester() }
    val bringIntoIncome = remember { BringIntoViewRequester() }
    val defaultOccupation = remember { mutableStateOf<Int?>(null) }
    val defaultIndustry = remember { mutableStateOf<Int?>(null) }

    val occupationsList = state.occupations.collectAsState()
    val industriesList = state.industries.collectAsState()


    Scaffold(
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.personal_income_title),
                progress = 80,
                onBackClick = {
                    eventHandler.invoke(QuestionaryEvent.onBackClicked)
                }
            )
        },
        bottomBar = {
            UmiElevatedButton(enabled = isNextBtnEnabled.value, label = stringResource(Res.string.next_btn_text), onClick = {
                state.isLoading.value = true

                eventHandler.invoke(QuestionaryEvent.saveJobData(IncomeRequestBody(
                    occupation = state.occupationSelected.value.toString(),
                    industry = state.industrySelected.value,
                    company = state.company.value,
                    position = state.position.value,
                    income = state.income.value.toIntOrNull()
                )))
            })
        }
    ) { padding ->
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(padding)
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            DropdownListWithSearch(
                modifier = Modifier.padding(top = 16.dp, start = 16.dp, end = 16.dp),
                label = stringResource(Res.string.personal_income_source),
                errorLabel = stringResource(Res.string.select_income_from_list),
                items = occupationsList.value.map { it.name },
                defaultItemIndex = null,
                defaultItem = if (!state.occupationSelected.value.isNullOrBlank()) {
                    occupationsList.value.first { it.codename == state.occupationSelected.value }.name
                } else null,
                isSearchable = false,
                chooseCategory = { newValue ->
                    if (state.occupationSelected.value != newValue) {
                        defaultOccupation.value = null

                        val type = state.occupations.value.find { it.name == newValue }
                        if (type != null) {
                            state.occupationSelected.value = type.codename
                            occupationId = type.id
                        }
                        visibility =
                            !(occupationId == 3 || occupationId == 4 || occupationId == 7 || occupationId == 8)
                        if (visibility) {
                            state.company.value = ""
                            state.industrySelected.value = ""
                            state.position.value = ""
                        } else {
                            state.company.value = null
                            state.industrySelected.value = null
                            state.position.value = null
                            if (occupationId == 4) {
                                state.position.value = ""
                            }
                        }
                    }
                    focusManager.moveFocus(FocusDirection.Next)
                }
            )

            if (visibility) {
                DropdownListWithSearch(
                    modifier = Modifier.padding(
                        top = 16.dp,
                        start = 16.dp,
                        end = 16.dp,
                        bottom = 0.dp
                    ),
                    label = stringResource(Res.string.personal_income_industry),
                    errorLabel = stringResource(Res.string.select_industry_from_list),
                    items = industriesList.value.map { it.name },
                    defaultItemIndex = null,
                    defaultItem = if (!state.industrySelected.value.isNullOrBlank()) {
                        industriesList.value.first { it.codename == state.industrySelected.value }.name
                    } else null,
                    isSearchable = false,
                    chooseCategory = { newValue ->
                        if (state.industrySelected.value != newValue) {
                            defaultIndustry.value = null

                            val type = state.industries.value.find { it.name == newValue }
                            if (type != null) {
                                state.industrySelected.value = type.codename
                            }
                        }

                        focusManager.moveFocus(FocusDirection.Next)
                    }
                )

                TextFieldWithDescription(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 16.dp, start = 16.dp, end = 16.dp, bottom = 0.dp),
                    label = stringResource(Res.string.personal_income_company),
                    text = state.company.value.orEmpty(),
                    supportingText = stringResource(Res.string.personal_data_text_error_msg),
                    onValueChange = {
                        if (it.length <= 255) {
                            //                            state.company.value = it
                            eventHandler.invoke(QuestionaryEvent.updateIndustry(it))
                            //                            viewModel.updateCompany(it)
                            //                            viewModel.setCompanyValid(validateMaxLength(it, TextFieldsMaxLength))

                        }
                    },
                    keyboardActions = KeyboardActions(onNext = {
                        focusManager.moveFocus(FocusDirection.Next)
                    }),
                    keyboardType = KeyboardType.Text,
                    maxLength = TextFieldsMaxLength,
                    isError = !state.companyValid.value,
                    capitalization = KeyboardCapitalization.Sentences
                )
            }

            if (visibility || occupationId == 4) {
                TextFieldWithDescription(
                    modifier = Modifier
                        .fillMaxWidth()
                        .bringIntoViewRequester(bringIntoPosition)
                        .onFocusEvent {
                            if (it.isFocused || it.hasFocus) {
                                coroutineScope.launch {
                                    delay(250)
                                    bringIntoPosition.bringIntoView()
                                }
                            }
                        }
                        .padding(top = 16.dp, start = 16.dp, end = 16.dp, bottom = 0.dp),
                    label = stringResource(Res.string.personal_income_position),
                    text = state.position.value.orEmpty(),
                    supportingText = stringResource(Res.string.personal_data_text_error_msg),
                    onValueChange = {
                        if (it.length <= 255) {
                            //                            state.position.value = it
                            eventHandler.invoke(QuestionaryEvent.updateOccupation(it))

                            //                            viewModel.updatePosition(it)
                            //                            viewModel.setPositionValid(validateMaxLength(it, TextFieldsMaxLength))

                        }
                    },
                    keyboardActions = KeyboardActions(onNext = {
                        focusManager.moveFocus(FocusDirection.Next)
                    }),
                    keyboardType = KeyboardType.Text,
                    isError = !state.positionValid.value,
                    maxLength = TextFieldsMaxLength,
                    capitalization = KeyboardCapitalization.Sentences
                )
            }

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .bringIntoViewRequester(bringIntoIncome)
                    .onFocusEvent {
                        if (it.isFocused || it.hasFocus) {
                            coroutineScope.launch {
                                delay(250)
                                bringIntoIncome.bringIntoView()
                            }
                        }
                    }
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp, bottom = 0.dp),
                label = stringResource(Res.string.personal_income_monthly),
                text = state.income.value.toString(),
                onValueChange = {
                    if (it.length <= 8) {
                        state.income.value = it.toString()
                        //                        viewModel.updateIncome(it)
                        //                        viewModel.setIncomeValid(validateNumbers(it, IncomeMaxLength))
                    }
                },
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                }),
                keyboardType = KeyboardType.NumberPassword,
                maxLength = IncomeMaxLength,
                visualTransformation = ThousandSeparatorVisualTransformation()
            )
        }
    }
}