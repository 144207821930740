package ph.umi.online.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBarColors
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import ph.umi.online.theme.md_theme_light_background
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_divider_color
import ph.umi.online.theme.umi_header_color
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.back_icon

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun UmiTopBar(
    label: String,
    progress: Int = 0,
    onBackClick: () -> Unit = {},
    isBackBtnEnabled: Boolean = true,
    isDividerEnabled: Boolean = true
) {
    Column(
        modifier = Modifier
            .wrapContentHeight()
            .fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        CenterAlignedTopAppBar(
            title = {
                Text(
                    text = label,
                    style = TextStyle(
                        fontSize = 20.sp,
                        lineHeight = 24.sp,
                        fontWeight = FontWeight(500),
                        color = umi_header_color,
                        textAlign = TextAlign.Center,
                        letterSpacing = 0.15.sp,
                    )
                )
            },
            navigationIcon = {
                if (isBackBtnEnabled) {
                    IconButton(
                        onClick = { onBackClick() }
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.back_icon),
                            contentDescription = "Icon Back",
                            tint = Color.Unspecified
                        )
                    }
                }
            },
            colors = TopAppBarDefaults.centerAlignedTopAppBarColors(
                containerColor = md_theme_light_background
            )
        )


        if (progress != 0) {
            CustomLinearProgressIndicator(
                progress = progress,
                color = umi_background_blue,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)
                    .height(4.dp)
            )
        }

        if (isDividerEnabled) HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(bottom = 16.dp), color = umi_divider_color, thickness = 1.dp)
    }

}