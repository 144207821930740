package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PersonalDataRequestBody(
    @SerialName("first_name")
    val firstName: String,

    @SerialName("middle_name")
    val middleName: String?,

    @SerialName("last_name")
    val lastName: String,

    @SerialName("document_serial_number")
    val documentNumber: String,

    @SerialName("sex")
    val gender: String,

    @SerialName("birth_date")
    val birthDate: String,

    @SerialName("document_issue_date")
    val issueDate: String?,

    @SerialName("document_expiration_date")
    val expireDate: String?,

    @SerialName("email")
    val email: String,
)
