package ph.umi.online.ui

import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.input.OffsetMapping
import androidx.compose.ui.text.input.TransformedText
import androidx.compose.ui.text.input.VisualTransformation

class MaskedTransformation(private val mask: String, val numberChar: Char) : VisualTransformation {
    override fun filter(text: AnnotatedString): TransformedText {
        val maskedTextBuilder = StringBuilder()
        var maskIndex = 0
        var textIndex = 0

        while (maskIndex < mask.length && textIndex < text.length) {
            if (mask[maskIndex] == numberChar) {
                maskedTextBuilder.append(text[textIndex])
                textIndex++
            } else {
                // Check if the next character in the input text matches the current mask character
                // If not, and if the current mask character is not numberChar, append it only if some text follows
                if (textIndex < text.length) {
                    maskedTextBuilder.append(mask[maskIndex])
                } else {
                    // If we are at the end of the input text, check if the next mask character is numberChar
                    // to decide whether to append the current mask character
                    if (maskIndex + 1 < mask.length && mask[maskIndex + 1] == numberChar) {
                        break
                    }
                }
            }
            maskIndex++
        }

        val offsetMapping = object : OffsetMapping {
            override fun originalToTransformed(offset: Int): Int {
                var transformedOffset = 0
                var originalCharsCount = 0

                mask.forEachIndexed { index, char ->
                    if (originalCharsCount == offset) {
                        return@forEachIndexed
                    }
                    if (char == numberChar) {
                        originalCharsCount++
                        transformedOffset++
                    } else if (index < mask.length && originalCharsCount < text.length) {
                        transformedOffset++
                    }
                }

                return transformedOffset
            }

            override fun transformedToOriginal(offset: Int): Int {
                var originalOffset = 0
                var transformedCharsCount = 0

                mask.forEachIndexed { index, char ->
                    if (transformedCharsCount >= offset) {
                        return@forEachIndexed
                    }
                    if (char == numberChar) {
                        originalOffset++
                        transformedCharsCount++
                    } else if (index < mask.length && transformedCharsCount < offset) {
                        transformedCharsCount++
                    }
                }

                return originalOffset
            }
        }

        return TransformedText(AnnotatedString(maskedTextBuilder.toString()), offsetMapping)
    }
}