package ph.umi.online.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuBox
import androidx.compose.material3.ExposedDropdownMenuDefaults
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.PopupProperties
import ph.umi.online.theme.md_theme_light_background

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DropdownListWithSearch(
    modifier: Modifier = Modifier,
    label: String,
    errorLabel: String?,
    items: List<String>,
    defaultItem: String? = null,
    defaultItemIndex: Int?,
    chooseCategory: (String) -> Unit,
    isSearchable: Boolean = false,
    capitalization: KeyboardCapitalization = KeyboardCapitalization.None,
) {
    val focusManager = LocalFocusManager.current
    var searchText by remember { mutableStateOf("") }
    var expanded by remember { mutableStateOf(false) }
    var error by remember { mutableStateOf(false) }

    var selectedItemIndex by rememberSaveable {
        mutableStateOf(if (items.isNotEmpty()) defaultItemIndex?.coerceIn(items.indices) else null)
    }
    var selectedText by rememberSaveable {
        mutableStateOf(defaultItemIndex?.let { items.getOrNull(it) } ?: "") }

    val filteredItems = items.filter { it.contains(searchText, ignoreCase = true) }
        .sortedWith(compareByDescending { it.startsWith(searchText, ignoreCase = true) })

    // Adjust selectedItemIndex when items list changes
    DisposableEffect(items) {
        selectedItemIndex = if (items.isNotEmpty()) defaultItemIndex?.coerceIn(items.indices) else null
        selectedText = selectedItemIndex?.let { items.getOrNull(it) } ?: ""
        onDispose { }
    }

    LaunchedEffect(items) {
        if (!defaultItem.isNullOrEmpty()) {
            selectedItemIndex = items.indexOf(defaultItem)
            selectedText = defaultItem
        }
    }

    Box(modifier = modifier
        .fillMaxWidth()
        .clip(shape = RoundedCornerShape(4.dp))
    ) {
        ExposedDropdownMenuBox(
            expanded = expanded,
            onExpandedChange = { expanded = !expanded }
        ) {
            TextFieldWithDescription(
                modifier = Modifier
                    .menuAnchor()
                    .wrapContentHeight()
                    .fillMaxWidth()
                    .onFocusChanged { focusState ->
                        expanded = focusState.isFocused
                        error = selectedText.isNotEmpty() && !items.contains(selectedText)
                    }
                    .background(Color.Transparent),
                readOnly = !isSearchable,
                text = selectedText,
                label = label,
                onValueChange = { newValue ->
                    searchText = newValue
                    expanded = true
                    selectedText = newValue
                    error = false
                    chooseCategory("")
                },
                keyboardType = KeyboardType.Text,
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Next)
                }),
                maxLength = 255, // Ensure this constant is defined in your project
                trailingIcon = { ExposedDropdownMenuDefaults.TrailingIcon(expanded = expanded) },
                isError = error,
                supportingText = errorLabel,
                isShowSupportText = error,
                capitalization = capitalization
            )
            if (expanded) {
                DropdownMenu(
                    expanded = expanded,
                    onDismissRequest = { expanded = false },
                    properties = PopupProperties(focusable = false),
                    modifier = Modifier
                        .exposedDropdownSize()
                        .background(Color.White)
                ) {
                    (if (searchText.isEmpty()) items else filteredItems).forEachIndexed { index, item ->
                        DropdownMenuItemWithSearch(
                            onClick = {
                                selectedText = item
                                selectedItemIndex = items.indexOf(item) // Update the index based on the original list
                                chooseCategory(item)
                                expanded = false
                                error = false
                            },
                            selected = selectedItemIndex == index,
                            enabled = true,
                            text = item
                        )
                    }
                }
            }
        }
    }
}
@Composable
fun DropdownMenuItemWithSearch(
    text: String,
    selected: Boolean,
    enabled: Boolean,
    onClick: () -> Unit
) {
    val contentColor = when {
        !enabled -> MaterialTheme.colorScheme.surfaceTint.copy(alpha = 0f)
        selected -> MaterialTheme.colorScheme.surface.copy(alpha = 1f)
        else -> MaterialTheme.colorScheme.surfaceTint.copy(alpha = 1f)
    }

    val backgroundColor = when {
        !enabled -> MaterialTheme.colorScheme.onSurfaceVariant.copy(alpha = 0.16f)
        selected -> MaterialTheme.colorScheme.primary.copy(alpha = 1f)
        else -> md_theme_light_background.copy(alpha = 1f)
    }

    CompositionLocalProvider(LocalContentColor provides contentColor) {
        Box(modifier = Modifier
            .fillMaxWidth()
            .clickable(enabled) { onClick() }
            .background(backgroundColor)
            .padding(12.dp)
        ) {
            Text(
                text = text,
                style = TextStyle(
                    fontSize = 16.sp,
                    lineHeight = 24.sp,
                    fontWeight = FontWeight(400),
                    color = if (selected) Color.White else Color.Black,
                    letterSpacing = 0.5.sp,
                )
            )
        }
    }
}