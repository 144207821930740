package ph.umi.online.ui

import androidx.compose.foundation.Canvas
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
fun CustomLinearProgressIndicator(
    modifier: Modifier = Modifier,
    progress: Int,
    backgroundColor: Color = NeutralsGrey80,
    color: Color = Color.Blue,
    shape: Dp = 20.dp
) {
    Canvas(modifier = modifier){
        val width = size.width * progress / 100
        drawRoundRect(
            color = backgroundColor,
            cornerRadius = CornerRadius(shape.toPx()),
            size = Size(width = size.width, size.height),
            topLeft = Offset(0f,0f)
        )
        drawRoundRect(
            color = color,
            cornerRadius = CornerRadius(shape.toPx()),
            size = Size(width = width, size.height),
            topLeft = Offset(0f,0f)
        )
    }
}
