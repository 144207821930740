package ph.umi.online.finish

import kotlinx.browser.window

internal actual fun openMapsUrl(url: String?) {
    url?.let { window.open(it) }
}

//internal actual fun copyToClipboard(text: String?) {
////    text?.let { window.navigator.clipboard.writeText(it) }
//
//
//}