package ph.umi.online.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.relocation.BringIntoViewRequester
import androidx.compose.foundation.relocation.bringIntoViewRequester
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.focus.onFocusEvent
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.data.requests.PersonalDataRequestBody
import ph.umi.online.questionary.models.DocTypesPatterns
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.DatePickerField
import ph.umi.online.ui.MaskedDateTransformation
import ph.umi.online.ui.MaskedTransformation
import ph.umi.online.ui.RadioButtonGroup
import ph.umi.online.ui.TextFieldWithDescription
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.*

@OptIn(ExperimentalFoundationApi::class)
@Composable
internal fun QuestionaryView(
    state: QuestionaryState,
    eventHandler: (QuestionaryEvent) -> Unit
) {
    val scrollState = rememberScrollState()
    val focusManager = LocalFocusManager.current
    val coroutineScope = rememberCoroutineScope()
    val bringIntoIdNumber = remember { BringIntoViewRequester() }
    val bringIntoEmail = remember { BringIntoViewRequester() }
    val snackbarHostState = remember { SnackbarHostState() }
    val isNextBtnEnabled = state.isNextBtnEnabled.collectAsState(false)
    val loading = state.isLoading.collectAsState()


    val isFirstValid = state.isFirstValid.collectAsState()
    val isMiddleValid = state.isMiddleValid.collectAsState()
    val isLastValid = state.isLastValid.collectAsState()
    val isDocNumberValid = state.isSerialNumValid.collectAsState()
    val isEmailValid = state.isEmailValid.collectAsState()
    val isBirtdateValid = state.isDateOfBirthValid.collectAsState()
    val isExpiredateValid = state.isExpiredDateValid.collectAsState()
    val isIssuedateValid = state.isIssueDateValid.collectAsState()
    val genderValue = state.gender.collectAsState()

    val defaultId = listOf("Male", "Female").map { it.uppercase()}.indexOf(genderValue.value)
    var mask : String = ""
    var hint : String = ""

    if (state.docType.value.isNotEmpty()) {
        mask = DocTypesPatterns.valueOf(state.docType.value).mask
        hint = DocTypesPatterns.valueOf(state.docType.value).sample
    }


    Scaffold(
        modifier = Modifier
            .fillMaxSize()
            .windowInsetsPadding(WindowInsets.safeDrawing),
        snackbarHost = @androidx.compose.runtime.Composable {
            SnackbarHost(snackbarHostState)
        },
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.personal_data_title),
                progress = 40,
                onBackClick = {
                    eventHandler.invoke(QuestionaryEvent.onBackClicked)
                }
            )
        },
        bottomBar = {
            UmiElevatedButton(
                enabled = isNextBtnEnabled.value,
                label = stringResource(Res.string.next_btn_text),
                onClick = {
                    state.isLoading.value = true

                    eventHandler.invoke(QuestionaryEvent.savePersonalData(
                        PersonalDataRequestBody(
                            firstName = state.firstName.value.toString(),
                            lastName = state.lastName.value.toString(),
                            middleName = state.middleName.value.toString(),
                            birthDate = state.dateOfBirth.value.toString(),
                            issueDate = state.dateOfIssue.value.toString(),
                            expireDate = state.dateOfExpired.value.toString(),
                            gender = state.gender.value.toString().uppercase(),
                            email = state.email.value.toString(),
                            documentNumber = state.serialNumber.value.toString()
                        )))
            })
        }
    ) { padding ->
        Column(
            modifier = Modifier
                .padding(padding)
                .verticalScroll(scrollState)
        ) {
            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .onFocusChanged {
                        if (!it.isFocused && state.firstName.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateFirstName(state.firstName.value))
                        }
                    }
                    .padding(start = 16.dp, end = 16.dp),
                label = stringResource(Res.string.personal_data_lastname),
                text = state.firstName.value,
                supportingText = stringResource(Res.string.allowed_characters),
                onValueChange = {
                    if (it.length <= state.NameMaxLength) {
                        eventHandler.invoke(QuestionaryEvent.updateFirstName(it))
                    }
                },
                keyboardActions = KeyboardActions(
                    onNext = {
                        focusManager.moveFocus(FocusDirection.Down)
                        if (state.firstName.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateFirstName(state.firstName.value))
                        }
                    }),
                keyboardType = KeyboardType.Text,
                capitalization = KeyboardCapitalization.Words,
                maxLength = state.NameMaxLength,
                isError = !isFirstValid.value,
                isShowSupportText = !isFirstValid.value
            )

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .onFocusChanged {
                        if (!it.isFocused && state.middleName.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateMiddleName(state.middleName.value))
                        }
                    }
                    .padding(bottom = 16.dp, start = 16.dp, end = 16.dp, top = 16.dp),
                label = stringResource(Res.string.personal_data_middle_name),
                text = state.middleName.value,
                supportingText = stringResource(Res.string.allowed_characters),
                onValueChange = {
                    if (it.length <= state.NameMaxLength) {
                        eventHandler.invoke(QuestionaryEvent.updateMiddleName(it))
                    }
                },
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Down)
                    if (state.middleName.value.isNotEmpty()) {
                        eventHandler.invoke(QuestionaryEvent.updateMiddleName(state.middleName.value))
                    }
                }),
                capitalization = KeyboardCapitalization.Words,
                keyboardType = KeyboardType.Text,
                maxLength = state.NameMaxLength,
                isError = !isMiddleValid.value,
                isShowSupportText = !isMiddleValid.value
            )

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .onFocusChanged {
                        if (!it.isFocused && state.lastName.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateLastName(state.lastName.value))
                        }
                    }
                    .padding(bottom = 16.dp, start = 16.dp, end = 16.dp, top = 0.dp),
                label = stringResource(Res.string.personal_data_lastname),
                text = state.lastName.value,
                supportingText = stringResource(Res.string.allowed_characters),
                onValueChange = {
                    if (it.length <= state.NameMaxLength) {
                        eventHandler.invoke(QuestionaryEvent.updateLastName(it))
                    }
                },
                keyboardActions = KeyboardActions(
                    onNext = {
                        focusManager.clearFocus()
                        if (state.lastName.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateLastName(state.lastName.value))
                        }
                    }),
                capitalization = KeyboardCapitalization.Words,
                keyboardType = KeyboardType.Text,
                maxLength = state.NameMaxLength,
                isError = !isLastValid.value,
                isShowSupportText = !isLastValid.value
            )

            DatePickerField(
                label = stringResource(Res.string.personal_data_birthdate),
                errorText = stringResource(Res.string.personal_data_age_error_msg),
                onDateSelected = {
                    eventHandler.invoke(QuestionaryEvent.updateDateOfBirth(it))
                },
                initialDate = state.dateOfBirth.value,
                onDone = {
                    focusManager.clearFocus()
                },
                isShowError = !isBirtdateValid.value,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(bottom = 8.dp, start = 16.dp, end = 16.dp)
            )


            RadioButtonGroup(
                radioBtnList = listOf("Male", "Female"),
                defaultValue = defaultId,
                onSelectedItem = {
                    state.gender.value = it.uppercase()
                },
                modifier = Modifier.padding(
                    bottom = 0.dp,
                    start = 16.dp,
                    end = 16.dp,
                    top = 0.dp
                )
            )

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .bringIntoViewRequester(bringIntoIdNumber)
                    .onFocusEvent {
                        if (it.isFocused || it.hasFocus) {
                            coroutineScope.launch {
                                delay(250)
                                bringIntoIdNumber.bringIntoView()
                            }
                        }
                    }
                    .onFocusChanged {
                        if (!it.isFocused && state.serialNumber.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateSerialNumber(state.serialNumber.value))
                        }
                    }
                    .padding(bottom = 16.dp, start = 16.dp, end = 16.dp, top = 0.dp),
                label = stringResource(Res.string.id_number),
                text = state.serialNumber.value,
                supportingText = stringResource(
                    Res.string.personal_data_document_error_msg,
                    hint
                ),
                onValueChange = { s ->
                    if (s.length > mask.count { it == '0' }) {
                        s.take(mask.count { it == '0' })
                        eventHandler.invoke(QuestionaryEvent.updateSerialNumber(s.take(mask.count { it == '0' })))
                    }
                    if (s.length <= mask.count { it == '0' }) {
                        eventHandler.invoke(QuestionaryEvent.updateSerialNumber(s))
                    }
                },
                keyboardActions = KeyboardActions(
                    onNext = {
                        focusManager.moveFocus(FocusDirection.Down)
                        if (state.serialNumber.value.isNotEmpty()) {
                            eventHandler.invoke(QuestionaryEvent.updateSerialNumber(state.serialNumber.value))
                        }
                    }),
                maxLength = mask.count { it == '0' },
                keyboardType = KeyboardType.Text,
                isError = !isDocNumberValid.value,
                isShowSupportText = !isDocNumberValid.value,
                visualTransformation = MaskedTransformation(mask, '0'),
                placeholder = hint,
                capitalization = KeyboardCapitalization.Characters,
            )

            if (state.docType.value == "PASSPORT" || state.docType.value == "DRIVER_LICENSE" ||
                state.docType.value == "TIN" || state.docType.value == "NATIONAL_ID" || state.docType.value == "PRC"
            ) {

                DatePickerField(
                    label = stringResource(Res.string.personal_data_serial_issue),
                    errorText = stringResource(Res.string.personal_data_document_issue_error_msg),
                    onDateSelected = {
                        eventHandler.invoke(QuestionaryEvent.updateDateOfIssue(it))
                    },
                    initialDate = state.dateOfIssue.value,
                    onDone = {
                        focusManager.clearFocus()
                    },
                    isShowError = !isIssuedateValid.value,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 16.dp, start = 16.dp, end = 16.dp)
                )


                if (state.docType.value == "PASSPORT" || state.docType.value == "DRIVER_LICENSE" || state.docType.value == "PRC") {
                    DatePickerField(
                        label = stringResource(Res.string.personal_data_serial_expired),
                        errorText = stringResource(Res.string.personal_data_document_expired_error_msg),
                        onDateSelected = {
                            eventHandler.invoke(QuestionaryEvent.updateDateOfExpired(it))
                        },
                        initialDate = state.dateOfExpired.value,
                        onDone = {
                            focusManager.clearFocus()
                        },
                        isShowError = !isExpiredateValid.value,
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(bottom = 16.dp, start = 16.dp, end = 16.dp)
                    )
                }
            }

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .bringIntoViewRequester(bringIntoEmail)
                    .onFocusEvent {
                        if (it.isFocused || it.hasFocus) {
                            coroutineScope.launch {
                                delay(250)
                                bringIntoEmail.bringIntoView()
                            }
                        }
                    }
                    .onFocusChanged {
                        if (!it.isFocused && state.email.value.isNotEmpty()) {
                            //                        viewModel.setEmailValid(
                            //                            validateEmail(email)
                            //                        )
                        }
                    }
                    .padding(top = 0.dp, start = 16.dp, end = 16.dp, bottom = 16.dp),
                label = stringResource(Res.string.personal_data_email),
                text = state.email.value,
                supportingText = stringResource(Res.string.personal_data_email_error_msg),
                onValueChange = {
                    eventHandler.invoke(QuestionaryEvent.updateEmailName(it))
                },
                keyboardActions = KeyboardActions(onNext = {
                    focusManager.moveFocus(FocusDirection.Down)
                    if (state.email.value.isNotEmpty()) {
                        //                    viewModel.setEmailValid(validateEmail(email))
                    }
                }),
                keyboardType = KeyboardType.Email,
                maxLength = 256,
                isError = !isEmailValid.value,
                isShowSupportText = !isEmailValid.value
            )
        }
    }
}