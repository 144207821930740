package ph.umi.online.otp

import UmiB2BOnline.composeApp.BuildConfig
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ph.umi.online.core.BaseViewModel
import ph.umi.online.core.saveToSessionStorage
import ph.umi.online.data.ApiStatus
import ph.umi.online.data.NetworkRepository
import ph.umi.online.navigation.ScreensList
import ph.umi.online.otp.models.OtpAction
import ph.umi.online.otp.models.OtpEvent
import ph.umi.online.otp.models.OtpState

external var guidShort : String
external fun getPixelData(umidLong: String, baseUrl: String)
//external fun loadScript(src: String)
//external fun loadNoScript(content: String)

class OtpViewModel(private val networkRepository: NetworkRepository): BaseViewModel<OtpState, OtpAction, OtpEvent>(
    initialState = OtpState()
) {
    private val TimerMillis = 1000L

    init {
        clearActions()
        networkRepository.setGuid(guidShort) //omg!!!


        if (baseToken.isNotEmpty()) {
            println("set token from OtpViewModel")
            networkRepository.setToken(baseToken)
        }
        if (currenPage.isNotEmpty()) {
            viewAction = OtpAction.goNextPage
        }

        viewModelScope.launch {
            continueOrder()
            delay(500)
        }

        println("Initialize OtpViewModel")
        tickTimer()
    }

    override fun obtainEvent(viewEvent: OtpEvent) {
        when (viewEvent) {
            OtpEvent.onError -> {
                viewAction = OtpAction.ShowErrorMsg
            }
            is OtpEvent.OtpEnteredIncorrectcly -> {
                viewState.isError.value = false
                viewState.isOtpError = false
                viewState.otpValue.value = ""
            }
            is OtpEvent.onUmidReceived -> {
//                viewModelScope.launch {
//                    continueOrder()
//                }
            }

            is OtpEvent.ProtectionChecked -> {
                viewState.agreed.value = viewEvent.checked
            }

            is OtpEvent.OtpChanged -> {
                viewState.otpValue.value = viewEvent.otpValue
                viewState.attemptsOtpLeft.value = viewState.attemptsOtpLeft.value.dec()
                viewState.otpSendCount.value = viewState.otpSendCount.value.inc()

                if (viewEvent.otpValue.toString().length == 4) { //todo shitty check
                    clearActions()

                    if (viewState.otpSendCount.value < 3) {
                        viewAction = OtpAction.SendOtp
                    } else {
                        viewAction = OtpAction.MaxTryCount
                    }
                }
            }

            OtpEvent.RequestOtpAgain -> {
                viewState.otpValue.value = ""
                viewModelScope.launch {
                    sendAgain()
                }
            }
        }
    }

    private fun tickTimer() {
        viewModelScope.launch(Dispatchers.Default) {
            if (viewState.countdown.value > 0) {

                viewState.countdown.value--
                delay(TimerMillis)
                tickTimer()
            }
        }
    }

    private suspend fun sendAgain() {
        viewState.isError.value = false
        viewState.isOtpError = false

        try {
            networkRepository.sendAgain().collect { resp ->
                when (resp.status) {
                    ApiStatus.SUCCESS -> {
                        viewState.countdown.value = 60

                        println("send again" + resp.data.toString())
                        if (resp.data?.verificationCount!! >= resp.data?.verificationCountMax!!) {
                            viewState.isSendAgainActive.value = false
                        }
                        tickTimer()
                    }
                    ApiStatus.NOT_FOUND -> {
                        println("send again NOT FOUND")

                        viewAction = OtpAction.NotExistingOrder
                    }
                    ApiStatus.ERROR -> {
                        viewState.countdown.value = 0

                        println("send again Error" + resp.message.toString())
                        viewState.isSendAgainActive.value = false

                        println("send again err code " + resp.status.ordinal)

                        viewAction = if (!resp.message.isNullOrEmpty()) {
                            OtpAction.ShowSendAgainErrorMsg("The OTP request limit for this number has been exceeded")
                        } else {
                            OtpAction.ShowErrorMsg
                        }
                    }
                    ApiStatus.LOADING -> {
                    }
                }
            }
        } catch (ex: Exception) {
            println("send again EXEPTION" + ex.message.toString())

            viewAction = OtpAction.ShowErrorMsg
        }
    }
    suspend fun continueOrder() {
        println("continueAction Run")

        try {
            networkRepository.continueAction().collect() { resp ->
                when (resp.status) {
                    ApiStatus.SUCCESS -> {

                        viewState.umid = resp.data?.umid.toString()
                        viewState.phoneCode = resp.data?.phoneCode.toString()
                        viewState.phoneNumber = resp.data?.phoneNumber.toString()
                        viewState.agreementLink.value =
                            resp.data?.agreements?.first { it.type == "CLIENT_ADDL_PRODUCTS" || it.type == "CLIENT_CONTINUATION_ORDER" }?.link ?: ""
                        try {
                            viewState.dataPrivacyLink.value =
                                resp.data?.agreements?.first { it.type == "CLIENT_PRIVACY_POLICY" }?.link ?: ""
                        } catch (ex: Exception) {
                            println("dataPrivacyLink exeption")

                            viewState.dataPrivacyLink.value = ""
                        }


//                        println("umid+ ${viewState.umid}")
//                        viewAction = OtpAction.RunPixelScript(viewState.umid)
//
//                        delay(1000)
                        viewState.isLoading.value = false

                        println("continueAction SUCCESS")
                        delay(4000)
                        getPixelData(resp.data?.umid.toString(), BuildConfig.BASE_BACKEND_URL)
                        println("getPixelData Started")
                    }
                    ApiStatus.ERROR -> {
                        println("continueOrder Err+ ${resp.message.toString()}")
                        viewState.isLoading.value = false

                        viewAction = OtpAction.ShowErrorMsg
                    }
                    ApiStatus.LOADING -> {
                        viewState.isLoading.value = true
                    }
                    ApiStatus.NOT_FOUND -> {
                        viewAction = OtpAction.NotExistingOrder
                        viewState.isLoading.value = false
                        println("continueAction NOT_FOUND")

                    }
                }
        }

        } catch (ex: Exception) {
            println("continueOrder Exeption -" + ex.message.orEmpty() + ex.cause?.message.orEmpty())
            viewState.isLoading.value = false
            viewAction = OtpAction.ShowErrorMsg
        }
    }

    suspend fun verificationCheck(umid: String, otp: String, agreement: Boolean?) {
        try {
            networkRepository.authorize(
                application = umid,
                otp = otp,
                agreement = agreement
            ).collect() { response ->
                when (response.status) {
                    ApiStatus.SUCCESS -> {
                        println("VerificationCheck success " + response.data.toString())

                        if (networkRepository.getToken() != response.data?.jwtToken) response.data?.jwtToken?.let {
                            networkRepository.setToken(it)
                            saveToSessionStorage(guidShort, it)
                        }

                        if (response.data?.otpVerified == true) {
                            println("verificationCheck response otpVerified")
                            viewAction = OtpAction.goNextPage
                        }
                    }
                    ApiStatus.ERROR -> {
                        println("authorize response ERROR")
                        viewState.isError.value = true
                        viewState.isOtpError = true

                        viewAction = OtpAction.IncorrectOtpSent
                    }
                    ApiStatus.LOADING -> {
                        println("authorize response LOADING")

                    }
                    ApiStatus.NOT_FOUND -> {
                        println("verificationCheck response NOT_FOUND")
                        viewAction = OtpAction.NotExistingOrder
                    }
                }
            }

        } catch (ex: Exception) {
            println("authorize Exeption -" + ex.message.orEmpty() + ex.cause?.message.orEmpty())
            viewAction = OtpAction.ShowErrorMsg
        }
    }
}