package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OtpProperties(
    @SerialName("detail")
    val detail: String,

    @SerialName("is_otp_verified")
    val otpVerified: Boolean?,

    @SerialName("otp_check_count")
    val checkCount: Int?,

    @SerialName("otp_check_count_max")
    val checkCountMax: Int?,

    @SerialName("access_token")
    val jwtToken: String?,
)
