package ph.umi.online.ui

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.OffsetMapping
import androidx.compose.ui.text.input.TransformedText
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.stringResource
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.allowed_characters
import umib2bonline.composeapp.generated.resources.personal_contacts_phone

@Composable
fun PhoneTextField(
    label: String,
    phoneCode: String,
    text: String,
    onValueChange: (String) -> Unit,
    onDone: () -> Unit,
    isError: Boolean,
    modifier: Modifier = Modifier
) {
    var isFocused by remember {
        mutableStateOf(false)
    }

    TextFieldWithDescription(
        modifier = modifier
            .onFocusChanged { isFocused = it.isFocused }
            .fillMaxWidth()
            .padding(top = 16.dp, start = 16.dp, end = 16.dp),
        text = text,
        maxLength = 10,
        onValueChange = onValueChange,
//        singleLine = true,
//        label = {
//            Text(
//                label,
//                style = TextStyle(
//                    fontSize = 14.sp,
//                    lineHeight = 18.sp,
//                    fontWeight = FontWeight(400),
//                    letterSpacing = 0.2.sp,
//                )
//            )
//        },
        label = stringResource(Res.string.personal_contacts_phone),

//        shape = RoundedCornerShape(4.dp),
//        colors = OutlinedTextFieldDefaults.colors(
//            cursorColor = MaterialTheme.colorScheme.primary,
//            focusedBorderColor = MaterialTheme.colorScheme.primary,
//            unfocusedBorderColor = MaterialTheme.colorScheme.onSurfaceVariant,
//            focusedTextColor = MaterialTheme.colorScheme.primary,
//            focusedLabelColor = MaterialTheme.colorScheme.primary,
//            unfocusedLabelColor = MaterialTheme.colorScheme.onSurfaceVariant,
//            focusedTrailingIconColor = MaterialTheme.colorScheme.primary,
//            unfocusedTrailingIconColor = MaterialTheme.colorScheme.onSurfaceVariant,
//            errorBorderColor = Error,
//            errorLabelColor = Error,
//            errorTextColor = Error,
//            errorCursorColor = Error,
//            errorTrailingIconColor = Error,
//            errorSupportingTextColor = Error
//        ),
        visualTransformation = PrefixTransformation(phoneCode),
//        keyboardOptions = KeyboardOptions(
//            keyboardType = KeyboardType.Phone,
//            imeAction = ImeAction.Done
//        ),
        keyboardType = KeyboardType.Phone,
        keyboardActions = KeyboardActions(
            onDone = {
                onDone()
            }
        ),
        isError = isError,
        isShowSupportText = isError
    )
    if (isError) {
        Text(
            text = stringResource(Res.string.allowed_characters),
            style = MaterialTheme.typography.bodyMedium.copy(
                color = Error
            ),
            modifier = Modifier
                .padding(start = 32.dp, end = 16.dp)
        )
    }
}

class PrefixTransformation(val prefix: String) : VisualTransformation {
    override fun filter(text: AnnotatedString): TransformedText {
        return prefixFilter(text, prefix)
    }
}

fun prefixFilter(text: AnnotatedString, prefix: String): TransformedText {
    val trimmed = if (text.text.length > 10) text.text.substring(0..10) else text.text
    var out = "$prefix | "

    for (i in trimmed.indices) {
        when (i) {
            0 -> out += "("
            3 -> out += ") "
            6 -> out += "-"
        }

        out += trimmed[i]
    }

    val prefixOffset = prefix.length

    val numberOffsetTranslator = object : OffsetMapping {
        override fun originalToTransformed(offset: Int): Int {
            if (offset <= 0) return offset + prefixOffset + 3
            if (offset <= 3) return offset + prefixOffset + 4
            if (offset <= 6) return offset + prefixOffset + 6
            return offset + prefixOffset + 7
        }

        override fun transformedToOriginal(offset: Int): Int {
            if (offset <= prefixOffset + 3) return 0
            if (offset <= prefixOffset + 5) return offset - 1 - prefixOffset - 3 //3
            if (offset <= prefixOffset + 10) return offset - 3 - prefixOffset - 3 // prefixOffset - 9
            return offset - prefixOffset - 3 - 4
        }
    }

    return TransformedText(buildAnnotatedStringWithColors(AnnotatedString(out).toString(), prefix), numberOffsetTranslator)
}

fun buildAnnotatedStringWithColors(
    text: String,
    phoneCode: String
): AnnotatedString {
    val prefixColor = NeutralsGrey50
    val valueColor = Color.Black

    var count = 0
    val prefixLength = phoneCode.length + 3

    val builder = AnnotatedString.Builder()
    text.forEach {
        builder.withStyle(style = SpanStyle(color = if (count < prefixLength) prefixColor else valueColor)) {
            append("$it")
        }
        count++
    }
    return builder.toAnnotatedString()
}