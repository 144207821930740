package ph.umi.online.ui

import androidx.compose.foundation.background
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarData
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import ph.umi.online.otp.models.OtpAction
import ph.umi.online.theme.umi_background_black
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_background_gray
import ph.umi.online.theme.umi_error_snackbar_color
import ph.umi.online.theme.umi_snackbar_btn_color

@Composable
fun CustomErrSnackBar(hostState: SnackbarHostState) {
    SnackbarHost(
        hostState = hostState
    ) {
        Snackbar(
            snackbarData = it,
            containerColor = umi_background_black,
            actionColor = umi_snackbar_btn_color,
            contentColor = umi_error_snackbar_color
        )
    }
}