package ph.umi.online.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuBox
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.focus.onFocusEvent
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.PopupProperties
import org.jetbrains.compose.resources.painterResource
import ph.umi.online.questionary.data.GeoSuggestion
import ph.umi.online.theme.md_theme_light_background
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.theme.umi_background_gray
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.ic_close
import umib2bonline.composeapp.generated.resources.ic_incorrect
import umib2bonline.composeapp.generated.resources.ic_rotate_right

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DropdownGeoSuggestionNew(
    modifier: Modifier = Modifier,
    label: String,
    errorLabel: String?,
    supportingText: String?,
    value: String,
    items: List<GeoSuggestion>,
    chooseCategory: (GeoSuggestion) -> Unit,
    isLoading: Boolean,
    isError: Boolean,
    capitalization: KeyboardCapitalization = KeyboardCapitalization.None,
    maxLength: Int = Int.MAX_VALUE,
    onClear: () -> Unit = {},
    onValueChange: (String) -> Unit,
    keyboardType: KeyboardType = KeyboardType.Text,
    keyboardActions: KeyboardActions = KeyboardActions.Default
) {
    var inputText by remember { mutableStateOf(items) }
    var expanded by remember { mutableStateOf(false) }
    var hasError by remember { mutableStateOf(false) }
    var selectedItemIndex by rememberSaveable { mutableStateOf(-1) }
    var selectedText by rememberSaveable { mutableStateOf( "") }
    var showClearBtn by remember { mutableStateOf(false) }
    val focusRequester = remember { FocusRequester() }
    var haveFocus by remember { mutableStateOf(false) }

    LaunchedEffect(key1 = selectedText, key2 = expanded) {
        showClearBtn = selectedText.isNotEmpty()
    }

    LaunchedEffect(items) {
        inputText = items
        expanded = true
    }

    LaunchedEffect(key1 = true) {
        expanded = false
    }

    LaunchedEffect(key1 = value) {
        selectedText = value
    }

    LaunchedEffect(key1 = isError) {
        hasError = isError
    }


    Box(modifier = modifier
        .fillMaxWidth()
        .clip(shape = RoundedCornerShape(4.dp))) {
        ExposedDropdownMenuBox(
            modifier = Modifier.heightIn(max = 200.dp),
            expanded = expanded,
            onExpandedChange = { expanded = it }
        ) {
            TextFieldWithDescription(
                modifier = Modifier
                    .menuAnchor()
                    .wrapContentHeight()
                    .fillMaxWidth()
                    .onFocusEvent {
                        haveFocus = it.hasFocus
                    }
                    .onFocusChanged { focusState ->
                        expanded = focusState.isFocused
                    }
                    .background(Color.Transparent),
                readOnly = false,
                text = selectedText,
                label = label,
                onValueChange = { newValue ->
                    onValueChange(newValue)
                },
                keyboardType = keyboardType,
                keyboardActions = keyboardActions,
                maxLength = maxLength,
                trailingIcon = {
                    if (showClearBtn && haveFocus) {
                        IconButton(
                            modifier = Modifier.focusRequester(focusRequester),
                            onClick = {
                                println("onClear tapped")
                                selectedText = ""
                                showClearBtn = false
                                onClear()
                            }
                        ) {
                            Icon(
                                painter = painterResource(Res.drawable.ic_close),
                                tint = Color.Unspecified,
                                contentDescription = "Clear"
                            )
                        }
                    }
                },
                isError = hasError,
                supportingText = if (hasError) errorLabel else supportingText,
                isShowSupportText = hasError,
                capitalization = capitalization
            )

            if (items.isNotEmpty() && expanded) {
                DropdownMenu(
                    expanded = expanded,
                    onDismissRequest = { expanded = false },
                    properties = PopupProperties(focusable = false),
                    modifier = Modifier
                        .exposedDropdownSize()
                        .background(Color.White)
                ) {
                    if (isLoading) {
                        // Show CircularProgressIndicator as an item while loading
                        Column(modifier = Modifier
                            .fillMaxWidth()
                            .padding(vertical = 56.dp),
                            horizontalAlignment = Alignment.CenterHorizontally) {
                            CircularProgressIndicator(
                                color = MaterialTheme.colorScheme.primary,
                                strokeWidth = 5.dp
                            )
                        }
                    } else {
                        inputText.forEachIndexed { index,  geoSuggestion ->
                            DropdownGeoMenuItemWithSearch(
                                text = geoSuggestion.text,
                                onClick = {
                                    chooseCategory(geoSuggestion)
                                    expanded = false
                                    selectedItemIndex = index
                                },
                                enabled = true,
                                selected = selectedItemIndex == index,
                            )
                        }
                    }
                }
            }
        }
    }
}
@Composable
fun DropdownGeoMenuItemWithSearch(
    text: String,
    selected: Boolean,
    enabled: Boolean,
    onClick: () -> Unit
) {
    val contentColor = when {
        !enabled -> MaterialTheme.colorScheme.surfaceTint.copy(alpha = 0f)
        selected -> MaterialTheme.colorScheme.surface.copy(alpha = 1f)
        else -> MaterialTheme.colorScheme.surfaceTint.copy(alpha = 1f)
    }

    val backgroundColor = when {
        !enabled -> MaterialTheme.colorScheme.onSurfaceVariant.copy(alpha = 0.16f)
        selected -> MaterialTheme.colorScheme.primary.copy(alpha = 1f)
        else -> md_theme_light_background.copy(alpha = 1f)
    }

    CompositionLocalProvider(LocalContentColor provides contentColor) {
        Box(modifier = Modifier
            .fillMaxWidth()
            .clickable(enabled) { onClick() }
            .background(backgroundColor)
            .padding(12.dp)
        ) {
            Text(
                text = text,
                style = MaterialTheme.typography.bodyLarge.copy(
                    color = if (selected) Color.White else Color.Black
                )
            )
        }
    }
}