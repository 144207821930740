package ph.umi.online.data.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerificationCheckBody(
    @SerialName("application")
    val application: String,

    @SerialName("otp")
    val otp: String,

    @SerialName("agreed_to_additional_products")
    val agreement: Boolean?
)
