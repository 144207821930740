package ph.umi.online.questionary.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DomainDocumentType(
    @SerialName("id")
    val id: Int,

    @SerialName("name")
    val name: String,

    @SerialName("codename")
    val codename: String,

    @SerialName("list_position")
    val listPosition: Int,

    @SerialName("is_recommended")
    val isRecommended: Boolean,
)
