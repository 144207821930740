package ph.umi.online.questionary

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.SnackbarResult
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.company.app.navigation.LocalNavHost
import ph.umi.online.navigation.ScreensList
import org.koin.compose.getKoin
import ph.umi.online.questionary.models.QuestionaryAction
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.CustomErrSnackBar
import ph.umi.online.views.AddressView
import ph.umi.online.views.ContactsView
import ph.umi.online.views.JobView
import ph.umi.online.views.QuestionaryView
import ph.umi.online.views.SelectDocTypeView

@Composable
fun QuestionaryScreen(
    viewModel: QuestionaryViewModel = getKoin().get()
) {
    val state by viewModel.viewStates().collectAsState()
    val action by viewModel.viewActions().collectAsState(null)
    val navHost = LocalNavHost.current
    val snackbarHostState = remember { SnackbarHostState() }
    val scope = rememberCoroutineScope()

    LaunchedEffect(Unit) {
        state.isLoading.value = false
        viewModel.isContentLoading.value = false
    }

    Scaffold(
        snackbarHost = @androidx.compose.runtime.Composable {
            CustomErrSnackBar(snackbarHostState)
        }
    ) {
        if (viewModel.isContentLoading.value) {
            Column(modifier = Modifier.fillMaxSize().padding(it), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
                CircularProgressIndicator(
                    color = umi_background_blue,
                    strokeWidth = 5.dp
                )
            }
        } else {
            QuestionaryView(state) { questionaryEvent ->
                viewModel.obtainEvent(questionaryEvent)
            }
        }
    }

    when(action) {
        QuestionaryAction.sendAddressData -> {}
        QuestionaryAction.sendContactsData -> {}
        QuestionaryAction.sendJobData -> {}
        QuestionaryAction.sendPersonData -> {}
        null -> {}

        QuestionaryAction.openFinishScreen -> {
            viewModel.clearActions()
            navHost.navigate(ScreensList.Finish.title)
        }

        QuestionaryAction.openPreviousPage -> {
            viewModel.clearActions()
            viewModel.isContentLoading.value = false

            navHost.navigate(ScreensList.DocumentTypeScreen.title)
        }

        QuestionaryAction.ShowErrorMsg -> {
            state.isError.value = true
            state.isLoading.value = false
            viewModel.isContentLoading.value = false


            scope.launch {
                val snackResult = snackbarHostState.showSnackbar(
                    "Oops, something went wrong. Please refresh the page and try again",
                    "Refresh",
                )
                when (snackResult) {
                    SnackbarResult.Dismissed -> {}
                    SnackbarResult.ActionPerformed -> {
                        navHost.currentBackStackEntry?.let { it.destination.route?.let { name ->
                            navHost.navigate(name)
                        } }
                    }
                }
            }
        }

        QuestionaryAction.openNextPage -> {
            viewModel.clearActions()

            navHost.navigate(ScreensList.AddressScreen.title)
            viewModel.isContentLoading.value = false
        }
    }
}
