package ph.umi.online.views

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import ph.umi.online.data.requests.ContactsReuestBody
import ph.umi.online.questionary.models.QuestionaryEvent
import ph.umi.online.questionary.models.QuestionaryState
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.DropdownListWithSearch
import ph.umi.online.ui.PhoneTextField
import ph.umi.online.ui.TextFieldWithDescription
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.allowed_characters
import umib2bonline.composeapp.generated.resources.next_btn_text
import umib2bonline.composeapp.generated.resources.personal_contacts_name
import umib2bonline.composeapp.generated.resources.personal_contacts_phone
import umib2bonline.composeapp.generated.resources.personal_contacts_relation
import umib2bonline.composeapp.generated.resources.personal_contacts_title
import umib2bonline.composeapp.generated.resources.select_income_from_list

private const val PHONE_MAX_LENGTH = 10
private const val CONTACT_MAX_LENGTH = 255


@Composable
fun ContactsView(
    state: QuestionaryState,
    eventHandler: (QuestionaryEvent) -> Unit
) {
    val scrollState = rememberScrollState()
    val focusManager = LocalFocusManager.current

    val relationshipsList = state.relationships.collectAsState()
    val contactPhoneValid = state.phoneValid.collectAsState()
    val contactNameValid = state.contactValid.collectAsState()
    val isNextBtnEnabled = state.isNextFromContactsBtnEnabled.collectAsState(false)
    val loading = state.isLoading.collectAsState()


    Scaffold(
        topBar = {
            UmiTopBar(
                label = stringResource(Res.string.personal_contacts_title),
                progress = 100,
                onBackClick = {
                    eventHandler.invoke(QuestionaryEvent.onBackClicked)
                }
            )
        },
        bottomBar = {
            UmiElevatedButton(enabled = isNextBtnEnabled.value, label = stringResource(Res.string.next_btn_text), onClick = {
                state.isLoading.value = true

                eventHandler.invoke(QuestionaryEvent.saveContactsData(ContactsReuestBody(
                    fullName = state.contactName.value.toString(),
                    relation = state.relation.value.toString(),
                    phone = state.phoneCode.plus(state.contactPhone.value)
                )))
            })
        }
    ) { padding ->
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(padding),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {

            TextFieldWithDescription(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 16.dp, start = 16.dp, end = 16.dp, bottom = 0.dp),
                label = stringResource(Res.string.personal_contacts_name),
                text = state.contactName.value,
                supportingText = stringResource(Res.string.allowed_characters),
                onValueChange = {
                    if (it.length <= CONTACT_MAX_LENGTH) {
                        eventHandler.invoke(QuestionaryEvent.updateContactName(it))
                    }
                },
                keyboardType = KeyboardType.Text,
                isError = !contactNameValid.value,
                maxLength = CONTACT_MAX_LENGTH,
                isShowSupportText = !contactNameValid.value,
                capitalization = KeyboardCapitalization.Words,
            )

            PhoneTextField(
                modifier = Modifier.onFocusChanged {
                    if (!it.isFocused && state.contactPhone.value.isNotEmpty())
                        eventHandler.invoke(QuestionaryEvent.validateComtactPhone(state.contactPhone.value))
                },
                label = stringResource(Res.string.personal_contacts_phone),
                text = state.contactPhone.value,
                phoneCode = state.phoneCode,
                onValueChange = { phone ->
                    if (phone.length <= PHONE_MAX_LENGTH) {
                        eventHandler.invoke(QuestionaryEvent.updateComtactPhone(phone))
                    }
                    if (phone.length == PHONE_MAX_LENGTH) {
                        eventHandler.invoke(QuestionaryEvent.validateComtactPhone(phone))
                    }
                },
                onDone = {
                    //                                if (contactPhone.isNotEmpty())
                    //                                    viewModel.checkPhoneNumberValid(contactPhone)
                    focusManager.moveFocus(FocusDirection.Next)
                },
                isError = !contactPhoneValid.value,
            )

            DropdownListWithSearch(
                modifier = Modifier.padding(top = 16.dp, start = 16.dp, end = 16.dp),
                label = stringResource(Res.string.personal_contacts_relation),
                errorLabel = stringResource(Res.string.select_income_from_list),
                items = relationshipsList.value.map { it.name },
                defaultItemIndex = null,
                defaultItem = if (!state.relation.value.isNullOrBlank()) {
                    relationshipsList.value.first { it.codename == state.relation.value }.name
                } else null,
                isSearchable = false,
                chooseCategory = { newValue ->
                    if (newValue.isNotEmpty()) {
                        state.relation.value =
                            relationshipsList.value.find { it.name == newValue }?.codename.orEmpty()
                    }
                    focusManager.moveFocus(FocusDirection.Down)
                }
            )


            //            LazyColumn(
            //                modifier = Modifier
            //                    .fillMaxWidth()
            //                    .fillMaxHeight()
            //                    .padding(16.dp),
            //                verticalArrangement = Arrangement.SpaceBetween,
            //                content = {
            //                    item {
            //
            //                    }
            //                }
            //            )
        }
    }
}