package ph.umi.online.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.selection.selectable
import androidx.compose.foundation.selection.selectableGroup
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Composable
fun RadioButtonGroup(
    radioBtnList: List<String>,
    defaultValue: Int,
    onSelectedItem: (String) -> Unit,
    modifier: Modifier = Modifier,
) {

    val selectedValue =
        if (radioBtnList.size >= defaultValue) radioBtnList[defaultValue] else radioBtnList.first()

    val (selectedOption, onOptionSelected) = remember { mutableStateOf(selectedValue) }

    Box(modifier = modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.selectableGroup(),
            horizontalArrangement = Arrangement.spacedBy(54.dp)
        ) {
            radioBtnList.forEach { name ->
                Row(modifier = Modifier
                    .clip(CircleShape)
                    .selectable(
                        selected = (name == selectedValue),
                        onClick = {
                            onSelectedItem(name)
                            onOptionSelected(name)
                        }
                    ),
                    verticalAlignment = Alignment.CenterVertically) {
                    RadioButton(
                        selected = (name == selectedValue),
                        onClick = {
                            onSelectedItem(name)
                            onOptionSelected(name)
                        },
                        modifier = Modifier.padding(start = 8.dp)
                    )
                    Text(
                        text = name,
                        style = TextStyle(
                            fontSize = 16.sp,
                            lineHeight = 24.sp,
                            fontWeight = FontWeight(400),
                            color = Color.Black,
                            letterSpacing = 0.5.sp,
                        ),
                        modifier = Modifier.padding(end = 16.dp)
                    )
                }
            }
        }
    }
}