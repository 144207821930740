package ph.umi.online.order.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OrderCart(
    @SerialName("items")
    val items: List<OrderCartItem>? = null,
    @SerialName("total_amount")
    val totalAmount: Double
)

@Serializable
data class OrderCartItem (
    @SerialName("goods_type")
    val goodsType: String,

    @SerialName("name")
    val name: String,

    @SerialName("price")
    val price: Double,

    @SerialName("quantity")
    val quantity: Int,

    @SerialName("id")
    val id: Int,
)