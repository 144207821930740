package ph.umi.online.otp

import UmiB2BOnline.composeApp.BuildConfig
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.SnackbarResult
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.company.app.navigation.LocalNavHost
import ph.umi.online.navigation.ScreensList
import org.koin.compose.getKoin
import ph.umi.online.otp.models.OtpAction
import ph.umi.online.otp.models.OtpEvent
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.CustomErrSnackBar
import ph.umi.online.umidVar
import ph.umi.online.views.OtpView


//external var ipAddress : String
//external var seonPayload : String
//external var juicySession : String
//external var umidVar : String
//external var umidVar : String



@Composable
fun OtpScreen(
    viewModel: OtpViewModel = getKoin().get()
) {
    val state by viewModel.viewStates().collectAsState()
    val action by viewModel.viewActions().collectAsState(null)
    val navHost = LocalNavHost.current
    val coroutineScope = rememberCoroutineScope()
    val snackbarHostState = remember { SnackbarHostState() }
    val loading = state.isLoading.collectAsState()

    LaunchedEffect(Unit) {
        viewModel.clearActions()
        delay(1500)
        state.isLoading.value = false
//        viewModel.obtainEvent(OtpEvent.onUmidReceived(""))
    }

    if (loading.value) {
        Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
            CircularProgressIndicator(
                color = umi_background_blue,
                strokeWidth = 5.dp
            )
        }
    } else {
        Scaffold(
            snackbarHost = @androidx.compose.runtime.Composable {
                CustomErrSnackBar(snackbarHostState)
            },
        ) {
            OtpView(state) { event ->
                viewModel.obtainEvent(event)
            }
        }
    }


    when (action) {
        is OtpAction.RunPixelScript -> {
            println("OtpAction.RunPixelScript with umid + ${(action as OtpAction.RunPixelScript).umidLong}")
//            getPixelData((action as OtpAction.RunPixelScript).umidLong, BuildConfig.BASE_BACKEND_URL, 6)
            viewModel.clearActions()
        }
        OtpAction.SendOtp -> {
            println("OtpAction.SendOtp")
            viewModel.clearActions()

            state.isOtpLoading.value = true


            coroutineScope.launch {
                delay(2500)
                viewModel.verificationCheck(
                    umid = state.umid,
                    otp = state.otpValue.value,
                    agreement = if (state.agreementLink.value.isNotEmpty()) state.agreed.value else null
                )
            }
        }
        null -> {}
        OtpAction.goNextPage -> {
            viewModel.clearActions()
            println("OtpAction.goNextPage")
            state.isOtpLoading.value = false
            navHost.navigate(ScreensList.OrderInfo.title)
        }

        OtpAction.IncorrectOtpSent -> {
            state.errorMessage.value = "${state.attemptsOtpLeft.value} attempts remain"
            state.isOtpLoading.value = false
            state.success.value = false
            println("OtpAction.IncorrectOtpSent")
            coroutineScope.launch {
                delay(2500)
                state.isError.value = false
                state.isOtpError = false
                state.otpValue.value = ""
            }

        }

        OtpAction.MaxTryCount -> {
            println("OtpAction.MaxTryCount")

            navHost.navigate(ScreensList.OtpError.title)
        }

        is OtpAction.SetJwtToken -> {
            println("OtpAction.SetJwtToken")
        }

        OtpAction.ShowErrorMsg -> {
            println("OtpAction.ShowErrorMsg")
            coroutineScope.launch {
                val snackResult = snackbarHostState.showSnackbar(
                    "Oops, something went wrong. Please refresh the page and try again",
                    "Refresh",
                )
                when (snackResult) {
                    SnackbarResult.Dismissed -> {}
                    SnackbarResult.ActionPerformed -> {
                        navHost.currentBackStackEntry?.let { it.destination.route?.let { name ->
                            navHost.navigate(name)
                        } }
                    }
                }
            }
            viewModel.clearActions()
//            state.isError.value = true
        }

        OtpAction.NotExistingOrder -> {
            viewModel.clearActions()
            navHost.navigate(ScreensList.OrderError.title)
        }

        is OtpAction.ShowSendAgainErrorMsg -> {
            println("OtpAction.ShowSendAgainErrorMsg")
            coroutineScope.launch {
                snackbarHostState.showSnackbar(
                    (action as OtpAction.ShowSendAgainErrorMsg).data
                )
            }
        }
    }
}