package ph.umi.online

import androidx.compose.runtime.*
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import ph.umi.online.theme.AppTheme
import org.company.app.navigation.LocalNavHost
import ph.umi.online.navigation.ScreensList
import org.koin.core.context.startKoin
import ph.umi.online.di.appModule
import ph.umi.online.finish.FinishScreen
import ph.umi.online.order.OrdersScreen
import ph.umi.online.otp.OtpScreen
import ph.umi.online.questionary.AddressScreen
import ph.umi.online.questionary.ContactsScreen
import ph.umi.online.questionary.IncomeScreen
import ph.umi.online.questionary.QuestionaryScreen
import ph.umi.online.questionary.SelectDocTypeScreen
import ph.umi.online.views.OrderNotFoundView
import ph.umi.online.views.OtpIncorrectView

external var umidVar : String

//external fun getPixelData() : JsAny?


@Composable
internal fun App() = AppTheme {
    startKoin {
        modules(appModule())
    }
    UmiB2bOnline()
}

@Composable
internal fun UmiB2bOnline(
    navHostController: NavHostController = rememberNavController()
) {
    val backStack by navHostController.currentBackStackEntryAsState()
    val currentScreen = backStack?.destination?.route ?: ScreensList.OrderInfo.title

    CompositionLocalProvider(
        LocalNavHost provides navHostController
    ) {
        NavHost(
            navController = navHostController,
            startDestination = currentScreen
        ) {
            composable(route = ScreensList.OtpScreen.title) {
                OtpScreen()
            }
            composable(route = ScreensList.OrderInfo.title) {
                OrdersScreen()
            }
            composable(route = ScreensList.DocumentTypeScreen.title) {
                SelectDocTypeScreen()
            }
            composable(route = ScreensList.Questionary.title) {
                QuestionaryScreen()
            }
            composable(route = ScreensList.AddressScreen.title) {
                AddressScreen()
            }
            composable(route = ScreensList.IncomeScreen.title) {
                IncomeScreen()
            }
            composable(route = ScreensList.ContactsScreen.title) {
                ContactsScreen()
            }
            composable(route = ScreensList.Finish.title) {
                FinishScreen()
            }
            composable(route = ScreensList.OtpError.title) {
                OtpIncorrectView()
            }
            composable(route = ScreensList.OrderError.title) {
                OrderNotFoundView()
            }
        }
    }
}

