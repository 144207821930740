package ph.umi.online.questionary.models

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import ph.umi.online.data.responses.CitiesResponse
import ph.umi.online.data.responses.PersonalExtraInfo
import ph.umi.online.data.responses.ProvinceResponse
import ph.umi.online.questionary.data.DomainDocumentType
import ph.umi.online.questionary.data.GeoSuggestion
import ph.umi.online.utils.validateAge
import ph.umi.online.utils.validateContact
import ph.umi.online.utils.validateDocExpired
import ph.umi.online.utils.validateDocIssue
import ph.umi.online.utils.validateDocument
import ph.umi.online.utils.validateEmail
import ph.umi.online.utils.validateNames
import ph.umi.online.utils.validatePhoneNumber
import ph.umi.online.utils.validateTemporaryIncome


data class QuestionaryState (
    val NameMaxLength: Int = 32,
    val ZipMaxLength: Int = 4,
    val StreetMaxLength: Int = 255,
    val BarangayMaxLength: Int = 128,
    val HouseMaxLength: Int = 255,

    val isLoadingDictionary: Boolean = false,

    var isLoading: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var isError: MutableState<Boolean> = mutableStateOf(false),

    var defaultDocumentIndex: MutableStateFlow<Int> = MutableStateFlow(1),


    var documentTypes: MutableStateFlow<List<DomainDocumentType>> = MutableStateFlow(mutableListOf()),
    var provinces: MutableStateFlow<List<ProvinceResponse>> = MutableStateFlow(mutableListOf()),
    var cities: MutableStateFlow<List<CitiesResponse>> = MutableStateFlow(mutableListOf()),
    var occupations: MutableStateFlow<List<PersonalExtraInfo>> = MutableStateFlow(mutableListOf()),
    var industries: MutableStateFlow<List<PersonalExtraInfo>> = MutableStateFlow(mutableListOf()),
    var relationships: MutableStateFlow<List<PersonalExtraInfo>> = MutableStateFlow(mutableListOf()),
    val barangays: MutableStateFlow<List<GeoSuggestion>> = MutableStateFlow(mutableListOf()),
    val zips: MutableStateFlow<List<GeoSuggestion>> = MutableStateFlow(mutableListOf()),


    var firstName: MutableStateFlow<String> = MutableStateFlow(""),
    var isFirstValid: MutableStateFlow<Boolean> = MutableStateFlow(true),

    val middleName: MutableStateFlow<String> = MutableStateFlow(""),
    var isMiddleValid: MutableStateFlow<Boolean> = MutableStateFlow(true),

    var lastName: MutableStateFlow<String> = MutableStateFlow(""),
    var isLastValid: MutableStateFlow<Boolean> = MutableStateFlow(true),

    var serialNumber: MutableStateFlow<String> = MutableStateFlow(""),
    var isSerialNumValid: MutableStateFlow<Boolean> = MutableStateFlow(true),

    var dateOfBirth: MutableStateFlow<String> = MutableStateFlow(""),
    var isDateOfBirthValid: MutableStateFlow<Boolean> = MutableStateFlow(false),

    var dateOfIssue: MutableStateFlow<String> = MutableStateFlow(""),
    var isIssueDateValid: MutableStateFlow<Boolean> = MutableStateFlow(false),

    var dateOfExpired: MutableStateFlow<String> = MutableStateFlow(""),
    var isExpiredDateValid: MutableStateFlow<Boolean> = MutableStateFlow(false),

    var gender: MutableStateFlow<String> = MutableStateFlow("MALE"),
    var docType: MutableStateFlow<String> = MutableStateFlow(""),

    var email: MutableStateFlow<String> = MutableStateFlow(""),
    var isEmailValid: MutableStateFlow<Boolean> = MutableStateFlow(true),

//    documentTypes staff

    val datemask : String = DateTimePatterns.valueOf("MMDD").mask,





    var streetValid:  MutableStateFlow<Boolean> = MutableStateFlow(true),
    var street: MutableStateFlow<String> = MutableStateFlow(""),
    var barangay: MutableStateFlow<String> = MutableStateFlow(""),
    var barangayValid:  MutableStateFlow<Boolean> = MutableStateFlow(true),
    var zip: MutableStateFlow<String> = MutableStateFlow(""),
    var zipValid:  MutableStateFlow<Boolean> = MutableStateFlow(true),
    var house: MutableStateFlow<String> = MutableStateFlow(""),
    var houseValid: Boolean = true,
    var selectedProvince: MutableStateFlow<String> = MutableStateFlow(""),
    var selectedCity: MutableStateFlow<String> = MutableStateFlow(""),


    var occupationSelected: MutableStateFlow<String> = MutableStateFlow(""),
    var industrySelected: MutableStateFlow<String?> = MutableStateFlow(""),
    var company: MutableStateFlow<String?> = MutableStateFlow(""),
    var companyValid: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var income: MutableStateFlow<String> = MutableStateFlow(""),
    var position: MutableStateFlow<String?> = MutableStateFlow(""),
    var positionValid: MutableStateFlow<Boolean> = MutableStateFlow(true),


    var relation: MutableStateFlow<String> = MutableStateFlow(""),
    var contactName: MutableStateFlow<String> = MutableStateFlow(""),
    var contactValid: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var contactPhone: MutableStateFlow<String> = MutableStateFlow(""),
    var phoneValid: MutableStateFlow<Boolean> = MutableStateFlow(true),
    var phoneCode: String = "+63",



    val isInputCorrect: Flow<Boolean> = combine(
        firstName,
        middleName,
        lastName,
        email,
        serialNumber
    ) { it ->
        val isNameCorrect = validateNames(it.component1())
        val isMiddleCorrect = it.component2().isEmpty() || validateNames(it.component2())
        val isLastCorrect = validateNames(it.component3())
        val isMailCorrect = validateEmail(it.component4())
        val isNumberCorrect = validateDocument(it.component5(), docType.value)
        return@combine isNameCorrect and isMiddleCorrect and isLastCorrect and isMailCorrect and isNumberCorrect
    },

    val isDatesCorrect: Flow<Boolean> = combine(
        isDateOfBirthValid,
        isIssueDateValid,
        isExpiredDateValid,
    ) { it ->
        val isDateOfBirthCorrect = !it.component1()
        println("isDateOfBirthCorrect".plus(isDateOfBirthCorrect))

        val isDateOfIssueCorrect =
            if ((docType.value == "PASSPORT" || docType.value == "DRIVER_LICENSE" || docType.value == "TIN" || docType.value == "NATIONAL_ID" || docType.value == "PRC"))
                !it.component2()
            else
                true
        println("isDateOfIssueCorrect".plus(isDateOfIssueCorrect))

        val isDateOfExpiredCorrect =
            if (docType.value == "PASSPORT" || docType.value == "DRIVER_LICENSE" || docType.value == "PRC")
                !it.component3()
            else
                true
        println("isDateOfExpiredCorrect".plus(isDateOfExpiredCorrect))

        return@combine isDateOfBirthCorrect and isDateOfIssueCorrect and isDateOfExpiredCorrect
    },

    val isNextBtnEnabled: Flow<Boolean> = combine(
        isInputCorrect,
        isDatesCorrect
    ) { it ->
        val isInputCorrect = it.component1()
        val isDatesCorrect = it.component2()
        println(isInputCorrect)
        println(isDatesCorrect)

        return@combine isInputCorrect and isDatesCorrect
    },

    val isNextFromAddressBtnEnabled: Flow<Boolean> = combine(
        selectedCity,
        selectedProvince,
        zipValid,
        barangay,
        street,
        house
    ) { inputs ->
        val city = inputs[0] as String
        val province = inputs[1] as String
        val zipValid = inputs[2] as Boolean
        val barValid = inputs[3] as String
        val streetValid = inputs[4] as String
        val houseValid = inputs[5] as String

        city.isNotEmpty() && province.isNotEmpty() && zipValid && barValid.isNotEmpty() && streetValid.isNotEmpty() && houseValid.isNotEmpty()
    },

    val isNextFromIncomeBtnEnabled: Flow<Boolean> = combine(
        industrySelected, company, position, income
    ) { it ->
        return@combine validateTemporaryIncome(it.component1()) and
                validateTemporaryIncome(it.component2()) and
                validateTemporaryIncome(it.component3()) and
                !it.component4().isNullOrEmpty()
    },

    val isNextFromContactsBtnEnabled: Flow<Boolean> = combine(
        contactName, contactPhone, relation
    ) { it ->
        val isCorrectName = validateContact(it.component1())
        val isCorrectPhone = validatePhoneNumber(
            it.component2(),
            phoneCode
        )
        val isRelateCorrect = it.component3().toString().isNotEmpty()
        return@combine isCorrectName and isCorrectPhone and isRelateCorrect
    }
)

enum class DocTypesPatterns(val pattern: String, val mask: String, val sample: String) {
    UMID("^\\d{4}-\\d{7}-\\d\$", "0000-0000000-0", "0000-0000000-0"),
    SSS("^\\d{2}-\\d{7}-\\d\$", "00-0000000-0", "00-0000000-0"),
    DRIVER_LICENSE("^[A-Z]\\d{2}-\\d{2}-\\d{6}\$", "000-00-000000", "A00-00-000000"),
    NATIONAL_ID("^\\d{4}-\\d{4}-\\d{4}-\\d{4}\$", "0000-0000-0000-0000", "0000-0000-0000-0000"),
    PRC("^\\d{7}\$", "0000000", "0000000"),
    PASSPORT("^[A-Z]\\d{7}[A-Z]\$", "000000000", "A0000000A"),
    TIN("^\\d{3}-\\d{3}-\\d{3}-\\d{3}\$", "000-000-000-000", "000-000-000-000")
}

enum class DateTimePatterns(val pattern: String, val mask: String, val sample: String) {
    MMDD("^\\d{2}-\\d{2}-\\d{4}\$", "MM/DD/YYYY", "MM/DD/YYYY"),
    YYMM("^\\d{4}-\\d{2}-\\d{2}\$", "YYYY/MM/DD", "YYYY/MM/DD")
}