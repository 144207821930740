package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SendAgainResponse(
    @SerialName("umid_long")
    val umid: String,

    @SerialName("phone_number_verification_count")
    val verificationCount: Int,

    @SerialName("phone_number_verification_count_max")
    val verificationCountMax: Int,
)
