package ph.umi.online.navigation

enum class ScreensList (val title: String) {
    OtpScreen("otp"),
    OrderInfo("orderinfo"),
    DocumentTypeScreen("doctype"),
    Questionary("questionary"),
    AddressScreen("address"),
    IncomeScreen("income"),
    ContactsScreen("contacts"),
    Finish("finish"),
    OtpError("otperror"),
    OrderError("ordererror")
}