import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.ComposeViewport
import ph.umi.online.App
import org.jetbrains.skiko.wasm.onWasmReady
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.url.URL

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        val body = document.body ?: return@onWasmReady

        ComposeViewport(body) {
            App()
        }
    }
}
