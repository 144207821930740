package ph.umi.online.data.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PersonalExtraInfo(
    @SerialName("id")
    val id: Int,

    @SerialName("name")
    val name: String,

    @SerialName("codename")
    val codename: String,
)
