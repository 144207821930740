@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package umib2bonline.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val allowed_characters: StringResource by 
      lazy { init_allowed_characters() }

  public val attempts_remain: StringResource by 
      lazy { init_attempts_remain() }

  public val close_btn_text: StringResource by 
      lazy { init_close_btn_text() }

  public val confirm_btn_text: StringResource by 
      lazy { init_confirm_btn_text() }

  public val congrats: StringResource by 
      lazy { init_congrats() }

  public val copy_btn: StringResource by 
      lazy { init_copy_btn() }

  public val data_privacy_header: StringResource by 
      lazy { init_data_privacy_header() }

  public val data_privacy_msg: StringResource by 
      lazy { init_data_privacy_msg() }

  public val document: StringResource by 
      lazy { init_document() }

  public val document_header: StringResource by 
      lazy { init_document_header() }

  public val follow_instructions: StringResource by 
      lazy { init_follow_instructions() }

  public val id_number: StringResource by 
      lazy { init_id_number() }

  public val input_4_numbers: StringResource by 
      lazy { init_input_4_numbers() }

  public val map_btn: StringResource by 
      lazy { init_map_btn() }

  public val next_btn_text: StringResource by 
      lazy { init_next_btn_text() }

  public val order_description_msg: StringResource by 
      lazy { init_order_description_msg() }

  public val order_error_description: StringResource by 
      lazy { init_order_error_description() }

  public val order_error_msg: StringResource by 
      lazy { init_order_error_msg() }

  public val order_greetings_msg: StringResource by 
      lazy { init_order_greetings_msg() }

  public val order_header: StringResource by 
      lazy { init_order_header() }

  public val otp_description_msg: StringResource by 
      lazy { init_otp_description_msg() }

  public val otp_error_description: StringResource by 
      lazy { init_otp_error_description() }

  public val otp_error_msg: StringResource by 
      lazy { init_otp_error_msg() }

  public val otp_header: StringResource by 
      lazy { init_otp_header() }

  public val personal_address_barangay: StringResource by 
      lazy { init_personal_address_barangay() }

  public val personal_address_city: StringResource by 
      lazy { init_personal_address_city() }

  public val personal_address_house: StringResource by 
      lazy { init_personal_address_house() }

  public val personal_address_province: StringResource by 
      lazy { init_personal_address_province() }

  public val personal_address_street: StringResource by 
      lazy { init_personal_address_street() }

  public val personal_address_title: StringResource by 
      lazy { init_personal_address_title() }

  public val personal_address_zipcode: StringResource by 
      lazy { init_personal_address_zipcode() }

  public val personal_contacts_name: StringResource by 
      lazy { init_personal_contacts_name() }

  public val personal_contacts_phone: StringResource by 
      lazy { init_personal_contacts_phone() }

  public val personal_contacts_phone_validation: StringResource by 
      lazy { init_personal_contacts_phone_validation() }

  public val personal_contacts_relation: StringResource by 
      lazy { init_personal_contacts_relation() }

  public val personal_contacts_title: StringResource by 
      lazy { init_personal_contacts_title() }

  public val personal_data_age_error_msg: StringResource by 
      lazy { init_personal_data_age_error_msg() }

  public val personal_data_birthdate: StringResource by 
      lazy { init_personal_data_birthdate() }

  public val personal_data_document_error_msg: StringResource by 
      lazy { init_personal_data_document_error_msg() }

  public val personal_data_document_expired_error_msg: StringResource by 
      lazy { init_personal_data_document_expired_error_msg() }

  public val personal_data_document_issue_error_msg: StringResource by 
      lazy { init_personal_data_document_issue_error_msg() }

  public val personal_data_email: StringResource by 
      lazy { init_personal_data_email() }

  public val personal_data_email_error_msg: StringResource by 
      lazy { init_personal_data_email_error_msg() }

  public val personal_data_firstname: StringResource by 
      lazy { init_personal_data_firstname() }

  public val personal_data_lastname: StringResource by 
      lazy { init_personal_data_lastname() }

  public val personal_data_middle_name: StringResource by 
      lazy { init_personal_data_middle_name() }

  public val personal_data_serial: StringResource by 
      lazy { init_personal_data_serial() }

  public val personal_data_serial_expired: StringResource by 
      lazy { init_personal_data_serial_expired() }

  public val personal_data_serial_issue: StringResource by 
      lazy { init_personal_data_serial_issue() }

  public val personal_data_text_error_msg: StringResource by 
      lazy { init_personal_data_text_error_msg() }

  public val personal_data_title: StringResource by 
      lazy { init_personal_data_title() }

  public val personal_income_company: StringResource by 
      lazy { init_personal_income_company() }

  public val personal_income_industry: StringResource by 
      lazy { init_personal_income_industry() }

  public val personal_income_monthly: StringResource by 
      lazy { init_personal_income_monthly() }

  public val personal_income_position: StringResource by 
      lazy { init_personal_income_position() }

  public val personal_income_source: StringResource by 
      lazy { init_personal_income_source() }

  public val personal_income_title: StringResource by 
      lazy { init_personal_income_title() }

  public val pre_approve_msg: StringResource by 
      lazy { init_pre_approve_msg() }

  public val price_msg: StringResource by 
      lazy { init_price_msg() }

  public val recommended: StringResource by 
      lazy { init_recommended() }

  public val select_barangay: StringResource by 
      lazy { init_select_barangay() }

  public val select_income_from_list: StringResource by 
      lazy { init_select_income_from_list() }

  public val select_industry_from_list: StringResource by 
      lazy { init_select_industry_from_list() }

  public val select_street: StringResource by 
      lazy { init_select_street() }

  public val select_type_of_document: StringResource by 
      lazy { init_select_type_of_document() }

  public val select_zip: StringResource by 
      lazy { init_select_zip() }

  public val send_again: StringResource by 
      lazy { init_send_again() }

  public val terms_header: StringResource by 
      lazy { init_terms_header() }

  public val terms_msg: StringResource by 
      lazy { init_terms_msg() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("allowed_characters", CommonMainString0.allowed_characters)
  map.put("attempts_remain", CommonMainString0.attempts_remain)
  map.put("close_btn_text", CommonMainString0.close_btn_text)
  map.put("confirm_btn_text", CommonMainString0.confirm_btn_text)
  map.put("congrats", CommonMainString0.congrats)
  map.put("copy_btn", CommonMainString0.copy_btn)
  map.put("data_privacy_header", CommonMainString0.data_privacy_header)
  map.put("data_privacy_msg", CommonMainString0.data_privacy_msg)
  map.put("document", CommonMainString0.document)
  map.put("document_header", CommonMainString0.document_header)
  map.put("follow_instructions", CommonMainString0.follow_instructions)
  map.put("id_number", CommonMainString0.id_number)
  map.put("input_4_numbers", CommonMainString0.input_4_numbers)
  map.put("map_btn", CommonMainString0.map_btn)
  map.put("next_btn_text", CommonMainString0.next_btn_text)
  map.put("order_description_msg", CommonMainString0.order_description_msg)
  map.put("order_error_description", CommonMainString0.order_error_description)
  map.put("order_error_msg", CommonMainString0.order_error_msg)
  map.put("order_greetings_msg", CommonMainString0.order_greetings_msg)
  map.put("order_header", CommonMainString0.order_header)
  map.put("otp_description_msg", CommonMainString0.otp_description_msg)
  map.put("otp_error_description", CommonMainString0.otp_error_description)
  map.put("otp_error_msg", CommonMainString0.otp_error_msg)
  map.put("otp_header", CommonMainString0.otp_header)
  map.put("personal_address_barangay", CommonMainString0.personal_address_barangay)
  map.put("personal_address_city", CommonMainString0.personal_address_city)
  map.put("personal_address_house", CommonMainString0.personal_address_house)
  map.put("personal_address_province", CommonMainString0.personal_address_province)
  map.put("personal_address_street", CommonMainString0.personal_address_street)
  map.put("personal_address_title", CommonMainString0.personal_address_title)
  map.put("personal_address_zipcode", CommonMainString0.personal_address_zipcode)
  map.put("personal_contacts_name", CommonMainString0.personal_contacts_name)
  map.put("personal_contacts_phone", CommonMainString0.personal_contacts_phone)
  map.put("personal_contacts_phone_validation",
      CommonMainString0.personal_contacts_phone_validation)
  map.put("personal_contacts_relation", CommonMainString0.personal_contacts_relation)
  map.put("personal_contacts_title", CommonMainString0.personal_contacts_title)
  map.put("personal_data_age_error_msg", CommonMainString0.personal_data_age_error_msg)
  map.put("personal_data_birthdate", CommonMainString0.personal_data_birthdate)
  map.put("personal_data_document_error_msg", CommonMainString0.personal_data_document_error_msg)
  map.put("personal_data_document_expired_error_msg",
      CommonMainString0.personal_data_document_expired_error_msg)
  map.put("personal_data_document_issue_error_msg",
      CommonMainString0.personal_data_document_issue_error_msg)
  map.put("personal_data_email", CommonMainString0.personal_data_email)
  map.put("personal_data_email_error_msg", CommonMainString0.personal_data_email_error_msg)
  map.put("personal_data_firstname", CommonMainString0.personal_data_firstname)
  map.put("personal_data_lastname", CommonMainString0.personal_data_lastname)
  map.put("personal_data_middle_name", CommonMainString0.personal_data_middle_name)
  map.put("personal_data_serial", CommonMainString0.personal_data_serial)
  map.put("personal_data_serial_expired", CommonMainString0.personal_data_serial_expired)
  map.put("personal_data_serial_issue", CommonMainString0.personal_data_serial_issue)
  map.put("personal_data_text_error_msg", CommonMainString0.personal_data_text_error_msg)
  map.put("personal_data_title", CommonMainString0.personal_data_title)
  map.put("personal_income_company", CommonMainString0.personal_income_company)
  map.put("personal_income_industry", CommonMainString0.personal_income_industry)
  map.put("personal_income_monthly", CommonMainString0.personal_income_monthly)
  map.put("personal_income_position", CommonMainString0.personal_income_position)
  map.put("personal_income_source", CommonMainString0.personal_income_source)
  map.put("personal_income_title", CommonMainString0.personal_income_title)
  map.put("pre_approve_msg", CommonMainString0.pre_approve_msg)
  map.put("price_msg", CommonMainString0.price_msg)
  map.put("recommended", CommonMainString0.recommended)
  map.put("select_barangay", CommonMainString0.select_barangay)
  map.put("select_income_from_list", CommonMainString0.select_income_from_list)
  map.put("select_industry_from_list", CommonMainString0.select_industry_from_list)
  map.put("select_street", CommonMainString0.select_street)
  map.put("select_type_of_document", CommonMainString0.select_type_of_document)
  map.put("select_zip", CommonMainString0.select_zip)
  map.put("send_again", CommonMainString0.send_again)
  map.put("terms_header", CommonMainString0.terms_header)
  map.put("terms_msg", CommonMainString0.terms_msg)
}

internal val Res.string.allowed_characters: StringResource
  get() = CommonMainString0.allowed_characters

private fun init_allowed_characters(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:allowed_characters", "allowed_characters",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    63, 110),
    )
)

internal val Res.string.attempts_remain: StringResource
  get() = CommonMainString0.attempts_remain

private fun init_attempts_remain(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:attempts_remain", "attempts_remain",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    174, 51),
    )
)

internal val Res.string.close_btn_text: StringResource
  get() = CommonMainString0.close_btn_text

private fun init_close_btn_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close_btn_text", "close_btn_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    226, 30),
    )
)

internal val Res.string.confirm_btn_text: StringResource
  get() = CommonMainString0.confirm_btn_text

private fun init_confirm_btn_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_btn_text", "confirm_btn_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    257, 36),
    )
)

internal val Res.string.congrats: StringResource
  get() = CommonMainString0.congrats

private fun init_congrats(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:congrats", "congrats",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    294, 40),
    )
)

internal val Res.string.copy_btn: StringResource
  get() = CommonMainString0.copy_btn

private fun init_copy_btn(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:copy_btn", "copy_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    335, 24),
    )
)

internal val Res.string.data_privacy_header: StringResource
  get() = CommonMainString0.data_privacy_header

private fun init_data_privacy_header(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:data_privacy_header", "data_privacy_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    360, 55),
    )
)

internal val Res.string.data_privacy_msg: StringResource
  get() = CommonMainString0.data_privacy_msg

private fun init_data_privacy_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:data_privacy_msg", "data_privacy_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    416, 112),
    )
)

internal val Res.string.document: StringResource
  get() = CommonMainString0.document

private fun init_document(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:document", "document",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    573, 28),
    )
)

internal val Res.string.document_header: StringResource
  get() = CommonMainString0.document_header

private fun init_document_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:document_header", "document_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    529, 43),
    )
)

internal val Res.string.follow_instructions: StringResource
  get() = CommonMainString0.follow_instructions

private fun init_follow_instructions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:follow_instructions", "follow_instructions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    602, 75),
    )
)

internal val Res.string.id_number: StringResource
  get() = CommonMainString0.id_number

private fun init_id_number(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:id_number", "id_number",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    678, 29),
    )
)

internal val Res.string.input_4_numbers: StringResource
  get() = CommonMainString0.input_4_numbers

private fun init_input_4_numbers(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:input_4_numbers", "input_4_numbers",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    708, 51),
    )
)

internal val Res.string.map_btn: StringResource
  get() = CommonMainString0.map_btn

private fun init_map_btn(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:map_btn", "map_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    760, 19),
    )
)

internal val Res.string.next_btn_text: StringResource
  get() = CommonMainString0.next_btn_text

private fun init_next_btn_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next_btn_text", "next_btn_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    780, 29),
    )
)

internal val Res.string.order_description_msg: StringResource
  get() = CommonMainString0.order_description_msg

private fun init_order_description_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:order_description_msg", "order_description_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    810, 117),
    )
)

internal val Res.string.order_error_description: StringResource
  get() = CommonMainString0.order_error_description

private fun init_order_error_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:order_error_description", "order_error_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    928, 103),
    )
)

internal val Res.string.order_error_msg: StringResource
  get() = CommonMainString0.order_error_msg

private fun init_order_error_msg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:order_error_msg", "order_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1032, 43),
    )
)

internal val Res.string.order_greetings_msg: StringResource
  get() = CommonMainString0.order_greetings_msg

private fun init_order_greetings_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:order_greetings_msg", "order_greetings_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1076, 39),
    )
)

internal val Res.string.order_header: StringResource
  get() = CommonMainString0.order_header

private fun init_order_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:order_header", "order_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1116, 40),
    )
)

internal val Res.string.otp_description_msg: StringResource
  get() = CommonMainString0.otp_description_msg

private fun init_otp_description_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:otp_description_msg", "otp_description_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1157, 79),
    )
)

internal val Res.string.otp_error_description: StringResource
  get() = CommonMainString0.otp_error_description

private fun init_otp_error_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:otp_error_description", "otp_error_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1237, 133),
    )
)

internal val Res.string.otp_error_msg: StringResource
  get() = CommonMainString0.otp_error_msg

private fun init_otp_error_msg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otp_error_msg", "otp_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1371, 49),
    )
)

internal val Res.string.otp_header: StringResource
  get() = CommonMainString0.otp_header

private fun init_otp_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otp_header", "otp_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1421, 46),
    )
)

internal val Res.string.personal_address_barangay: StringResource
  get() = CommonMainString0.personal_address_barangay

private fun init_personal_address_barangay(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_barangay", "personal_address_barangay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1468, 45),
    )
)

internal val Res.string.personal_address_city: StringResource
  get() = CommonMainString0.personal_address_city

private fun init_personal_address_city(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_city", "personal_address_city",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1514, 53),
    )
)

internal val Res.string.personal_address_house: StringResource
  get() = CommonMainString0.personal_address_house

private fun init_personal_address_house(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_house", "personal_address_house",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1568, 66),
    )
)

internal val Res.string.personal_address_province: StringResource
  get() = CommonMainString0.personal_address_province

private fun init_personal_address_province(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_province", "personal_address_province",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1635, 45),
    )
)

internal val Res.string.personal_address_street: StringResource
  get() = CommonMainString0.personal_address_street

private fun init_personal_address_street(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_street", "personal_address_street",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1681, 39),
    )
)

internal val Res.string.personal_address_title: StringResource
  get() = CommonMainString0.personal_address_title

private fun init_personal_address_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_title", "personal_address_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1721, 42),
    )
)

internal val Res.string.personal_address_zipcode: StringResource
  get() = CommonMainString0.personal_address_zipcode

private fun init_personal_address_zipcode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_address_zipcode", "personal_address_zipcode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1764, 44),
    )
)

internal val Res.string.personal_contacts_name: StringResource
  get() = CommonMainString0.personal_contacts_name

private fun init_personal_contacts_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_contacts_name", "personal_contacts_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1809, 46),
    )
)

internal val Res.string.personal_contacts_phone: StringResource
  get() = CommonMainString0.personal_contacts_phone

private fun init_personal_contacts_phone(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_contacts_phone", "personal_contacts_phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1935, 51),
    )
)

internal val Res.string.personal_contacts_phone_validation: StringResource
  get() = CommonMainString0.personal_contacts_phone_validation

private fun init_personal_contacts_phone_validation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_contacts_phone_validation", "personal_contacts_phone_validation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1856, 78),
    )
)

internal val Res.string.personal_contacts_relation: StringResource
  get() = CommonMainString0.personal_contacts_relation

private fun init_personal_contacts_relation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_contacts_relation", "personal_contacts_relation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    1987, 50),
    )
)

internal val Res.string.personal_contacts_title: StringResource
  get() = CommonMainString0.personal_contacts_title

private fun init_personal_contacts_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_contacts_title", "personal_contacts_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2038, 51),
    )
)

internal val Res.string.personal_data_age_error_msg: StringResource
  get() = CommonMainString0.personal_data_age_error_msg

private fun init_personal_data_age_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_age_error_msg", "personal_data_age_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2090, 79),
    )
)

internal val Res.string.personal_data_birthdate: StringResource
  get() = CommonMainString0.personal_data_birthdate

private fun init_personal_data_birthdate(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_birthdate", "personal_data_birthdate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2170, 67),
    )
)

internal val Res.string.personal_data_document_error_msg: StringResource
  get() = CommonMainString0.personal_data_document_error_msg

private fun init_personal_data_document_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_document_error_msg", "personal_data_document_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2238, 72),
    )
)

internal val Res.string.personal_data_document_expired_error_msg: StringResource
  get() = CommonMainString0.personal_data_document_expired_error_msg

private fun init_personal_data_document_expired_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_document_expired_error_msg", "personal_data_document_expired_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2311, 100),
    )
)

internal val Res.string.personal_data_document_issue_error_msg: StringResource
  get() = CommonMainString0.personal_data_document_issue_error_msg

private fun init_personal_data_document_issue_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_document_issue_error_msg", "personal_data_document_issue_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2412, 110),
    )
)

internal val Res.string.personal_data_email: StringResource
  get() = CommonMainString0.personal_data_email

private fun init_personal_data_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_email", "personal_data_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2589, 35),
    )
)

internal val Res.string.personal_data_email_error_msg: StringResource
  get() = CommonMainString0.personal_data_email_error_msg

private fun init_personal_data_email_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_email_error_msg", "personal_data_email_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2523, 65),
    )
)

internal val Res.string.personal_data_firstname: StringResource
  get() = CommonMainString0.personal_data_firstname

private fun init_personal_data_firstname(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_firstname", "personal_data_firstname",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2625, 47),
    )
)

internal val Res.string.personal_data_lastname: StringResource
  get() = CommonMainString0.personal_data_lastname

private fun init_personal_data_lastname(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_lastname", "personal_data_lastname",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2673, 42),
    )
)

internal val Res.string.personal_data_middle_name: StringResource
  get() = CommonMainString0.personal_data_middle_name

private fun init_personal_data_middle_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_middle_name", "personal_data_middle_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2716, 65),
    )
)

internal val Res.string.personal_data_serial: StringResource
  get() = CommonMainString0.personal_data_serial

private fun init_personal_data_serial(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_serial", "personal_data_serial",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2930, 44),
    )
)

internal val Res.string.personal_data_serial_expired: StringResource
  get() = CommonMainString0.personal_data_serial_expired

private fun init_personal_data_serial_expired(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_serial_expired", "personal_data_serial_expired",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2782, 76),
    )
)

internal val Res.string.personal_data_serial_issue: StringResource
  get() = CommonMainString0.personal_data_serial_issue

private fun init_personal_data_serial_issue(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_serial_issue", "personal_data_serial_issue",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2859, 70),
    )
)

internal val Res.string.personal_data_text_error_msg: StringResource
  get() = CommonMainString0.personal_data_text_error_msg

private fun init_personal_data_text_error_msg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_text_error_msg", "personal_data_text_error_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    2975, 60),
    )
)

internal val Res.string.personal_data_title: StringResource
  get() = CommonMainString0.personal_data_title

private fun init_personal_data_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_data_title", "personal_data_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3036, 47),
    )
)

internal val Res.string.personal_income_company: StringResource
  get() = CommonMainString0.personal_income_company

private fun init_personal_income_company(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_company", "personal_income_company",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3084, 47),
    )
)

internal val Res.string.personal_income_industry: StringResource
  get() = CommonMainString0.personal_income_industry

private fun init_personal_income_industry(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_industry", "personal_income_industry",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3132, 44),
    )
)

internal val Res.string.personal_income_monthly: StringResource
  get() = CommonMainString0.personal_income_monthly

private fun init_personal_income_monthly(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_monthly", "personal_income_monthly",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3177, 59),
    )
)

internal val Res.string.personal_income_position: StringResource
  get() = CommonMainString0.personal_income_position

private fun init_personal_income_position(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_position", "personal_income_position",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3237, 44),
    )
)

internal val Res.string.personal_income_source: StringResource
  get() = CommonMainString0.personal_income_source

private fun init_personal_income_source(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_source", "personal_income_source",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3282, 54),
    )
)

internal val Res.string.personal_income_title: StringResource
  get() = CommonMainString0.personal_income_title

private fun init_personal_income_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_income_title", "personal_income_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3337, 53),
    )
)

internal val Res.string.pre_approve_msg: StringResource
  get() = CommonMainString0.pre_approve_msg

private fun init_pre_approve_msg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pre_approve_msg", "pre_approve_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3391, 135),
    )
)

internal val Res.string.price_msg: StringResource
  get() = CommonMainString0.price_msg

private fun init_price_msg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:price_msg", "price_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3527, 37),
    )
)

internal val Res.string.recommended: StringResource
  get() = CommonMainString0.recommended

private fun init_recommended(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:recommended", "recommended",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3565, 35),
    )
)

internal val Res.string.select_barangay: StringResource
  get() = CommonMainString0.select_barangay

private fun init_select_barangay(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_barangay", "select_barangay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3601, 91),
    )
)

internal val Res.string.select_income_from_list: StringResource
  get() = CommonMainString0.select_income_from_list

private fun init_select_income_from_list(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_income_from_list", "select_income_from_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3693, 83),
    )
)

internal val Res.string.select_industry_from_list: StringResource
  get() = CommonMainString0.select_industry_from_list

private fun init_select_industry_from_list(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_industry_from_list", "select_industry_from_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3777, 73),
    )
)

internal val Res.string.select_street: StringResource
  get() = CommonMainString0.select_street

private fun init_select_street(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_street", "select_street",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3851, 85),
    )
)

internal val Res.string.select_type_of_document: StringResource
  get() = CommonMainString0.select_type_of_document

private fun init_select_type_of_document(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_type_of_document", "select_type_of_document",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    3937, 75),
    )
)

internal val Res.string.select_zip: StringResource
  get() = CommonMainString0.select_zip

private fun init_select_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_zip", "select_zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    4013, 86),
    )
)

internal val Res.string.send_again: StringResource
  get() = CommonMainString0.send_again

private fun init_send_again(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:send_again", "send_again",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    4100, 34),
    )
)

internal val Res.string.terms_header: StringResource
  get() = CommonMainString0.terms_header

private fun init_terms_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:terms_header", "terms_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    4135, 60),
    )
)

internal val Res.string.terms_msg: StringResource
  get() = CommonMainString0.terms_msg

private fun init_terms_msg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:terms_msg", "terms_msg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/umib2bonline.composeapp.generated.resources/values/strings.commonMain.cvr",
    4196, 73),
    )
)
