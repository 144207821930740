package ph.umi.online.order

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Scaffold
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.launch
import org.company.app.navigation.LocalNavHost
import ph.umi.online.navigation.ScreensList
import org.jetbrains.compose.resources.stringResource
import org.koin.compose.getKoin
import ph.umi.online.order.models.OrdersAction
import ph.umi.online.order.models.OrdersEvent
import ph.umi.online.theme.umi_background_blue
import ph.umi.online.ui.UmiElevatedButton
import ph.umi.online.ui.UmiTopBar
import ph.umi.online.views.OrdersView
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.confirm_btn_text
import umib2bonline.composeapp.generated.resources.order_header

@Composable
fun OrdersScreen(
    viewModel: OrderViewModel = getKoin().get()
) {
    val state by viewModel.viewStates().collectAsState()
    val action by viewModel.viewActions().collectAsState(null)
    val navHost = LocalNavHost.current

    val snackbarHostState = remember { SnackbarHostState() }
    val scope = rememberCoroutineScope()
    val loading = state.isLoading.collectAsState()

    LaunchedEffect(state) {
        println("LaunchedEffect from ORDERS SCREEN")
        viewModel.getOrderDetails()
    }

    if (loading.value) {
        Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
            CircularProgressIndicator(
                color = umi_background_blue,
                strokeWidth = 5.dp
            )
        }
    } else {
        Scaffold(
            snackbarHost = @androidx.compose.runtime.Composable {
                SnackbarHost(snackbarHostState)
            },
            topBar = {
                UmiTopBar(
                    label = stringResource(Res.string.order_header),
                    progress = 10,
                    isDividerEnabled = true,
                    isBackBtnEnabled = false
                )
            },
            bottomBar = {
                UmiElevatedButton(
                    label = stringResource(Res.string.confirm_btn_text),
                    onClick = {
                        viewModel.clearActions()
                        viewModel.obtainEvent(OrdersEvent.onNextBtnClicked)
                    })
            }
        ) {
            OrdersView(state, it) { event ->
                viewModel.obtainEvent(event)
            }
        }
    }


    when (action) {
        null -> {}
        OrdersAction.GetOrders -> {
            viewModel.clearActions()
            state.isLoading.value = false
        }

        OrdersAction.goNextPage -> {
            viewModel.clearActions()
            navHost.navigate(ScreensList.DocumentTypeScreen.title)
        }

        OrdersAction.ShowErrorMsg -> {
            scope.launch {
                snackbarHostState.showSnackbar(
                    "Some error occured, try again later"
                )
            }
        }

        OrdersAction.go404Page -> {
            navHost.navigate(ScreensList.OrderError.title)
        }

        OrdersAction.goRestart -> {
            viewModel.clearActions()
            navHost.navigate(ScreensList.OtpScreen.title)
        }

        is OrdersAction.CopyAddress -> {}
        is OrdersAction.ShowGmaps -> {}
        OrdersAction.goFinishPage -> {
            viewModel.clearActions()
            navHost.navigate(ScreensList.Finish.title)
        }
    }
}