package ph.umi.online.views

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.vectorResource
import ph.umi.online.theme.md_theme_light_background
import ph.umi.online.theme.umi_error_color
import ph.umi.online.theme.umi_header_color
import ph.umi.online.theme.umi_primary_text_color
import umib2bonline.composeapp.generated.resources.Res
import umib2bonline.composeapp.generated.resources.ic_incorrect

@Composable
internal fun OrderNotFoundView() {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(md_theme_light_background),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(32.dp))

        Text(
            text = "404",
            style = TextStyle(
                fontSize = 60.sp,
                lineHeight = 60.sp,
                fontWeight = FontWeight(600),
                color = umi_error_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.15.sp
            )
        )

        Spacer(modifier = Modifier.height(32.dp))

        Text(
            text = "Order not found",
            style = TextStyle(
                fontSize = 20.sp,
                lineHeight = 24.sp,
                fontWeight = FontWeight(500),
                color = umi_header_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.15.sp
            )
        )

        Spacer(modifier = Modifier.height(24.dp))

        Text(
            text = "We could not find your order or it has been completed",
            style = TextStyle(
                fontSize = 16.sp,
                lineHeight = 24.sp,
                fontWeight = FontWeight(400),
                color = umi_primary_text_color,
                textAlign = TextAlign.Center,
                letterSpacing = 0.5.sp
            )
        )

        Spacer(modifier = Modifier.height(24.dp))
    }
}