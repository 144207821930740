package ph.umi.online.data

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

enum class ApiStatus {
    SUCCESS,
    ERROR,
    LOADING,
    NOT_FOUND
}

sealed class NetworkResult<out T>(val status: ApiStatus, val data: T?, val message: String?) {
    data class Success<out T>(val _data: T?) :
        NetworkResult<T>(status = ApiStatus.SUCCESS, data = _data, message = null)

    data class Error<out T>(val _data: T?, val exception: String) :
        NetworkResult<T>(status = ApiStatus.ERROR, data = _data, message = exception)

    data class NotFound<out T>(val guid: String) :
        NetworkResult<T>(status = ApiStatus.NOT_FOUND, data = null, message = null)

    data class Loading<out T>(val isLoading: Boolean) :
        NetworkResult<T>(status = ApiStatus.LOADING, data = null, message = null)
}

fun <T> toResultFlow(call: suspend () -> NetworkResult<T?>) : Flow<NetworkResult<T>> {
    return flow {
        emit(NetworkResult.Loading(true))
        val c = call.invoke()
        c.let { response ->
            try {
                println("response_data ${response.data}")
                println("response_status ${response.status}")
                println("response_msg ${response.message}")
                when (response.status) {
                    ApiStatus.SUCCESS -> {
                        emit(NetworkResult.Success(response.data))
                    }
                    ApiStatus.ERROR -> {
                        emit(NetworkResult.Error(null, response.message.toString()))
                    }
                    ApiStatus.LOADING -> {
                        emit(NetworkResult.Loading(true))
                    }
                    ApiStatus.NOT_FOUND -> {
                        emit(NetworkResult.NotFound(""))
                    }
                }
            } catch (e: Exception) {
                println("NetworkResult" + e.message)
                emit(NetworkResult.Error(null, e.message.toString()))

                //  emit(NetWorkResult.Error("error", e.toString()))
            }
        }
    }
}